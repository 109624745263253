@import '../../../defaults.scss';

.device-properties-view {
	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
	grid-template-rows: max-content;
	grid-gap: 1rem;
}

.device-property-wrapper {
	position: relative;
}
