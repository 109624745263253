@import '../../../defaults.scss';

.widget-layout-manager .widget-container {
	background-color: white;
	pointer-events: all;
	border-bottom: 5px solid $color-widget-primary;
	border-radius: 0 0 5px 5px;
	display: flex;
	flex-direction: column;
}
.widget-layout-manager .widget-container.droppable {
	box-shadow: 0px 0px 14px 5px $color-widget-primary;
}
.widget-layout-manager .widget-container.interacting {
	z-index: 5;
}
.widget-layout-manager .widget-container .to-do-drag {
	cursor: move;
}
.widget-container {
	display: flex;
	flex-direction: column;
	height: 100%;
}
.widget-container > .header {
	padding: 10px;
	border-bottom: 1px solid;
	display: flex;
	user-select: none;
	border-image: linear-gradient(90deg, rgba(0, 0, 0, 0), $color-border 15% 85%, rgba(0, 0, 0, 0)) 1;
}
.widget-container > .header > .title {
	flex-grow: 1;
	font-weight: bold;
	font-size: larger;
	color: $color-widget-primary;
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
}
// .widget-container > .header > .title::first-letter {
// 	text-transform: uppercase;
// }
.widget-container > .header .action-bar {
	grid-auto-columns: auto;
	grid-gap: initial;
}
.widget-container > .header .action-bar > div {
	display: flex;
    flex-direction: row;
    align-items: center;
	justify-content: center;
	margin-left: 5px;
}

.widget-container.configure-content-wrap {
	width: 300px;
	user-select: text;
	cursor: auto;
}
.widget-container.configure-content-wrap > .header {
	display: flex;
	justify-content: space-between;
	flex-direction: row;
	font-weight: bold;
	padding: 10px;
}
.widget-container.configure-content-wrap > .header img {
	width: 12px;
}
.widget-container.configure-content-wrap > .content {
	padding: 10px;
	border-radius: 10px;
}

.widget-container > .content {
	overflow: hidden;
	flex: 1;
	display: flex;
    flex-direction: column;
	justify-content: space-between;
	// commented out because the popup that is in the widget is clipped by the widget itself. really need this property?
	// position: relative;
	height: min-content;
}
.widget-container > .content > .static.fullHeight {
	height: 100%;
}
.widget-container > .content > .static.fullHeight > * {
	max-height: 100%;
}
.widget-container > .content > .static {
	height: min-content;
	padding-top: 10px;
	margin: 0 10px;
}
.widget-container > .content > .static.hasDynamicContent {
	padding-top: 10px;
	margin: 0 10px;
}
.widget-container > .content > .static > * {
	height: min-content;
}
.widget-container > .content > .dynamic {
	padding: 10px 0;
	margin: 0 10px;
	height: max-content;
	border-top: 1px solid $color-primary-dark-bg;
	overflow-y: auto;
	overflow-x: hidden;
	position: relative;
}
.widget-container > .footer {
	margin-top: auto;
}
.widget-container > .footer .arrow-button {
	display: flex;
	justify-content: center;
	background-color: $color-widget-primary;
	color: white;
	border: none;
	border-radius: 0;
	padding: 0.25em;
}
.widget-container > .footer .arrow-button img {
	height: 0.4em;
	filter: $icon-filter-white;
}
.widget-container > .footer .arrow-button img.rotate {
	transform: rotate(180deg);
}
/* widget error message */
.widget-container > .content > .static .widget-error {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	padding-top: 25px;
	padding-bottom: 25px;
}
.widget-container > .content > .static .widget-error > .icon > * {
	margin-bottom: 10px;
	color: #d0d0d0;
	width: 60px; height: 60px;
}
.widget-container > .content > .static .widget-error > .message {
	text-align: center;
}
.widget-container > .content > .static .widget-error > .message::first-letter {
	text-transform: capitalize;
}
/* resize handler */

.widget-container .react-resizable-handle-se {
	background-image: none;
	width: 7px;
	height: 7px;
	border-right: 0.17em solid $text-color-main-2;
	border-bottom: 0.17em solid $text-color-main-2;
	margin-right: 3px;
	margin-bottom: 1px;
}
.widget-container > .footer ~ .react-resizable-handle-se {
	border-right-color: $color-primary-dark-bg;
	border-bottom-color: $color-primary-dark-bg;
	margin-bottom: 0;
}
.widget-container .react-resizable-handle-se::after {
	display: none;
}
