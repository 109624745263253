.map-widgets-control .widget-selector {
	min-width: 150px;
}

.map-widgets-control .widget-selector > .header {
	display: flex;
	font-weight: bold;
	padding: 10px;
	text-transform: capitalize;
}
.map-widgets-control .widget-selector > .header > * {
	display: flex;
	align-items: center;
}
.map-widgets-control .widget-selector > .header > *:nth-child(1) {
	flex-grow: 1;
}
.map-widgets-control .widget-selector > .header .close img {
	height: 11px;
}
.map-widgets-control .widget-selector > .list {
	max-height: 200px;
	overflow-y: auto;
}
.map-widgets-control .widget-selector > .list .list-item {
	padding: 10px;
}
.map-widgets-control .widget-selector > .list .list-item::first-letter {
	text-transform: capitalize;
}
.map-widgets-control .widget-selector.header .title {
	display: block;
}
.map-widgets-control .widget-selector.header .title::first-letter {
	text-transform: capitalize;
}