@import '../../../defaults.scss';

.zone-guard.body {
	display: flex;
	flex-direction: row;
	width: 100%;
}
.zone-guard.body .object-device-manager {
	display: flex;
	flex-direction: column;
	border-right: 1px solid $color-border;
}
.zone-guard.body .object-device-manager > *:last-child {
	flex-grow: 1;
	overflow-y: auto;
}
.zone-guard.body .object-device-manager,
.zone-guard.body .object-zone-manager {
	min-width: 250px;
}
.zone-guard.body .object-zone-manager .zones {
	border-right: 1px solid $color-border;
}
.zone-guard.body .object-device-manager {
	width: 250px;
}
.zone-guard.body .object-device-manager .header,
.zone-guard.body .object-zone-manager .header {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	padding: 15px;
}
.zone-guard.body .list-item.droppable {
	background-color: $color-primary !important;
	color: white !important;
	box-shadow: 0px 2px 7px -2px rgba(0, 0, 0, 0.4);
}
.zone-guard.body .zone-select-map {
	position: relative;
	width: 100%;
	height: 100%;
}
.zone-guard.body span.no-items {
	padding: 15px;
}
