.account-info {
	display: flex;
	width: 100%;
}
.account-info .avatar {
	margin-right: 10px;
}
.account-info .name {
	font-weight: bold;
}
.account-info .name::first-letter {
	text-transform: capitalize;
}
.account-info .info,
.account-info .login-name,
.account-info .name span {
	white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
	max-width: 100%;
}