.create-level-fields  > div {
	display: flex;
	margin-bottom: 10px;
}
.create-level-fields > div > div {
	max-width: 50%;
}
.create-level-fields  > div label {
	display: block;
	margin-bottom: 8px;
}