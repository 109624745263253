@import '../../../defaults.scss';

$height: 50px;
$line-color: #d0d0d0;
$current-color: #b0b0b0;

.time-range-scale {
	flex-grow: 1;
    display: flex;
	flex-direction: column;
}

.time-range-scale > * {
	width: 100%;
}

.time-range-scale .status-line {
	text-align: center;
	padding: 10px;
	background-color: #252c34;
	color: white;
	flex: 0 0 36px;
	height: 36px;
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
}
.time-range-scale .status-line > span:first-child {
	text-align: left;
}
.time-range-scale .status-line > span:last-child {
	text-align: right;
}
.time-range-scale .status-line > * {
	user-select: none;
}

.time-range-scale > .container {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-grow: 1;
}

.time-range-scale > .container > .time-scale {
	overflow: hidden;
	padding: 0 1em;
	width: 100%;
	 color: #808080;
	--line-color: #d0d0d0;
	font-size: 10px;
	font-family: sans;
}
.time-range-scale > .container > .time-scale > .frame {
	position: relative;
}
.time-range-scale > .container > .time-scale > .frame > .assy {
	position: relative;
}

.time-range-scale > .container > .time-scale .display {
	position: relative;
	height: $height;
}
.time-range-scale > .container > .time-scale .display .current {
	display: block;
	position: absolute;
	width: 1px;
	z-index: 12;
	background-color: $current-color;
	height: 100%;
}
.time-range-scale > .container > .time-scale .display .current > .handle {
	top: 25%;
	right: -3px;
}
.time-range-scale > .container > .time-scale .display > .range {
	position: absolute;
	height: $height;
	border-left: 1px solid $line-color;
	border-right: 1px solid $line-color;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	background-color: #e8f7f8;
	pointer-events: none;
}
.time-range-scale > .container > .time-scale .display div.handle {
	position: absolute;
	width: 7px;
	height: 50%;
	border: 1px solid $line-color;
	background-color: $line-color;
	cursor: pointer;
	border-radius: 3px;
	pointer-events: all;
}
.time-range-scale > .container > .time-scale .display div.current .handle {
	display: none;
}
.time-range-scale > .container > .time-scale .display div.handle.hovered {
	display: block;
	border: 1px solid #7dd5e2;
	background-color: #7dd5e2;
}
.time-range-scale > .container > .time-scale .display div.handle.on {
	border: 1px solid green;
	background-color: green;
}
.time-range-scale > .container > .time-scale .display div.handle.error {
	border: 1px solid red !important;
	background-color: red !important;
}
.time-range-scale > .container > .time-scale .display > .range > div.handle.start {
	right: 100%;
	margin-right: -3px;
}
.time-range-scale > .container > .time-scale .display > .range > div.handle.end {
	left: 100%;
	margin-left: -3px;
}
.time-range-scale > .container > .time-scale .scale {
	position: relative;
	user-select: none;
	cursor: pointer;
}
.time-range-scale > .container > .time-scale .scale > .axis {
	border-top: 1px solid $line-color;
}
.time-range-scale > .container > .time-scale .scale > .ticks {
	height: calc(12px + 2em);
	line-height: 1.3;
}
.time-range-scale > .container > .time-scale .scale > .ticks > * {
	position: absolute;
	top: -1px;
	width: 1px;
	height: 8px;
	background-color: $line-color;
}

.time-range-scale > .container > .time-scale .scale > .ticks > * > * {
	position: absolute;
	top: 100%;
	left: 50%;
	transform: translate(-50%, 0);
	white-space: nowrap;
}

.time-range-scale > .container > .time-scale .scale > .ticks > *:first-child > * {
	left: auto;
	transform: none;
}

.time-range-scale > .container > .time-scale .scale > .ticks > *:last-child > * {
	left: auto;
	transform: translate(-100%, 0);
}

.time-range-scale > .container > .time-scale .scale > .ticks > .l-1 {
	top: -1px;
	height: 12px;
	font-size: 80%;
}

.time-range-scale > .container > .time-scale .scale > .ticks > .l-2 {
	top: -1px;
	height: 16px;
}
.time-range-scale > .container > .time-scale .scale > .ticks > .l-2 > * {
	top: calc(100% - 4px + 1em);
}

.time-range-scale > .container > .time-scale .scale > .ticks > .l-3 {
	top: -1px;
	height: 20px;
}

.time-range-scale > .container > .time-scale .events-line {
	display: flex;
	flex-direction: column;
	justify-content: space-evenly;
	width: 100%;
	height: 100%;
	pointer-events: none;
}
.time-range-scale > .container > .time-scale .events-line > .uri-line {
	position: relative;
	width: 100%;
	pointer-events: none;
}
.time-range-scale > .container > .time-scale .events-line > .uri-line > .event-tick {
	cursor: pointer;
	pointer-events: all;
	position: absolute;
	top: -3px;
	height: 7px;
	width: 7px;
	border-radius: 99px;
	background-color: $device-event-color;
	z-index: 1;
}
.time-range-scale > .container > .time-scale .display.interacting .event-tick {
	pointer-events: none;
}
.time-range-scale > .container > .time-scale .events-line > .uri-line > .event-tick.incident {
	background-color: $device-incedent-event-color;
	z-index: 2;
}
.time-range-scale > .container > .time-scale .events-line > .uri-line > .event-tick > div {
	width: 100%;
	height: 100%;
}
.time-range-scale > .container > .time-scale .info-line {
	position: absolute;
	width: 100%;
	height: 100%;
	pointer-events: none;
}
.time-range-scale > .container > .time-scale .info-line:empty {
	display: none;
}
.time-range-scale > .container > .time-scale .info-line > .range {
	position: absolute;
	height: 100%;
	background-color: black;
	opacity: 0.05;
	pointer-events: none;
}