@import '../../../defaults.scss';


.marker.hide {
	display: none !important;
}
.event-map-marker {
	cursor: pointer;
	position: absolute;
	left: -6px; top: -6px;
	width: 12px; height: 12px;
	background-color: $trace-point-fill-color;
	border: 2px solid $device-event-color;
	border-radius: 99px;
}
.event-map-marker.incident {
	border-color: $device-incedent-event-color;
}