.digest-configuration {
	display: flex;
	flex-direction: column;
	width: 280px;
}
.digest-configuration .general-picker {
	display: flex;
	flex-direction: column;
	position: relative;
}
.digest-configuration .general-picker .collected-inputs {
	display: flex;
	flex-direction: row;
}
.digest-configuration .general-picker .x-picker.input-picker:not(.menu) {
	margin-right: 10px;
}
.digest-configuration .fields {
	position: relative;
}
.digest-configuration .fields div.field > div.input-number {
	display: flex;
    flex-direction: row-reverse;
    align-items: center;
}
.digest-configuration .fields div.since > div {
	display: flex;
    flex-direction: row;
    align-items: center;
}
.digest-configuration .fields div.since label {
	margin-left: 0;
}
.digest-configuration .fields label {
	margin: 10px;
}