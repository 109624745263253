@import '../../../../defaults.scss';

.action.multiple-selection img {
	width: 100%;
	height: 100%;
}
.action.multiple-selection.active img {
	filter: $icon-filter-highlight;
}
.multiple-selection .multiple-selection-popover.bp3-popover {
	margin-top: 5px !important;
	border-color: #79cdd0 !important;
	color: black !important;
	border-radius: 5px !important;
}
.multiple-selection .multiple-selection-popover .bp3-popover-content {
	border-radius: 5px !important;
}
.multiple-selection .multiple-selection-popover .bp3-menu {
	padding: 0;
}
.multiple-selection .multiple-selection-popover a div {
	color: black;
}
.multiple-selection .multiple-selection-popover a:hover {
	background-color: #e2f4f5 !important;
}