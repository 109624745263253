@import '../../../defaults.scss';

.commute-interval-list:not(.filled) {
	display: flex;
	align-items: center;
	justify-content: center;
	min-height: 60px;
}
.commute-interval-list.filled {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	grid-auto-rows: auto;
}
.commute-interval-list.filled.full {
	grid-template-columns: repeat(3, 1fr);
}
.commute-interval-list.filled > div {
	padding: 5px;
	display: inline-block;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 100%;
}
.commute-interval-list.filled > .header {
	position: sticky;
	top: -10px;
	padding: 5px;
	background-color: $color-primary-dark;
	color: white;
}
.commute-interval-list.filled:not(.full) > *:nth-child(4n+3),
.commute-interval-list.filled:not(.full) > *:nth-child(4n+4) {
	background-color: $reports-bg-color;
}
.commute-interval-list.filled.full > *:nth-child(6n+4),
.commute-interval-list.filled.full > *:nth-child(6n+5),
.commute-interval-list.filled.full > *:nth-child(6n+6) {
	background-color: $reports-bg-color;
}
