@import "../../../defaults.scss";

.page-panel {
	height: 100%;
	padding: 30px;
}

.page-panel > .wrap {
	border: 1px solid $color-border;
	border-radius: 5px;
	background-color: white;
	height: 100%;
}

.page-panel > .wrap > .content {
	height: 100%;
}