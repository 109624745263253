.location {
	display: flex;
}
.location span:not(:last-child) {
	margin-right: 5px;
}
.location.resolved {
	display: block;
}
.location.resolved .loader {
	display: none;
}
.location.resolved .coordinates {
	font-size: smaller;
	word-break: keep-all;
	opacity: .7;
}
.location.resolved .coordinates::before {
	content: " "
}