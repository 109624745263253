@import "../../../defaults.scss";

.global-menu {
	height: 100%;
}
.global-menu ul {
	display: flex;
}
.global-menu ul > li {
	display: inline-block;
	vertical-align: top;
}
.global-menu ul > span {
	vertical-align: top;
}
.global-menu .nav-link-item a > img, .global-menu .nav-item img {
	filter: $icon-filter-main;
	height: 20px;
}
.global-menu .nav-link-item a > svg {
	color: $icon-color-main;
}
.global-menu .nav-link-item a.active > svg {
	color: $color-primary;
}
.global-menu .nav-link-item a.active > img {
	filter: $icon-filter-highlight;
}
.global-menu .nav-item > div {
	width: $header-height;
	height: $header-height;
}
.global-menu ul > span .rs-avatar > svg {
	height: 18px;
	width: 18px;
}


.global-menu .rs-nav-item.bp3-active .rs-nav-item-content > img {
	filter: $icon-filter-highlight;
}
.global-menu .rs-nav-item-content, .global-menu .nav-link-item a {
	display: flex !important;
	align-items: center;
	justify-content: center;
}
.global-menu .rs-nav-item-content > img {
	height: 20px;
	filter: $icon-filter-main;
}
.global-menu .rs-nav-item-content > svg {
	color: $icon-color-main;
}