.map-controls {
	position: absolute;
    top: 63px;
    left: 8px;
    display: grid;
    grid-auto-columns: min-content;
    grid-gap: 3px;
    grid-auto-rows: min-content;
}

.map-controls > * ~ * {
    margin-top: 3px;
}