.employee-import-form {
	padding: 15px;
}
.employee-import-form .employee-categories {
	position: relative;
}
.employee-import-form .loaded-info .local-errors {
	display: flex;
	flex-direction: column;
	color: red;
}
