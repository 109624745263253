@import '../../../defaults.scss';

.device-info.widget .device-statistics .device-set .list {
  border: 1px solid $color-border;
  border-radius: 3px;
  max-height: 92px;
}
.device-info.widget .device-statistics .device-set {
  margin-bottom: 10px;
}
.device-info.widget .device-statistics label {
  font-weight: normal;
}
.device-info.widget {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.device-info.widget > div {
  padding-top: 5px;
  padding-bottom: 5px;
}
.device-info.widget > div.no-data-view {
  margin: 20px 0;
}
.device-info.widget .message-fields-time-location {
  min-height: 80px;
}

.device-info.widget .expiration {
  display: flex;
  justify-content: space-between;

  .expired {
    color: $text-color-error;
  }

  & > div {
    &:first-child {
      font-weight: bold;
      color: $text-color-main;
    }
  }
}
