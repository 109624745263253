.widget.trips {
	display: flex;
	justify-content: center;
	height: 100% !important;
}
.widget.trips.options {
	justify-content: flex-start;
}
.widget.trips > div:first-child {
	height: min-content;
	margin: 0 10px;
}
.widget.trips .widget-data-signature {
	text-align: center;
    margin-top: 5px;
    font-weight: bold;
}
