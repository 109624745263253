@import "../../../../defaults.scss";
@import "./index.scss";

$column_width:  // TODO
	minmax(100px, 5%) // employee id
	minmax(100px, 5%) // employee
	minmax(120px, 14%) // rfid
	minmax(120px, 10%) // start time
	minmax(200px, 1fr) // start location
	minmax(120px, 6%) // end time
	minmax(120px, 1fr) // end location
	minmax(120px, 6%) // duration
	minmax(120px, 6%) // duration
;
.report.commute .scroll-list {
	grid-auto-rows: auto;
}
.report.commute .scroll-list .header-section {
	position: -webkit-sticky;
	position: sticky;
	top: 0;
	background-color: white;
	z-index: 1;
	padding: 0;
}
.report.commute .scroll-list .section-header {
	background-color: #f0f0f0;
	font-weight: bold;
	position: -webkit-sticky;
	position: sticky;
	top: calc(1rem + 30px);
	z-index: 1;
}
.report.commute .scroll-list .section > * {
	padding: 15px;
	height: 100%;
}
.report.commute .scroll-list .section {
	padding: 0;
	display: grid;
	grid-template-columns: $column_width;
	grid-auto-rows: auto;
}

.report.commute .scroll-list .loader, // TODO
.report.commute .scroll-list .indicator {
	grid-column: 1 / 8;
}
.report.commute .scroll-list .section > *:nth-child(18n+10), // TODO
.report.commute .scroll-list .section > *:nth-child(18n+11),
.report.commute .scroll-list .section > *:nth-child(18n+12),
.report.commute .scroll-list .section  > *:nth-child(18n+13),
.report.commute .scroll-list .section  > *:nth-child(18n+14),
.report.commute .scroll-list .section > *:nth-child(18n+15),
.report.commute .scroll-list .section > *:nth-child(18n+16),
.report.commute .scroll-list .section > *:nth-child(18n+17),
.report.commute .scroll-list .section > *:nth-child(18n+18) {
	background-color: $reports-bg-color;
}
