@import '../../../../defaults.scss';

.map-search-control > .header {
	display: flex;
    justify-content: space-between;
	font-weight: bold;
	padding-bottom: 10px;
}
.map-search-control > .header .close {
	height: 11px;
}
.map-search-control > .header .close img {
	height: 100%;
}
.map-search .content img {
    filter: $icon-filter-white;
    width: 10px; height: auto;
}
.map-search .map-button {
	position: relative;
	left: 0;
	top: 0;
}
.map-search .map-search-popover {
	min-width: 250px;
}
.map-search-control {
    padding: 10px;
    cursor: default;
}
.map-search-control .wrap {
    border: 1px solid $color-border;
    border-radius: 3px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}
.map-search-control .icon {
    filter: $icon-filter-highlight;
    cursor: pointer;
    padding: 0 5px;
    width: 22px;
}
.map-search-control .form-control {
    width: 100%;
}
.map-search-control input {
    width: 100%;
    border: none;
    padding-right: 15px;
    caret-color: #adbfdd;
    padding: 3px 4px;
}