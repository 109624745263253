@import "../../../defaults.scss";

.action-bar.align-right {
	position: absolute;
	right: 0; top: 0;
}

.action-bar {
	display: grid;
	grid-auto-flow: column;
	grid-auto-columns: 16px;
	grid-gap: 16px;
}
.action-bar .action.disabled {
	color: $text-color-pale;
}
.action-bar .action.disabled img,
.action.disabled img {
	filter: $icon-filter-disabled !important;
}
.action-bar .action.pale img {
	filter: $icon-filter-pale;
}
.action-bar > * img {
	filter: $text-filter-main;
	pointer-events: none;
}
.action-bar .action.draggable img {
	filter: $icon-filter-highlight;
}
.action-bar .action {
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	user-select: none;
	-moz-user-select: none;
	-webkit-user-select: none;
	-ms-user-select: none;
}
.action-bar .action.drag {
	cursor: move;
}
.action-bar .action.active:not(.disabled) {
	color: $color-primary;
}
.action-bar .action.active:not(.disabled) img {
	filter: $icon-filter-highlight;
}