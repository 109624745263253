@import '../../defaults.scss';

.app-page.device-commands .sidebar-layout {
	height: 100%;
}
.app-page.device-commands .layout.sidebar-layout.body > .content {
	flex-grow: 1;
    padding: 15px;
    overflow-y: auto;
}
.app-page.device-commands .sidebar-layout .sidebar {
	border-right: 1px solid $color-border;
}
.app-page.device-commands .sidebar-layout .sidebar .filter {
	border-bottom: 1px solid $color-border;
    padding: 15px;
}
.app-page.device-commands .sidebar-layout .sidebar .filter .input {
	display: flex;
	flex-direction: column;
}
.app-page.device-commands .sidebar-layout .sidebar .filter .input label {
	margin-bottom: 10px;
}
.app-page.device-commands .device-commands-view {
	display: grid;
    grid-template-columns: repeat(auto-fill, 250px);
    grid-template-rows: max-content;
    grid-gap: 1rem;
}
.app-page.device-commands .object-action-dialog-popup {
	width: 250px;
}
.app-page.device-commands .object-action-dialog-popup .device-command {
	width: 100%;
    padding: 0;
    border: none;
}
.app-page.device-commands .object-action-dialog-popup .device-command .header {
	color: $color-primary-dark;
}
.app-page.device-commands .layout.sidebar-layout.body .sidebar {
	display: flex;
    flex-direction: column;
    height: 100%;
    overflow: hidden;
}
.app-page.device-commands .layout.sidebar-layout.body .sidebar .sidebar-content {
	display: flex;
    flex-direction: column;
	overflow: hidden;
	height: 100%;
}
.app-page.device-commands .layout.sidebar-layout.body .sidebar .sidebar-content .body {
	min-height: 200px;
	flex-grow: 1;
    overflow: hidden;
    display: flex;
    flex-direction: column;
}
.app-page.device-commands .layout.sidebar-layout.body .sidebar-content .device-filtered-events {
	max-height: 300px;
	flex-grow: 1;
    overflow: hidden;
    display: flex;
    flex-direction: column;
	border-top: 1px solid $color-border;
}
.app-page.device-commands .layout.sidebar-layout.body .sidebar-content .device-filtered-events .title {
	padding: 10px 15px 0;
}
.app-page.device-commands .layout.sidebar-layout.body .sidebar-content .device-filtered-events .x-list.list {
	padding-left: 15px;
	padding-right: 15px;
}
.app-page.device-commands .layout.sidebar-layout.body .sidebar-content .pending-command-list > div {
	padding: 10px 15px;
}
.app-page.device-commands .layout.sidebar-layout.body .sidebar-content .pending-command-list > div:nth-child(odd) {
	background-color: $list-item-bg-odd;
}
.app-page.device-commands .layout.sidebar-layout.body .sidebar-content .pending-command-list .device-command-is-pending .button {
	height: 26px;
}