.account-popup.rs-modal {
	margin-top: 100px;
}
.account-popup .rs-modal-body {
	padding-bottom: 0;
}
.account-popup .rs-modal-header {
	height: 80px;
}
.account-popup .rs-modal-header .rs-modal-header-close {
	z-index: 10;
}
.account-popup .rs-modal-header .wrap {
	position: absolute;
    right: 0;
    left: 0;
    top: -65px;
    background: none;
    display: flex;
    justify-content: center;
}
.account-popup .rs-modal-header .wrap .rs-avatar {
	border: 3px solid white;
	width: 160px;
	height: 160px;
}
.account-popup .account-info .info {
	text-align: center;
}
.account-popup .form.account-details,
.account-popup form.inspect-account,
.account-popup form.change-password {
	margin: 30px 20px 0;
}
.account-popup .account-info {
	justify-content: center;
}