.widget.events {
	display: flex;
	justify-content: center;
	height: 100% !important;
}
.widget.events.options {
	justify-content: flex-start;
}
.widget.events > div:first-child {
	height: min-content;
	margin: 0 10px;
}
.widget.events .widget-data-signature {
	text-align: center;
    margin-top: 5px;
    font-weight: bold;
}
.configuration-button .configuration-button .categories-control .content {
	padding: 0 10px;
}
.event-categories.widget-configuration .header .action-bar .add.action {
	width: 16px;
	height: 16px;
}
.widget.events .event-field {
	align-items: flex-start;
}
