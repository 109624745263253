@import './../../../../../defaults.scss';

.recent-events .header {
	font-weight: bold;
	color: $color-primary;
	padding: 5px;
	position: sticky;
	top: 0;
	background-color: white;
	z-index: 1;
	text-transform: uppercase;
}
.recent-events {
	display: flex;
	flex-direction: column;
	height: max-content !important;
	max-height: 100%;
}
.recent-events.empty {
	height: min-content;
}
.recent-events .event-item {
	padding: 10px 5px;
	cursor: inherit;
	margin-right: 10px;
}
.recent-events .event-item > div {
	padding: 2px 0;
}
.recent-events .event-item .message .event-icon {
	height: 20px;
    width: 20px;
    min-width: 20px;
	background-size: contain;
}
.recent-events .event-item .message {
	display: flex;
	flex-direction: column;
}
.recent-events .event-item .message .location {
	padding: 5px;
}
.recent-events > .event-item:not(:last-child) {
	border-bottom: 1px solid $color-border;
}
.recent-events .event-item .device-name {
	color: $color-primary;
	font-weight: bold;
}

.recent-events .event-item .info > div:not(:last-child) {
	border-bottom: 1px solid $color-border;
}
