@import "../../defaults.scss";

.app-page.device-control-panel .wrap {
	display: flex;
	flex-direction: column;
}
.app-page.device-control-panel .wrap > .header {
	display: flex;
	padding: 20px;
	font-size: larger;
}
.app-page.device-control-panel .wrap > .header > .icon {
	margin-right: 5px;
}
.app-page.device-control-panel .wrap > .content {
	display: grid;
	grid-template-columns: 1fr 1fr;
	overflow-y: auto;
}
.app-page.device-control-panel .wrap > .content a {
	color: inherit;
}

.app-page.device-control-panel .wrap > .content > .column > * {
	padding: 0 2rem;
}

.app-page.device-control-panel .wrap > .content > .column > * ~ * {
	margin-top: 1rem;
}

.app-page.device-control-panel .wrap .title {
	display: flex;
	align-items: center;
	font-weight: bold;
	text-transform: capitalize;
	line-height: 1;
}

.app-page.device-control-panel .wrap .title svg {
	margin-left: 5px;
}

.app-page.device-control-panel .panel-layout > .header {
	padding: 0 0 1rem !important;
}
.app-page.device-control-panel .panel-layout > .header > div > .icon {
	display: inherit;
}
.app-page.device-control-panel .panel-layout > .header > div > .icon > svg {
	width: 1rem;
	height: 1rem;
}
.app-page.device-control-panel .wrap > .content > .column {
	padding-bottom: 2rem;
}

@media(max-width: $screen-width-lg) {
	.app-page.device-control-panel .wrap > .content {
		display: block;
		height: auto;
		padding-bottom: 2rem;
	}
	.app-page.device-control-panel .wrap > .content > .column {
		padding-bottom: 0;
	}
	.app-page.device-control-panel .wrap > .content > .column ~ .column {
		margin-top: 1rem;
	}
}