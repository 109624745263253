@import '../../../../../defaults.scss';

.device-events.widget {
	display: flex;
	flex-direction: column;
}
.device-events.widget > .filter-info-view {
	padding: 5px 0;
}
.device-events.widget .device-events.group:not(:first-child) {
	border-top: 1px solid $color-border;
}
.device-events.widget .device-events.group {
	margin: 0;
}
.device-events.widget .device-events.group .section-header,
.device-events.widget .device-events.group .event-field {
	padding: 5px;
}
.device-events.widget .device-events.group .event-field.selected {
	border-bottom: 3px solid #d6d6d6;
}
.device-events.widget .device-events.group .event-field.now,
.device-events.widget .device-events.group .event-field:hover {
	background-color: #d6d6d6 !important;
}
.device-events.widget .device-events.group .section-header {
	background-color: #f0f0f0;
}
.device-events.widget .device-events.group .section .event-field:nth-child(even) {
	background-color: #f7f7f7;
}
.device-events.widget .event-categories.widget-configuration .header .action-bar .add.action {
	height: 16px;
	width: 16px;
}
.device-events.widget .filter-info-view {
	border-bottom: 1px solid $color-border;
}
.device-events.widget .filter-info-view > div {
	display: flex;
}
.device-events.widget .filter-info-view > div {
	padding-bottom: 5px;
}
.device-events.widget .filter-info-view label {
	font-weight: bold;
	white-space: nowrap;
}
.device-events.widget .filter-info-view span.names {
	display: inline-block;
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
	width: 100%;
}
.device-events.widget .no-data-view {
	padding: 20px 0;
}
.device-events.widget .list.empty {
	height: min-content;
}