@import '../../../../defaults.scss';
@import '../renderer/index.scss';


.report.driving-behaviour .scroll-list {
	display: grid;
	grid-template-columns: 
		minmax(80px, 5%) // item no
		minmax(180px, .2fr) // device
		repeat(1, minmax(80px, .1fr)) // distance
		repeat(1, minmax(140px, .2fr)) // odometer
		repeat(7, minmax(80px, .1fr)) // violations
		repeat(2, minmax(80px, .1fr)) // working, parked
		repeat(3, minmax(80px, .1fr)) // idling
		repeat(4, minmax(80px, .1fr)) // shares
	;
}

.report.driving-behaviour .scroll-list.options-1 {
	grid-template-columns: 
		minmax(80px, 5%) // item no
		minmax(180px, .2fr) // device
		minmax(180px, .2fr) // option 1
		repeat(1, minmax(80px, .1fr)) // distance
		repeat(1, minmax(140px, .2fr)) // odometer
		repeat(7, minmax(80px, .1fr)) // violations
		repeat(2, minmax(80px, .1fr)) // working, parked
		repeat(3, minmax(80px, .1fr)) // idling
		repeat(4, minmax(80px, .1fr)) // shares
	;
}

.report.driving-behaviour .scroll-list.options-2 {
	grid-template-columns: 
		minmax(80px, 5%) // item no
		minmax(180px, .2fr) // device
		minmax(180px, .2fr) // option 1
		minmax(180px, .2fr) // option 2
		repeat(1, minmax(80px, .1fr)) // distance
		repeat(1, minmax(140px, .2fr)) // odometer
		repeat(7, minmax(80px, .1fr)) // violations
		repeat(2, minmax(80px, .1fr)) // working, parked
		repeat(3, minmax(80px, .1fr)) // idling
		repeat(4, minmax(80px, .1fr)) // shares
	;
}

.report.driving-behaviour .scroll-list .loader
, .report.driving-behaviour .scroll-list .indicator {
	grid-column: 1 / -1;
}

.report.driving-behaviour .scroll-list .headers
, .report.driving-behaviour .scroll-list .item {
	display: contents;
}

.report.driving-behaviour .scroll-list > * > * {
	padding: 15px;
	display: flex;
	gap: .5em;
	align-items: center;
	flex-wrap: wrap;
}

.report.driving-behaviour .scroll-list .headers > * {
	position: sticky;
	top: 0;
	z-index: 1;
	padding-right: 15px;
	border-bottom: 1px solid $color-border;
	font-weight: bold;
	color: black;
	background-color: white;
}

.report.driving-behaviour .scroll-list .headers > * {
	grid-row-end: span 2;
}

.report.driving-behaviour .scroll-list .headers > *:not(.sub-header) > *::first-letter {
	text-transform: capitalize;
}

.report.driving-behaviour .scroll-list .headers > .group-header {
	grid-row-end: span 1;
	border-bottom: none;
	padding-bottom: 7px;
	justify-content: center;
}
.report.driving-behaviour .scroll-list .headers > .group-header.idling {
	grid-column-end: span 3;
}
.report.driving-behaviour .scroll-list .headers > .group-header.speeding
, .report.driving-behaviour .scroll-list .headers > .group-header.violations {
	grid-column-end: span 2;
}
.report.driving-behaviour .scroll-list .headers > .group-header.shares {
	grid-column-end: span 4;
}
.report.driving-behaviour .scroll-list .headers > .sub-header {
	top: calc(15px + 1em * 1.28581 + 7px);
	padding-top: 0;
	grid-row-end: span 1;
}


.report.driving-behaviour .scroll-list > .item:nth-child(even) > * {
	background-color: $reports-bg-color;
}

.report.driving-behaviour .scroll-list > .item > .asset-type > *::first-letter {
	text-transform: capitalize;
}

.report.driving-behaviour .scroll-list > .headers > .sub-header.statistics > *:nth-child(1)
, .report.driving-behaviour .scroll-list > .item > .statistics > *:nth-child(1) {
	width: 4em;
}

