@import "../../../../defaults.scss";
@import "../zoneX.scss";

$padding: 15px;

.zone-editor .vh-center,
.zone-editor > .sidebar,
.zone-editor > .content {
	height: 100%;
}

.zone-editor .zone-edit-map,
.zone-editor .zone-area-edit-map {
	height: 100%;
}
.zone-editor .sidebar-content {
	overflow-y: auto;
}
.zone-editor .zone-form {
	padding: $padding;
}

.zone-editor .zone-category-selector .list-item,
.zone-editor .zone-feature-list .list-item {
	width: calc(#{$sidebar-width} - 2 * #{$padding});
	overflow-wrap: break-word;
}
.zone-editor .zone-feature-list span.empty {
	display: block;
}
.zone-editor .zone-feature-list span.empty::first-letter {
	text-transform: capitalize;
}

.zone-editor .list-item.droppable {
	background-color: $color-primary !important;
	color: white;
}

.zone-editor .zone-area-editor {
	padding: $padding;
}
.zone-editor .zone-area-editor > * ~ * {
	margin-top: 15px;
}
.zone-editor .zone-area-editor > .summary > * ~ * {
	margin-top: 5px;
}
.zone-editor .zone-area-editor .toolbar button span {
	display: flex;
	align-items: center;
	justify-content: center;
}
.zone-editor .zone-area-editor .toolbar img {
	filter: $icon-filter-highlight;
}
.zone-editor .zone-area-editor span.caption {
	display: inline-block;
	margin-right: 5px;
}
.zone-editor .zone-area-editor span.caption::first-letter {
	text-transform: capitalize;
}