
.zone-x .action.add .bp3-popover {
	margin-top: 5px !important;
	border-color: #79cdd0 !important;
	color: black !important;
	border-radius: 5px !important;
}
.zone-x .action.add .bp3-popover-content {
	border-radius: 5px !important;
}
.zone-x .action.add a div {
	color: black;
}
.zone-x .action.add a div::first-letter {
	text-transform: capitalize;
}
.zone-x .action.add a {
	height: 40px;
}
.zone-x .action.add a:hover {
	background-color: #e2f4f5 !important;
}
.zone-x .action.add .bp3-menu {
	padding: 0;
}