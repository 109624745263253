@import '../../../defaults.scss';

.zone-list {
	flex-grow: 1;
	display: flex;
	flex-direction: column;
	overflow-y: auto;
}

.zone-list .header {
	font-weight: bold;
	padding: 15px;
	display: flex;
	border-top: 1px solid $color-border;
	border-bottom: 1px solid $color-border;
}
.zone-list .header span::first-letter {
	text-transform: capitalize;
}
.zone-list .header.clickable span:hover {
	color: $color-primary;
}
.zone-list .header .clickable:hover {
	color: $color-primary;
}
.zone-list .header > *:first-child {
	flex-grow: 1;
}

.zone-list .list-item {
	padding: $list-item-default-padding;
}

.zone-list .list-item.selected {
	background-color: #d0d0d0;
}

.zone-list .list-item.droppable {
	background-color: $color-primary !important;
	color: white;
}