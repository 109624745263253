@import '../../../defaults.scss';

.commuters.widget .device-statistics .device-set .list {
	border: 1px solid $color-border;
	border-radius: 3px;
	max-height: 92px;
}
.commuters.widget .device-statistics .device-set {
	margin-bottom: 10px;
}
.commuters.widget .device-statistics label {
	font-weight: normal;
}
.commuters.widget {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}
.commuters.widget > div {
	padding-top: 5px;
	padding-bottom: 5px;
}
.commuters.widget > div.no-data-view {
	margin: 20px 0;
}
.commuters.widget .message-fields-time-location {
	min-height: 80px;
}
