@import '../../../../defaults.scss';

.x-elements {
	display: flex;
	flex-direction: column;
	align-items: center;
}
.x-elements > .header {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	position: relative;
	width: 100%;
	padding: 19px 0 10px;
	margin-bottom: 0;
}
.x-elements > .header .label {
	font-weight: bold;
}
.x-elements > .header .action-bar {
	padding-right: 0 !important;
}
.x-elements .elements {
	width: 100%;
}

.x-elements .elements .property-element {
	padding: 10px 11px;
}