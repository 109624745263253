.account-auth-settings {
	margin: 20px 30px 0;
}
.account-auth-settings .info-header {
	display: flex;
    justify-content: center;
    padding-bottom: 15px;
}
.account-auth-settings .info-header span {
	font-weight: bold;
	font-size: 1.1rem;
}
.account-auth-settings li .bp3-menu-item {
	padding: 15px 10px;
	font-size: 1.2rem;
}
