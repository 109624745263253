@import '../../../defaults.scss';

.device-set label {
	margin-bottom: 5px;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
}
.device-set label span.toggle-devices-view {
	padding: 0 5px;
}
.device-set label span.toggle-devices-view:hover {
	color: $color-primary;
}