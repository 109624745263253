@import '../../../../defaults.scss';

.recent-data-event .list {
	min-height: 25px;
}
.recent-data-event .list .event-data-item {
	display: flex;
	flex-direction: column;
	padding: 10px;
	border: none !important;
}
.recent-data-event .list .event-data-item:not(:last-child) {
	border-bottom: 1px solid $color-border;
}
.recent-data-event .list .event-data-item .datetime-field {
	font-weight: bolder;
}
.recent-data-event .list .event-data-item > *:not(:last-child) {
	margin-bottom: 5px;
}
.recent-data-event .list .event-data-item .location {
	max-width: 150px;
}
.recent-data-event .see-more {
	display: flex;
	align-items: center;
	justify-content: center;
}