.no-data-view {
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
}

.no-data-view svg {
	width: 32px;
	height: 32px;
}

.no-data-view > div:last-child {
	margin-top: 5px;
}