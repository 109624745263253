@import '../../defaults.scss';

.app-page.mqtt-clients .side-bar-content {
  height: 100%;
}

.app-page.mqtt-clients .body {
  height: 100%;
}

.app-page.mqtt-clients .sidebar .header {
  display: flex;
  align-items: center;
  margin-right: 95px;
}

.app-page.mqtt-clients .sidebar .header .icon > * {
  margin-right: 5px;
  display: block;
}

.app-page.mqtt-clients .sidebar-content {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: hidden;
}

.app-page.mqtt-clients .sidebar-content .error {
  margin-top: 10px;
  text-align: center;
}

.app-page.mqtt-clients .sidebar-content .action-bar {
  position: absolute;
  top: 0;
  right: 0;
  padding: 18px 15px;
}

.app-page.mqtt-clients .sidebar-content .action-bar .actions {
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: 16px;
  grid-gap: 16px;
  cursor: pointer;
}

.app-page.mqtt-clients .sidebar-content .action-bar .actions > * img {
  filter: $text-filter-main;
}

.app-page.mqtt-clients .layout.sidebar-layout > .content {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  border-left: 1px solid $color-border;
}
