.editable-level-fields > div {
	display: flex;
	justify-content: center;
	margin-bottom: 10px;
}
.editable-level-fields > div > div {
	max-width: 50%;
}
.editable-level-fields > div label {
	display: block;
	margin-bottom: 8px;
}
.editable-level-fields .button.rs-btn {
	margin: 0 10px;
}