@import "../../../../defaults.scss";

.x-detector-widget {
	height: 100%;
}
.x-detector-widget > * ~ * {
	text-align: center;
	margin-top: 15px;
}
.x-detector-widget .indicator,
.x-detector-widget .summary {
	text-align: center;
}
.x-detector-widget .summary {
	color: #33558B;
	font-weight: bold;
}
.x-detector-widget .detector-details > .header {
	font-weight: bold;
	text-transform: capitalize;
	color: #D4534D;
}
.x-detector-widget .detector-details > .content {
	font-size: 90%;
	color: $text-color-light;
}
.x-detector-widget .detector-details > .content::first-letter {
	text-transform: capitalize;
}
.x-detector-widget .no-data-view {
	min-height: inherit;
}
.x-detector-widget-list-item {
	display: flex;
	padding: 7px 5px;
}
.x-detector-widget-list-item img {
	filter: invert(32%) sepia(79%) saturate(528%) hue-rotate(314deg) brightness(115%) contrast(90%);
	margin-right: 10px;
}
.x-detector-widget-list-item .content > div:first-child {
	font-weight: bold;
	color: #D4534D;
}
.x-detector-widget-list-item .content > div:last-child {
	color: $text-color-light;
}