@import './../../../../../defaults.scss';

.recent-trips .header {
	font-weight: bold;
	color: $color-primary;
	padding: 5px;
	position: sticky;
	top: 0;
	background-color: white;
	text-transform: uppercase;
	z-index: 1;
}
.recent-trips.empty {
	height: min-content;
}
.recent-trips {
	display: flex;
	flex-direction: column;
	height: max-content !important;
	max-height: 100%;
}
.recent-trips .trip-item {
	padding: 10px 5px;
	cursor: inherit;
	margin-right: 10px;
}
.recent-trips .trip-item > div {
	padding: 2px 0;
}
.recent-trips .trip-item .location-message .location {
	display: inline-block;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
	width: 100%;
	direction: rtl;
}
.recent-trips > .trip-item:not(:last-child) {
	border-bottom: 1px solid $color-border;
}
.recent-trips .trip-item .device-name {
	color: $color-primary;
	font-weight: bold;
}

.recent-trips .trip-item .info > div:not(:last-child) {
	border-bottom: 1px solid $color-border;
}
