@import "../../../defaults.scss";

.message-fields-view:not(.bare) .main {
	display: flex;
}
.message-fields-view .main > *:first-child {
	font-weight: bold;
}
.message-fields-view .main > *:first-child::first-letter {
	text-transform: capitalize;
}
.message-fields-view:not(.bare) .main > *:last-child {
	flex-grow: 1;
	justify-content: flex-end;
}

.message-fields-view button,
.message-fields-view button:active,
.message-fields-view button:focus {
	font-size: 18px;
	color: inherit;
	border: none;
	background-color: transparent !important;
	height: 18px;
	padding: 0 !important;
}

.message-fields-view img {
	height: 16px;
	vertical-align: baseline;
	margin-right: 3px;
	filter: $text-filter-main;
}


.message-fields-view .group {
	padding-right: 5px;
}
.message-fields-view .group:not(:last-child) {
	margin-bottom: 10px;
}

.message-fields-view .message-field {
	display: flex;
	justify-content: space-between;
}
.message-fields-view .message-field span > a {
	color: $link-color !important;
}
.message-fields-view .message-field.clickable:not(.pinned):hover {
	background-color: $list-item-bg-odd;
}

.message-fields-view .message-field .pin-block {
	padding-left: 5px
}
.message-fields-view .message-field .pin-block:hover .pin-icon {
	filter: $icon-filter-highlight;
}
.message-fields-view .message-field .pin-block .pin-icon {
	margin: 0;
	height: 10px;
	vertical-align: baseline;
	filter: $text-filter-main-2;
}

.message-fields-view .message-field:not(:last-child) {
	margin-bottom: 3px;
}

.message-fields-view .message-field .main-block .title {
	font-weight: bold;
	margin-right: 5px;
}