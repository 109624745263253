@import "../../../../defaults.scss";
@import "./index.scss";

$column_width:
	minmax(90px, 5%) // No
	minmax(120px, 10%) // start time
	minmax(200px, 1fr) // start location
	minmax(120px, 10%) // end time
	minmax(200px, 1fr) // end location
	minmax(70px, 6%) // duration
	minmax(70px, 6%) // distance
	minmax(100px, 10%) // incidents
;

.report.trip .scroll-list {
	display: grid;
	grid-template-columns: $column_width;
}
.report.trip.markers .scroll-list {
	display: grid;
	grid-template-columns: $column_width minmax(100px, 10%);
}

.report.trip .scroll-list .event-count-icon {
	display: flex;
	align-items: center;
}
.report.trip .scroll-list .event-count-icon > *:first-child {
	margin-right: 2px;
}
.report.trip .scroll-list .event-count-icon:not(:last-child) {
	margin-right: 5px;
}
.report.trip.base .scroll-list .loader,
.report.trip.base .scroll-list .indicator {
	grid-column: 1 / 9;
}
.report.trip.markers .scroll-list .loader,
.report.trip.markers .scroll-list .indicator {
	grid-column: 1 / 10;
}

.report.trip.base .scroll-list > *:nth-child(16n+9),
.report.trip.base .scroll-list > *:nth-child(16n+10),
.report.trip.base .scroll-list > *:nth-child(16n+11),
.report.trip.base .scroll-list > *:nth-child(16n+12),
.report.trip.base .scroll-list > *:nth-child(16n+13),
.report.trip.base .scroll-list > *:nth-child(16n+14),
.report.trip.base .scroll-list > *:nth-child(16n+15),
.report.trip.base .scroll-list > *:nth-child(16n+16) {
	background-color: $reports-bg-color;
}
.report.trip.markers .scroll-list > *:nth-child(18n+10),
.report.trip.markers .scroll-list > *:nth-child(18n+11),
.report.trip.markers .scroll-list > *:nth-child(18n+12),
.report.trip.markers .scroll-list > *:nth-child(18n+13),
.report.trip.markers .scroll-list > *:nth-child(18n+14),
.report.trip.markers .scroll-list > *:nth-child(18n+15),
.report.trip.markers .scroll-list > *:nth-child(18n+16),
.report.trip.markers .scroll-list > *:nth-child(18n+17),
.report.trip.markers .scroll-list > *:nth-child(18n+18) {
	background-color: $reports-bg-color;
}