.presence-detector-fields {
  & > div {
    &:not(:last-child):not(.tap-in-checkbox) {
      margin-bottom: 15px;
    }
  }

  .tap-in-checkbox {
    margin-bottom: 5px;
  }
}

.presence-detector-fields .rs-checkbox .rs-checkbox-checker {
  padding-left: 26px;
}
.presence-detector-fields .rs-checkbox .rs-checkbox-wrapper {
  left: 0;
}
