.inspect-account-form-wrap form.inspect-account .form-control {
	width: 100%;
	padding: 0 20px;
}
.inspect-account-form-wrap form.inspect-account .content {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}
.inspect-account-form-wrap .form.inspect-account > .footer {
    margin-top: 30px;
}
.inspect-account-form-wrap .form.inspect-account > .error {
    text-align: center;
}
.inspect-account-form-wrap .form.inspect-account .inspect-account-buttons {
	margin: 0 auto;
    width: 400px;
    grid-gap: 30px;
}
.modal .inspect-account-buttons  {
    margin: 30px auto 20px auto;
    width: 400px;
    grid-gap: 30px;
}
.inspect-account-form-wrap form.authorization-form {
	margin: 20px 100px;
}
.inspect-account-form-wrap .authorization-form.form .button-group {
	padding: 0 50px;
	grid-template-columns: 1fr 1fr;
}
