.device-short-info-view {
	display: flex;
	align-items: center;
}

.device-short-info-view > *:first-child {
	margin-right: 10px;
}

.device-short-info-view .device-name {
	font-weight: bold;
}