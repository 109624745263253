@import '../../defaults.scss';

.app-page.commute-detectors .form {
  height: 100%;
}

.app-page.commute-detectors .body {
  height: 100%;
}

.app-page.commute-detectors .sidebar .header {
  display: flex;
  align-items: center;
  margin-right: 95px;
}

.app-page.commute-detectors .sidebar .header .icon > * {
  margin-right: 5px;
  display: block;
}

.app-page.commute-detectors .sidebar-content {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: hidden;
}

.app-page.commute-detectors .sidebar-content .error {
  margin-top: 10px;
  text-align: center;
}

.app-page.commute-detectors .sidebar-content .filter {
  border-bottom: 1px solid $color-border;
  padding: 15px;
}

.app-page.commute-detectors .sidebar-content .filter .input {
  display: flex;
  flex-direction: column;
}

.app-page.commute-detectors .sidebar-content .filter .input label {
  margin-bottom: 10px;
}

.app-page.commute-detectors .sidebar-content .action-bar {
  position: absolute;
  top: 0;
  right: 0;
  padding: 25px 15px;
}

.app-page.commute-detectors .sidebar-content .action-bar .actions {
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: 16px;
  grid-gap: 16px;
  cursor: pointer;
}

.app-page.commute-detectors .sidebar-content .action-bar .actions > * img {
  filter: $text-filter-main;
}

.app-page.commute-detectors .sidebar-content > .content {
  padding: 15px;
  overflow-y: auto;
}

.app-page.commute-detectors .sidebar-content > .content > .button {
  margin-top: 20px;
}

.app-page.commute-detectors .commute-detector.card {
  border-top: 1px solid $color-border;
  padding: 15px;
}

.app-page.commute-detectors .commute-detector.card .options .option::first-letter {
  text-transform: capitalize;
}

.app-page.commute-detectors .commute-detector.card .name div {
  overflow-wrap: break-word;
}

.app-page.commute-detectors .commute-detector.card > div ~ div {
  margin-top: 10px;
}

.app-page.commute-detectors .layout.sidebar-layout > .content {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  border-left: 1px solid $color-border;
}

.app-page.commute-detectors .list .list-item {
  overflow-wrap: break-word;
  padding: $list-item-default-padding;
}
