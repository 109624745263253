@import "../../../../defaults.scss";

.device-selector > * ~ * {
	margin-top: 15px;
}

/* search control */

.device-selector .device-search-control .content {
	background-color: white;
    color: $text-color-main;
	border-radius: 2px;
    font-weight: normal;
}
.device-selector .device-search-control .content > input {
	border: 1px solid $color-border;
	width: 100%;
    background-color: transparent;
    caret-color: $text-color-main;
	padding: 7px 11px;
	padding-right: 15px;
}
.device-selector .device-search-control .content > .action-clear {
	margin-left: -15px;
}
.device-selector .device-search-control .content > input:focus {
	border-color: $color-primary;
}

.device-selector .grouping-picker .rs-picker-toggle {
	background-color: white !important;
	border: 1px solid $color-border !important;
	border-radius: 3px;
}
.modal .device-selector-buttons {
	margin: 35px 0 20px 0;
}

/* list */

.device-selector .category-list {
	height: 490px;
	border: 1px solid $color-border;
}
.device-selector .category-list .category {
	padding: 15px 0 15px 15px;
	box-shadow: none;
	color: $text-color-main;
	background-color: white;
	font-weight: bold;
	text-transform: capitalize;
	border-bottom: 1px solid $color-border;
	border-top: 1px solid $color-border;
}
.device-selector .category-list .category.expandable:not(:nth-last-child(2)),
.device-selector .category-list .category.empty:not(:nth-last-child(2)) {
	border-bottom: none;
}
.device-selector .category-list .category:first-child,
.device-selector .category-list .category:last-child {
	border-top: none;
}
.device-selector .device-list .list-item {
	padding: 15px;
}