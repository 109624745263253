@import '../../../defaults.scss';

.message-publisher-view.body {
	display: flex;
	flex-direction: row;
	width: 100%;
}

.message-publisher-view.body .object-device-manager {
	display: flex;
	flex-direction: column;
}

.message-publisher-view.body .object-device-manager > *:last-child {
	flex-grow: 1;
	overflow-y: auto;
}

.message-publisher-view.body .message-publisher-details {
	height: 100%;
	max-width: 380px;
	width: 100%;
	display: flex;
	flex-direction: column;
	border-right: 1px solid #e5e5ea;
}

.message-publisher-view.body .object-device-manager {
	height: 100%;
	width: 280px;
	border-right: 1px solid $color-border;
}

.message-publisher-view.body .message-publisher-details .container {
	padding: 0 15px 15px;
	overflow-y: auto;
}

.message-publisher-view.body .message-publisher-details .x-list.list,
.message-publisher-view.body .x-list.list {
	display: block;
}

.message-publisher-view.body .object-device-manager .header,
.message-publisher-view.body .message-publisher-details .header {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	padding: 15px;
}

.message-publisher-view.body .message-publisher-details .container .x-list.list .list-item {
	width: 100%;
	border-radius: 0;
	background-color: white;
}

.message-publisher-view.body .message-publisher-details .container .x-list .list-item:nth-child(odd) {
	background-color: #f7f7f7;
}

.message-publisher-view.body .list-item.droppable {
	background-color: $color-primary !important;
	color: white !important;
	box-shadow: 0px 2px 7px -2px rgba(0, 0, 0, 0.4);
}

.message-publisher-view.body span.no-items {
	padding: 15px;
}

@media(max-width: 890px) {
	.message-publisher-view.body {
		display: grid;
		grid-template-columns: none;
		grid-template-rows: repeat(auto-fit, minmax(200px, max-content));
	}

	.message-publisher-view.body .object-device-manager {
		width: 100%;
		border: none;
	}

	.message-publisher-view.body .message-publisher-details {
		border-top: 1px solid $color-border;
		max-width: 100%;
	}
}
