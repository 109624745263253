@import '../../defaults.scss';

.gallery-cards {
	width: 100%;
	height: 180px;
}
.gallery-cards.xs {
	height: 100px;
}
.gallery-cards.s {
	height: 135px;
}
.gallery-cards.l {
	height: 225px;
}
.gallery-cards.xl {
	height: 270px;
}

.gallery-cards-wrap {
	height: 100%;
	position: relative;
	border: 1px solid $color-border;
	border-radius: 5px;
}

.gallery-cards-wrap .gallery-arrow {
	display: none;
	z-index: 2;
	height: 100%;
	justify-content: center;
	align-items: center;
	position: absolute;
	top: 0;
	width: 26px;
	border-radius: 15px;
	height: 100%;
	box-shadow: 0px 0px 0px 1px #ffffff60;
	background-color: #ffffff60;
}
.gallery-cards.xs .gallery-arrow {
	width: 18px;
}
.gallery-cards.s .gallery-arrow {
	width: 20px;
}
.gallery-cards.l .gallery-arrow {
	width: 40px;
}
.gallery-cards.xl .gallery-arrow {
	width: 45px;
}
.gallery-cards-wrap .gallery-arrow.visible {
	display: flex !important;
}
.gallery-cards-wrap .gallery-arrow > * {
	width: 100%;
	height: 100%;
	color: $color-primary;
	opacity: 0.7;
}
.gallery-cards-wrap .gallery-arrow.left {
	left: 0;
}
.gallery-cards-wrap .gallery-arrow.right {
	right: 0px;
}
.gallery-cards-wrap .gallery-arrow:hover {
	box-shadow: 0px 0px 0px 1px #ffffff9a;
	background-color: #ffffff9a;
}
.gallery-cards-wrap .gallery-arrow:hover > * {
	opacity: 1;
}
.gallery-cards-wrap .gallery-content,
.gallery-cards-wrap .gallery-content-inner {
	max-width: inherit;
}
.gallery-cards .gallery-content {
	display: flex;
	height: 100%;
	overflow: auto;
	padding: 10px 0;
	scrollbar-width: none !important;

}
.gallery-cards.xs .gallery-content {
	padding: 5px 0;
}
.gallery-cards.s .gallery-content {
	padding: 7px 0;
}
.gallery-cards.l .gallery-content {
	padding: 12px 0;
}
.gallery-cards.xl .gallery-content {
	padding: 15px 0;
}
.gallery-cards-wrap .gallery-content::-webkit-scrollbar {
    height: 0px;
}
.gallery-cards-wrap .gallery-content-inner {
	height: 100%;
}
.gallery-cards-wrap .gallery-content > *:first-child {
	margin-left: 25px;
}
.gallery-cards.xs .gallery-content > *:first-child {
	margin-left: 10px;
}
.gallery-cards.s .gallery-content > *:first-child {
	margin-left: 17px;
}
.gallery-cards.l .gallery-content > *:first-child {
	margin-left: 25px;
}
.gallery-cards.xl .gallery-content > *:first-child {
	margin-left: 30px;
}
.gallery-cards-wrap .gallery-content .clear-box {
	border: none !important;
	background-color: transparent !important;
	box-shadow: none !important;
}
.gallery-cards-wrap .gallery-content .card {
	height: 100%;
	border: 1px solid #e5e5ea;
	border-radius: 5px;
	box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.08),0 1px 1px 0 rgba(0, 0, 0, 0.00);
	transition: transform 0.3s;
}
.gallery-cards-wrap .gallery-content .card:hover {
	box-shadow: 0px 2px 7px -2px rgba(0, 0, 0, 0.4);
	transform: translate(0px, -2px) scale(1.03);
	transition: transform 0.3s;
}