@import '../../../defaults.scss';

.authorization-form.form .floating-label-input .description {
	display: flex;
	align-items: center;
}

.authorization-form.form .floating-label-input .description .resend span {
	user-select: none;
	margin-left: 5px;
	color: $link-color;
	text-decoration: underline;
}
.authorization-form.form .floating-label-input .description .resend span.disabled {
	color: $text-color-pale;
}
.authorization-form.form .floating-label-input .description .resend span.disabled,
.authorization-form.form .floating-label-input .description .resend span:hover {
	text-decoration: none;
}
.authorization-form.form .button-group {
	margin-top: 20px;
}
.authorization-form.form .form-control .error:not(.floating-label-input) {
	margin: 3px 13px;
}
