.report-summary {
	padding: 0 15px 15px;
}
.report-summary > *:not(:first-child) {
	margin-top: 10px;
}
.report-summary .data {
	display: flex;
}
.report-summary .data > *:not(:first-child) {
	margin-left: 15px;
}
.report-summary .data > *:not(:first-child) .label {
	display: inline-block;
}
.report-summary .data .label {
	font-weight: bold;
}
.report-summary .data .time-range > span {
	margin-right: 5px;
}
.report-summary .generated::first-letter {
	text-transform: capitalize;
}