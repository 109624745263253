@import '../../../defaults.scss';

.coin-board {
	border: 3px solid $color-widget-primary;
	border-radius: 50px;
	width: 75px; height: 75px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}
.coin-board .count > span {
	color: $color-primary;
	font-weight: bold;
	font-size: large;
}
.coin-board .title > span {
	color: $text-color-main;
	font-weight: bold;
	font-size: medium;
}
