@import '../../../defaults.scss';

.message-fields-time-location .updated-at {
	display: flex;
	margin-bottom: 10px;
}
.message-fields-time-location .updated-at > *:first-child {
	font-weight: bold;
}
.message-fields-time-location .updated-at > *:first-child::first-letter {
	text-transform: capitalize;
}
.message-fields-time-location .updated-at > *:last-child {
	flex-grow: 1;
	justify-content: flex-end;
}
.message-fields-time-location img {
	height: 16px;
	vertical-align: baseline;
	margin-right: 3px;
	filter: $text-filter-main;
}