.employee-fields > .employee-categories {
	position: relative;
	padding-top: 15px;
}
.employee-fields .categories-control.employee-categories .header .action-bar {
	padding: 0;
	padding-top: 15px;
}
.employee-fields .last-information {
	padding-top: 25px;
}
