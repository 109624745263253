@import '../../../../../defaults.scss';

.bar-chart {
	display: flex;
    flex-direction: column;
    align-items: center;
}
.bar-chart .histogram {
	display: flex;
    flex-direction: row;
	justify-content: space-between;
	height: 95px;
	width: min-content;
	margin-bottom: 5px;
}
.bar-chart .histogram .container {
	display: flex;
	align-items: flex-end;
	border-radius: 99px;
	height: 100%;
	margin: 0 2px;
}
.bar-chart .histogram .container:hover {
	background-color: $color-primary-dark-bg;
}
.bar-chart .histogram .bin {
	border-radius: 99px;
	padding: 3px;
	background-color: $color-primary;
}
.bar-chart .histogram .bin:hover {
	background-color: $color-primary-pale;
}
.bar-chart .total-quantity {
	text-align: center;
	font-weight: bold;
	font-size: 20px;
}