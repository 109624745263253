@import "../../../../defaults.scss";

.form-control.properties > * ~ * {
	margin-top: 15px;
}
.form-control.properties .header {
	display: flex;
	align-items: center;
}

.form-control.properties .header > label {
	flex-grow: 1;
	margin: 0;
}

.form-control.properties .property-composite.root.bundle > label {
	color: #a0a0a0;
    font-variant: small-caps;
    letter-spacing: 1px;
}

.form-control.properties .form-control.droppable *,
.form-control.properties .form-control-group.droppable * {
	color: $color-primary;
}