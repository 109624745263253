@import '../../../defaults.scss';

.presence-detector.body {
	display: flex;
	flex-direction: row;
	width: 100%;
}
.presence-detector.body .object-device-manager {
	display: flex;
	flex-direction: column;
}
.presence-detector.body .object-device-manager > *:last-child {
	flex-grow: 1;
	overflow-y: auto;
}
.presence-detector.body .object-device-manager,
.presence-detector.body .categories-control,
.presence-detector.body .presence-detector-categories {
	height: 100%;
	width: 280px;
	border-right: 1px solid $color-border;
	display: flex;
	flex-direction: column;
}
.presence-detector.body .categories-control .x-list.list,
.presence-detector.body .x-list.list {
	display: block;
}
.presence-detector.body .object-device-manager .header,
.presence-detector.body .categories-control .header,
.presence-detector.body .presence-detector-categories .header {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	padding: 15px;
	margin-bottom: 0;
}
.presence-detector.body .presence-detector-categories .header {
	font-weight: bold;
}
.presence-detector.body .categories-control .content {
	overflow-y: auto;
}
.presence-detector.body .categories-control.empty .content {
	padding: $list-item-default-padding;
}
.presence-detector.body .categories-control .content .x-list.list .list-item {
	width: 100%;
	border-radius: 0;
	background-color: white;
}
.presence-detector.body .categories-control .content .x-list .list-item:nth-child(odd) {
	background-color: #f7f7f7;
}
.presence-detector.body .list-item.droppable {
	background-color: $color-primary !important;
	color: white !important;
	box-shadow: 0px 2px 7px -2px rgba(0, 0, 0, 0.4);
}
.presence-detector.body span.no-items {
	padding: 15px;
}
