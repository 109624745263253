.device-map-view {
	height: 300px;
	position: relative;
}
.device-map-view .map {
	height: 300px;
}

.device-map-view.fullscreen > .content {
	position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 100;
    margin: 0 !important;
    padding: 0 !important;
}

.device-map-view.fullscreen .map {
	height: 100% !important;
}