@import "../../../defaults.scss";

.x-picker {
	color: $text-color-main;
}
.x-picker.loading > .bp3-spinner {
	height: calc(1em * 1.42857143 + 2 * (7px + 1px));
}
.x-picker > .description {
	font-size: 11px;
}
.x-picker .rs-picker-input,
.x-picker .rs-picker-toggle {
	border-radius: 2px;
}
.x-picker .rs-picker-toggle {
	height: 100% !important;
	border-color: $color-border;
}
.x-picker .rs-picker-toggle:hover,
.x-picker .rs-picker-toggle.active {
	border-color: $color-primary !important;
}
.rs-picker-date-menu .rs-picker-toolbar-right .rs-picker-toolbar-right-btn-ok {
	color: $color-primary;
	background-color: white;
	border: 1px solid $color-primary;
	border-radius: 20px;
	font-weight: bold;
}
.rs-picker-date-menu .rs-picker-toolbar-right .rs-picker-toolbar-right-btn-ok:hover {
	background-color: $color-primary-pale;
}
.rs-picker-date-menu .rs-picker-toolbar-right .rs-picker-toolbar-right-btn-ok:active {
	background-color: $color-primary;
	color: white;
}
.rs-calendar-time-dropdown-cell-active.rs-calendar-time-dropdown-cell,
.rs-calendar-time-dropdown-cell-active.rs-calendar-time-dropdown-cell:hover {
	background-color: $color-primary !important;
}
.rs-calendar-show-month-dropdown .rs-calendar-header-title-date,
.rs-calendar-show-time-dropdown .rs-calendar-header-title-time {
	color: $color-primary !important;
}
.rs-calendar-month-dropdown-cell-active .rs-calendar-month-dropdown-cell-content {
	background-color: $color-primary !important;
	border-color: $color-primary !important;
}
.rs-calendar-month-dropdown-year-active {
	color: $color-primary !important;
}
.rs-picker-date-menu .rs-calendar-table-cell-selected .rs-calendar-table-cell-content {
    border-color: $color-primary;
    background-color: $color-primary;
}
.rs-picker-date-menu .rs-picker-toolbar .rs-picker-toolbar-option {
	color: $color-primary;
	font-weight: bold;
	text-decoration: none;
}
.rs-picker-date-menu .rs-calendar-table-cell.rs-calendar-table-cell-is-today > * {
    border-color: $color-primary;
}
.x-picker .rs-picker-toggle-value {
	color: inherit !important;
}

.x-picker .rs-picker-input:hover,
.x-picker .rs-picker-input.rs-picker-focused,
.rs-picker-menu .rs-picker-search-bar .rs-picker-search-bar-input:hover {
	border-color: $color-primary !important;
}

.x-picker .rs-picker-tag .rs-picker-tag-wrapper {
	min-height: calc(2rem + 2px);
}

.x-picker label {
	display: block;
	margin-bottom: 8px;
}
.x-picker label::first-letter {
	text-transform: capitalize;
}

.x-picker.inline-control {
	display: inline;
}
.x-picker.hide-control .rs-picker-date,
.x-picker.hide-control .rs-picker-daterange {
	visibility: hidden;
	width: 0;
	height: 0;
}

.rs-picker-menu {
	z-index: 20;
}
