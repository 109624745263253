@import "../../../defaults.scss";

.x-list {
	width: 100%;
	height: 100%;
	overflow-y: auto;
}

.x-list .list-item {
	padding: 7px 5px;
}

.x-list .list-item:nth-child(odd) {
	background-color: $list-item-bg-odd;
}

.x-list .list-item.clickable {
	cursor: pointer;
}

.x-list .list-item.selected {
	background-color: $list-item-bg-selected;
}