@import "../../defaults.scss";

$margins: 30px;

.app-page.reports {
	display: flex;
	height: calc(100vh - #{$header-height} - #{$margins * 2});
	margin: $margins;
	border: 1px solid $color-border;
	border-radius: 5px;
	background-color: white;
}

.app-page.reports .wrapper {
	position: relative;
	display: grid;
	flex-grow: 1;
	grid-template-columns: 260px 1fr;
	grid-template-rows: 100%;
}

.app-page.reports .side {
	position: relative;
	border-right: 1px solid $color-border;
	overflow-y: auto;
}

.app-page.reports .side > * {
	padding: 15px;
}

.app-page.reports .side .action-bar {
	position: absolute;
	top: 0; right: 0;
	height: 52px;
	padding: 15px 15px;
	z-index: 7;
}

.app-page.reports .side > .header {
	display: flex;
	flex-direction: row;
	font-size: larger;
	font-weight: bold;
	position: sticky;
	top: 0;
	background-color: white;
	z-index: 6;
}

.app-page.reports .side > .header img {
	height: 22px;
	filter: $text-filter-main;
	margin-right: 10px;
}

.app-page.reports .side .modes {
	margin-top: 20px;
}

@media (max-width: $screen-tablet) {
	.app-page.reports .wrapper {
		grid-template-columns: 190px 1fr;
	}
}

.app-page.reports .content > * > * > .header {
	display: flex;
	flex-direction: row;
	align-items: center;
	height: 52px;
	padding: 15px;
	font-weight: 700;
}

.app-page.reports .content > * > * > .header > .title::first-letter {
	text-transform: capitalize;
}
