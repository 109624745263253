@import "../../../defaults.scss";

.nav-link-item a {
	display: flex;
    flex-direction: row;
	align-items: center;
    border-radius: 2px;
    text-decoration: none;
    color: inherit;
}
.nav-link-item a.active {
    color: $color-primary;
    text-decoration: none;
}
.nav-link-item a:hover:not(.disabled) {
    background-color: rgba(167, 182, 194, 0.3);
    color: $color-primary;
    text-decoration: none;
}