@import '../../../defaults.scss';

.fuel-processor.body {
	display: flex;
	flex-direction: row;
	width: 100%;
}
.fuel-processor.body .object-device-manager {
	display: flex;
	flex-direction: column;
}
.fuel-processor.body .object-device-manager > *:last-child {
	flex-grow: 1;
	overflow-y: auto;
}
.fuel-processor.body .fuel-level-processor-field {
	height: 100%;
	max-width: 380px;
	width: 100%;
	display: flex;
    flex-direction: column;
}
.fuel-processor.body .object-device-manager {
	height: 100%;
	width: 280px;
	border-right: 1px solid $color-border;
}
.fuel-processor.body .fuel-level-processor-field > .content {
	padding: 0 15px 15px;
	flex-grow: 1;
    overflow-y: auto;
}
.fuel-processor.body .fuel-level-processor-field > .content > div {
	margin-bottom: 10px;
}
.fuel-processor.body .x-list.list {
	display: block;
}
.fuel-processor.body .object-device-manager .header,
.fuel-processor.body .fuel-level-processor-field .header {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	padding: 15px;
}
.fuel-processor.body .list-item.droppable {
	background-color: $color-primary !important;
	color: white !important;
	box-shadow: 0px 2px 7px -2px rgba(0, 0, 0, 0.4);
}
.fuel-processor.body span.no-items {
	padding: 15px;
}

@media(max-width: 890px) {
    .fuel-processor.body {
        display: grid;
        grid-template-columns: none;
		grid-template-rows: repeat(auto-fit, minmax(200px, max-content));
	}
	.fuel-processor.body .object-device-manager {
		width: 100%;
		border: none;
	}
	.fuel-processor.body .fuel-level-processor-field {
		border-top: 1px solid $color-border;
		max-width: 100%;
	}
}
