@import "../../../defaults.scss";

.device-property {
	cursor: pointer;
	border: 1px solid $color-border;
	border-radius: 3px;
	padding: 10px;
	display: flex;
	flex-direction: column;
	min-height: 75px;
    transition: transform 0.3s;
}
.device-property:hover {
	background-color: $color-primary-pale;
	box-shadow: 0px 2px 7px -2px rgba(0, 0, 0, 0.4);
	transform: translate(0px, -5px) scale(1.02);
}
.device-property.droppable {
	background-color: $color-primary;
	color: white;
	box-shadow: 0px 2px 7px -2px rgba(0, 0, 0, 0.4);
}
.device-property .property-info {
	flex-grow: 1;
}
.device-property .property-info .name {
	font-weight: bold;
	overflow-wrap: break-word;
}
.device-property > .description {
	overflow-wrap: break-word;
}