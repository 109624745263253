@import '../../../defaults.scss';

.datetime-field.clickable {
	color: $link-color;
	text-decoration: none;
}
.datetime-field.clickable:hover {
	text-decoration: underline;
}
.datetime-field time {
	word-break: break-word;
}