@import '../../../defaults.scss';

.color-picker-control .swatch {
	padding: 5px;
	background: #fff;
	border-radius: 1px;
	box-shadow: 0 0 0 1px rgba(0,0,0,.1);
	display: inline-block;
	cursor: pointer;
}
.color-picker-control .popover {
	border: 1px solid $color-border;
	background: white;
	padding: 10px;
	position: absolute;
	z-index: 2;
	width: 100%;
}
.color-picker-control .color-preview-box {
	width: 14px;
	height: 14px;
	border-radius: 2px;
}
.color-picker-control .color-preview-box.disabled {
	opacity: 0.4;
}