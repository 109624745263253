@import '../../../defaults.scss';

.marker {
	cursor: pointer;
	position: absolute;
	left: -29px;
	top: -29px;
	width: 58px;
	height: 58px;
	background: center no-repeat url('../../../img/markers/marker.png');
}

.marker > div.body {
	position: absolute;
	width: 100%;
	height: 100%;
	mask-image: url('../../../img/markers/circle.png');
	-webkit-mask-image: url('../../../img/markers/circle.png');
}
.marker.pointer > div.body {
	mask-image: url('../../../img/markers/pointer.png');
	-webkit-mask-image: url('../../../img/markers/pointer.png');
}

@each $assetType in $assetTypes {
	.marker.#{$assetType}::after {
		@include assetTypeIcon($assetType);
	}
}

.marker > div.marker-label {
	position: absolute;
	left: 50%;
	top: -20px;
	bottom: calc(29px + 0.4px);
}
.marker > div.marker-label > div {
	white-space: nowrap;
	background-color: white;
	padding: 0.25em 0.5em;
	overflow: hidden;
	border: 1px solid hsl(0, 0%, 75%);
	position: relative;
	left: -50%;
	max-width: 200px;
	text-overflow: ellipsis;
	user-select: none;
	color: $text-color-light;
	cursor: pointer;
}

.marker::after {
	content: '';
	display: block;
	position: absolute;
	top: 13px;
	left: 13px;
	width: 32px;
	height: 32px;
	mask-image: url('../../../img/assetTypes/MD/car.png');
	mask-size: 20px;
	mask-position: 50% 50%;
	mask-repeat: no-repeat;
	-webkit-mask-image: url('../../../img/assetTypes/MD/car.png');
	-webkit-mask-size: 20px;
	-webkit-mask-position: 50% 50%;
	-webkit-mask-repeat: no-repeat;
}

.marker.online > div.body,
.marker.online::after {
	background-color: $device-status-color-online;
}
.marker.active > div.body,
.marker.active::after {
	background-color: $device-status-color-active;
}
.marker.idle > div.body,
.marker.idle::after {
	background-color: $device-status-color-idle;
}
.marker.offline > div.body,
.marker.offline::after {
	background-color: $device-status-color-offline;
}
.marker > div.body,
.marker::after,
.marker.stale > div.body,
.marker.stale::after {
	background-color: $device-status-color-stale;
}

.marker.selected::before {
	content: '';
	display: block;
	width: 32px;
	height: 32px;
	position: absolute;
	top: 13px;
	left: 13px;
	border-radius: 16px;
	box-shadow: 0 0 8px 2px $device-status-color-stale;
}

.marker > div.marker-label > div {
	color: $device-status-text-color-stale;
	box-shadow: 0 3px 8px 2px $device-label-shadow-color;
}
.marker.online > div.marker-label > div {
	color: $device-status-text-color-online;
}
.marker.active > div.marker-label > div {
	color: $device-status-text-color-active;
}
.marker.idle > div.marker-label > div {
	color: $device-status-text-color-idle;
}
.marker.offline > div.marker-label > div {
	color: $device-status-text-color-offline;
}

.marker.selected > div.marker-label > div {
	box-shadow: 0 3px 10px 2px $device-label-shadow-selected-color;
}
.marker.selected.online::before {
	box-shadow: 0 0 8px 2px $device-status-color-online;
}
.marker.selected.active::before {
	box-shadow: 0 0 8px 2px $device-status-color-active;
}
.marker.selected.idle::before {
	box-shadow: 0 0 8px 2px $device-status-color-idle;
}
.marker.selected.offline::before {
	box-shadow: 0 0 8px 2px $device-status-color-offline;
}
