@import '../../../../defaults.scss';

.mqtt-clients-table {
  display: flex;
  flex-direction: column;
  width: 100%;

  &--header {
    position: sticky;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 17px 15px;
    font-weight: bold;
    border-bottom: 1px solid $color-border;
    background-color: white;
    z-index: 1;
    margin: 0;
    cursor: default;
  }

  &--column {
    width: 200px;
    text-overflow: ellipsis;
    overflow: hidden;

    &-id {
      width: 100px;
    }

    &-identifier,
    &-description {
      width: 300px;
    }
  }
}

.mqtt-clients-table .mqtt-clients-list.x-list {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}

.mqtt-clients-table .employee-header-list-item,
.mqtt-clients-table .mqtt-clients-list.x-list > * {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 15px;
}

.mqtt-clients-table .mqtt-clients-list .list-item.droppable {
  background-color: $droppable-background-color;
  color: $droppable-text-color;
  box-shadow: $droppable-box-shadow;
  z-index: 1;
}

.mqtt-clients-table .mqtt-clients-list .list-item:not(.droppable).canDrop:nth-child(even) {
  background-color: $can-drop-background-color-even;
}

.mqtt-clients-table .mqtt-clients-list .list-item:not(.droppable).canDrop:nth-child(odd) {
  background-color: $can-drop-background-color-odd;
}
