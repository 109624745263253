@import '../../../../defaults.scss';

.report.device-registry div.device-registry {
	display: grid;
	width: 100%;
	padding: 0;
}
.report.device-registry .x-list.scroll-list {
	border-top: 1px solid $color-border;
}
.report.device-registry div.device-registry div {
	padding: 10px 5px;
	border-right: 1px solid $color-border;
	border-bottom: 1px solid $color-border;
	display: block;
	text-align: center;
	text-overflow: ellipsis;
	overflow: hidden;
    white-space: nowrap;
}
.report.device-registry div.device-registry div.header-item {
	padding: 10px;
	display: flex;
	justify-content: center;
	align-items: flex-end;
	white-space: normal;
	overflow: visible;
}
.report.device-registry div.device-registry div.header-item:first-child {
	justify-content: flex-start;
	padding: 10px 5px;
}