.message-publisher-details .details-content .form-control,
.message-publisher-details .details-content .form-control-group {
	margin-bottom: 15px;
}

.destination-input .input {
	display: flex;
	align-items: center;

	& > label {
		margin-right: 10px;
		margin-bottom: 0;
	}
}
