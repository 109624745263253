.device-command .header {
	margin-bottom: 15px;
}
.device-command .header > div {
	margin-bottom: 10px;
}
.device-command .event-icon {
	height: 20px;
    width: 20px;
    min-width: 20px;
    background-size: 20px;
}
.device-command .status {
	display: grid;
	grid-template-columns: 20px auto;
	grid-gap: 5px;
}
.device-command .status span {
	height: 20px;
}
.device-command .status span.icon {
	font-size: 20px;
}