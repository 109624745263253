.zone-guard-list {
    display: flex;
    flex-direction: column;
}
.zone-guard-list label {
    padding: 15px;
}
.zone-guard-list {
	flex-grow: 1;
    display: flex;
    flex-direction: column;
    overflow-y: auto;;
}
.zone-guard-list .list-item {
    padding: 10px 15px;
}
.zone-guard-list .list-item.selected {
    background-color: #d0d0d0;
}
.zone-guard-list .list-item.droppable {
    background-color: #79CDD0 !important;
    color: white !important;
    box-shadow: 0px 2px 7px -2px rgba(0, 0, 0, 0.4);
}