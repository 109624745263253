@import '../zoneX.scss';

.zone-viewer .sidebar-content {
	flex-grow: 1;
	display: flex;
	flex-direction: column;
	overflow: hidden;
}
.zone-viewer .sidebar-content .zone-filter {
	padding: 15px;
	background-color: white;
	border-bottom: 1px solid $color-border;
}
.zone-viewer .sidebar-content .zone-filter .input {
	display: flex;
	flex-direction: column;
}
.zone-viewer .sidebar-content .zone-filter .input label {
	margin-bottom: 10px;
}
.zone-viewer .sidebar-content .lists {
	overflow-y: auto;
	display: flex;
	flex-direction: column;
	flex-grow: 1;
}
.zone-viewer .sidebar-content .lists > *.collapsed:not(:last-child) .header {
	border-bottom: none !important;
}
.zone-viewer .sidebar-content .lists .zone-category-selector {
	max-height: 50%;
	display: flex;
	flex-direction: column;
}

.zone-viewer .zone-select-map {
	height: 100%;
	position: relative;
}
.zone-viewer .zone-select-map .map-button.focus-all.disabled {
	opacity: 0.5;
	cursor: not-allowed;
}
.zone-viewer .zone-select-map .map-button.focus-all.disabled:hover > div {
	background-color: rgba(0,60,136,0.5);
}
