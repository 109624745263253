@import "../../../defaults.scss";

.device-status-icon {
	position: relative;
	width: 16px; height: 16px;
}

.device-status-icon > div {
	position: absolute;
	width: 100%; height: 100%;
	mask-image: url('../../../img/status/16/circle.png');
	-webkit-mask-image: url('../../../img/status/16/circle.png');
	background-color: $device-status-color-stale;
}
.device-status-icon.pointer > div {
	mask-image: url('../../../img/status/16/pointer.png');
	-webkit-mask-image: url('../../../img/status/16/pointer.png');
}

.device-status-icon.large {
	width: 32px; height: 32px;
}
.device-status-icon.large > div {
	mask-image: url('../../../img/status/32/circle.png');
	-webkit-mask-image: url('../../../img/status/32/circle.png');
}
.device-status-icon.large.pointer > div {
	mask-image: url('../../../img/status/32/pointer.png');
	-webkit-mask-image: url('../../../img/status/32/pointer.png');
}

.device-status-icon.online > div {
	background-color: $device-status-color-online;
}
.device-status-icon.active > div {
	background-color: $device-status-color-active;
}
.device-status-icon.idle > div {
	background-color: $device-status-color-idle;
}
.device-status-icon.offline > div {
	background-color: $device-status-color-offline;
}
.device-status-icon > div,
.device-status-icon.stale > div {
	background-color: $device-status-color-stale;
}