.widget-menu-item {
    position: relative;
}
.widget-menu-item .bp3-menu-item {
    height: 36px;
}
.widget-menu-item:hover .checkbox-pin-action {
    display: block;
}
.checkbox-pin-action {
    position: absolute;
    display: none;
    right: 0;
    bottom: 0;
}
