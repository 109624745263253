@import '../../../defaults.scss';

.widget-layout-manager {
	position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
	pointer-events: none;
	padding-left: 40px;
	overflow-y: auto;
}
.widget-layout-manager.ctrl {
	pointer-events: all;
}
.widget-layout-manager > .content {
	position: relative;
	width: 100%;
	height: 100%;
}
.widget-layout-manager > .content > .react-grid-layout {
	max-height: 100% !important;
}
.react-grid-item.react-grid-placeholder {
	background-color: gray !important;
}