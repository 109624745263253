@import '../../../../defaults.scss';
@import './index.scss';

.report.device-usages .scroll-list {
	display: grid;
	grid-template-columns:
		minmax(90px, 5%) // No
		minmax(150px, 1fr) // device
		minmax(80px, 6%) // days
		minmax(80px, 6%) // trips
		minmax(80px, 6%) // distance
	;
}

.report.device-usages .scroll-list .loader,
.report.device-usages .scroll-list .indicator {
	grid-column: 1 / 6;
}

.report.device-usages .scroll-list > *:nth-child(10n+6),
.report.device-usages .scroll-list > *:nth-child(10n+7),
.report.device-usages .scroll-list > *:nth-child(10n+8),
.report.device-usages .scroll-list > *:nth-child(10n+9),
.report.device-usages .scroll-list > *:nth-child(10n+10) {
	background-color: $reports-bg-color;
}