@import "../../../defaults.scss";

.device-property-card > * ~ * {
	margin-top: 10px;
}
.device-property-card .property-elements .title {
	padding-bottom: 5px;
	margin-bottom: 5px;
	font-weight: bold;
	border-bottom: 1px dotted $color-border;
}
.device-property-card .property-elements .title::first-letter {
	text-transform: capitalize;
}
.device-property-card .element.composite > .element,
.device-property-card .elements {
	padding-left: 10px;
	margin-top: 5px;
	margin-left: 1px;
	border-left: 1px solid $color-border;
}
.device-property-card .element {
	margin-top: 5px;
}
.device-property-card .property-elements > .elements {
	margin: 0;
	padding: 0;
	border: none;
}
.device-property-card .element .name {
	text-transform: capitalize;
}