@import '../../../defaults.scss';

.category-picker {
	width: 100%;
}
.category-picker span.none {
	font-style: italic;
}
.category-picker .category-picker-board {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
}
.category-picker .category-picker-board .actions {
	display: flex;
	flex-direction: row;
	align-items: center;
}
.category-picker .category-picker-board .actions > * {
	margin-left: 3px;
}
.category-picker .category-picker-board .actions .clear {
	padding: 0 3px;
}
.category-picker .category-picker-board .actions .clear:hover {
	color: red;
}
.category-picker .category-picker-board .label {
	display: flex;
	flex-direction: row;
	align-items: center;
	margin-right: 5px;
}
.category-picker .category-picker-board .label label {
	margin-right: 5px;
	margin-bottom: 0;
}
.category-picker .arrow-button img {
	filter: $text-filter-main;
	height: 5px;
}
.category-picker .arrow-button img.rotate {
	transform: rotate(180deg);
}
.category-picker.bp3-popover.bp3-minimal {
	width: min-content;
	margin-left: -10px !important;
    margin-top: 10px !important;
    margin-bottom: 10px !important;
}
.category-picker .category-picker-content {
	height: 150px;
}
.category-picker .category-picker-content .list .list-item:hover {
	background-color: $color-primary-pale;
}
.category-picker .category-picker-content .list .list-item.selected {
	color: white;
	background-color: $color-primary;
}