.profile-control {
	min-width: 200px;
	max-width: 250px;
	padding: 10px;
}

.profile-control > *:first-child {
	margin-bottom: 10px;
}

.profile-control .rs-avatar {
	font-size: x-large;
}

.profile-control .user-info {
	display: grid;
	grid-gap: 10px;
	grid-template-columns: 60px 1fr;
}

.profile-control .user-info > *:first-child {
	grid-column: 1;
	grid-row: 1;
}

.profile-control .user-info > *:last-child {
	grid-column: 2;
	grid-row: 1;
	display: grid;
	grid-gap: 5px;
}

.profile-control .user-info .e-mail {
	font-size: smaller;
}

.profile-control .user-info button {
	font-size: smaller;
}