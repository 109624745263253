@import "../../../../defaults.scss";
@import "./index.scss";

$group-width:
	minmax(70px, 1fr) // min
	minmax(70px, 1fr) // max
	minmax(70px, 1fr) // avg
	minmax(70px, 1fr) // q-ty
;

.report.zove-visits .scroll-list {
	display: grid;
	grid-template-columns: repeat(7, auto);
}
.report.zove-visits .scroll-list.esg {
	display: grid;
	grid-template-columns: repeat(9, auto);
}

.report.zove-visits .scroll-list .header-item.rows,
.report.zove-visits .scroll-list .group {
	display: grid;
}
.report.zove-visits .scroll-list .loader,
.report.zove-visits .scroll-list .indicator {
	grid-column: 1 / 8;
}
.report.zove-visits .scroll-list.esg .loader,
.report.zove-visits .scroll-list.esg .indicator {
	grid-column: 1 / 10;
}
.report.zove-visits .scroll-list .header-item.rows > div:last-child {
	display: grid;
	grid-template-columns: $group-width;
}
.report.zove-visits .scroll-list .group {
	grid-template-columns: $group-width;
}

.report.zove-visits .scroll-list.esg > *:nth-child(18n+10),
.report.zove-visits .scroll-list.esg > *:nth-child(18n+11),
.report.zove-visits .scroll-list.esg > *:nth-child(18n+12),
.report.zove-visits .scroll-list.esg > *:nth-child(18n+13),
.report.zove-visits .scroll-list.esg > *:nth-child(18n+14),
.report.zove-visits .scroll-list.esg > *:nth-child(18n+15),
.report.zove-visits .scroll-list.esg > *:nth-child(18n+16),
.report.zove-visits .scroll-list.esg > *:nth-child(18n+17),
.report.zove-visits .scroll-list.esg > *:nth-child(18n+18) {
	background-color: $reports-bg-color;
}

.report.zove-visits .scroll-list:not(.esg) > *:nth-child(14n+8),
.report.zove-visits .scroll-list:not(.esg) > *:nth-child(14n+9),
.report.zove-visits .scroll-list:not(.esg) > *:nth-child(14n+10),
.report.zove-visits .scroll-list:not(.esg) > *:nth-child(14n+11),
.report.zove-visits .scroll-list:not(.esg) > *:nth-child(14n+12),
.report.zove-visits .scroll-list:not(.esg) > *:nth-child(14n+13),
.report.zove-visits .scroll-list:not(.esg) > *:nth-child(14n+14) {
	background-color: $reports-bg-color;
}

