@import '../../../defaults.scss';

.device-asset-type-icon-picker .rs-avatar svg {
	width: 32px;
	height: 32px;
}
.device-asset-type-icon-picker .device-asset-type-icon-picker-content .list {
	max-height: 300px;
}
.device-asset-type-icon-picker .device-asset-type-icon-picker-content .list-item {
	display: grid;
	grid-template-columns: 1fr 4fr;
	grid-gap: 5px;
	padding-left: 15px;
}
.device-asset-type-icon-picker .device-asset-type-icon-picker-content .list-item img {
	filter: $text-filter-main;
}
.device-asset-type-icon-picker .device-asset-type-icon-picker-content .list-item.selected {
	color: white;
	background-color: $color-primary;
}
.device-asset-type-icon-picker .device-asset-type-icon-picker-content .list-item:hover {
	background-color: $color-primary-pale;
}
.device-asset-type-icon-picker .device-asset-type-icon-picker-content .list-item > * {
	display: flex;
	align-items: center;
}