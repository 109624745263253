@import "../../../defaults.scss";

.device-form .description-fields > .content {
	display: grid;
	grid-template-columns: 9fr 1fr;
	grid-gap: 10px;
	align-items: flex-end;
}

.device-form .description-fields > .content > * {
	margin: 0;
}

.device-form .expired {
	color: $text-color-error;
}
