.device-requests-view .header .title a {
	display: flex;
	align-items: center;
}
.device-requests-view > .content {
	position: relative;
}
.device-requests-view > .content .command-tiles {
	display: grid;
	grid-template-columns: repeat(auto-fill, 250px);
	grid-gap: 15px;
	position: relative;
}
.device-requests-view > .object-action-dialog-popup {
	width: 250px;
}
.device-requests-view .content .object-action-dialog-popup > div:first-child {
	opacity: 1;
}
.device-requests-view > .content .command-tiles .device-command-tile.more {
	padding: 0;
}
.device-requests-view > .content .command-tiles .device-command-tile.more a {
	width: 100%; height: 100%;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	font-size: 32px;
}