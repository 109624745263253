.custom-tooltip {
	background-color: white;
	padding: 10px;
	border: 2px solid #79cdd0;
    color: black;
    border-radius: 5px;
}
.custom-tooltip > *:not(:last-child) {
	margin-bottom: 5px;
}
.custom-tooltip span.label {
	font-weight: bold;
}