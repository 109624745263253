.map-coordinate-slector {
	height: 400px;
	display: grid;
    grid-template-columns: 2fr 1fr;
    grid-gap: 15px;
}
.map-coordinate-slector-buttons {
	margin: 15px auto 0;
	max-width: 450px;
}
.map-coordinate-slector .coordinate-fields > div.input {
	display: flex;
    flex-direction: column;
	margin-bottom: 15px;
}
.map-coordinate-slector .coordinate-fields > div.input > label {
	margin-bottom: 5px;
}