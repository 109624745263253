.sign-in-options-form-wrap form.change-signIn-options {
	padding: 0 20px;
}
.sign-in-options-form-wrap form.change-signIn-options .form-control-group {
	margin-top: 20px;
}
.sign-in-options-form-wrap form.change-signIn-options .floating-label-input .wrap,
.sign-in-options-form-wrap form.change-signIn-options .form-control-group .content > * {
	height: auto;
	width: 100%;
	margin: 0;
}
.sign-in-options-form-wrap form.change-signIn-options .content {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}
.sign-in-options-form-wrap .form.change-signIn-options > .footer {
    margin-top: 30px;
}
.sign-in-options-form-wrap .form.change-signIn-options > .error {
    text-align: center;
}
.sign-in-options-form-wrap .form.change-signIn-options .change-signIn-options-buttons {
	margin: 0 auto;
    width: 400px;
    grid-gap: 30px;
}
.modal .change-signIn-options-buttons  {
    margin: 30px auto 20px auto;
    width: 400px;
    grid-gap: 30px;
}
.sign-in-options-form-wrap form.authorization-form {
	margin: 20px 100px;
}
.sign-in-options-form-wrap .authorization-form.form .button-group {
	padding: 0 50px;
	grid-template-columns: 1fr 1fr;
}
