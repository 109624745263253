.layout.panel-layout > .header {
	display: flex;
	flex-direction: row;
	padding: 20px;
}
.layout.panel-layout > .header > div:first-child {
	flex-grow: 1;
	display: flex;
	align-items: center;
}
.layout.panel-layout > .header > div:first-child > .icon {
	margin-right: 5px;
}
.layout.panel-layout > .header > div:first-child > .title {
	font-weight: bold;
	font-size: larger;
}