@import '../../../../defaults.scss';

.category-list {
  box-shadow: none;
}
.category-list .category {
  padding: 15px 0 15px 25px;
  box-shadow: none;
  color: #798699;
  background-color: #262c33;
  font-weight: bold;
  text-transform: capitalize;
}
.category-list .category .category-name:hover,
.category .category-icon:hover ~ .category-name {
  color: $color-primary;
}
.category-list .category.droppable {
  background-color: $color-primary;
  color: white !important;
}
.category-list .category .content {
  display: flex;
}
.category-list .category .content > * {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.category-list .category .category-icon {
  // TODO
  margin-right: 15px;
  font-weight: bold;
  color: #647080;
  position: relative;
}

.category-list .category .category-icon > img {
  height: 19px;
  margin: 3px;
  filter: $text-filter-main-2;
}

.category-list .category .category-icon .badge {
  position: absolute;
  left: 12px;
  top: -50%;
  background: #00adc6;
  border-radius: 7px;
  color: white;
  min-width: 15px;
  height: 15px;
  text-align: center;
  font-size: 78%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.category-list .category .category-icon .badge > span {
  padding: 0 2px;
}

.category-list .category .category-icon.dynamic {
  justify-content: center;
  color: #00adc6;
  width: 22px;
  height: 22px;
  border: 2px solid #00adc6;
  font-size: 77%;
}

.category-list .category .category-name {
  flex-grow: 1;
}

.category-list .sub-list {
  box-shadow: none;
  padding: 0;
}
.category-list .sub-list.collapsed {
  display: none;
}

.category-list .category span.toggle-view {
  padding: 0 10px 0 10px;
  margin-right: 10px;
}
.category-list .category span.toggle-view:hover {
  color: $color-primary;
}

.category-list .category .markers-visibility:hover {
  color: $color-primary;
}

.loading-wrapper {
  display: flex;
  width: 100%;
  height: 200px;
  align-items: center;
  justify-content: center;
}
