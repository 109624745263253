@import '../../../defaults.scss';

.flow-processor.body {
	display: flex;
	flex-direction: row;
	width: 100%;
}
.flow-processor.body .x-list.list {
	display: block;
}

.flow-processor.body .list-item.droppable {
	background-color: $color-primary !important;
	color: white !important;
	box-shadow: 0px 2px 7px -2px rgba(0, 0, 0, 0.4);
}
.flow-processor.body span.no-items {
	padding: 15px;
}


.flow-processor.body .object-device-manager .header
, .flow-processor.body .flow-processor-details .header {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	padding: 15px;
}


.flow-processor.body .object-device-manager {
	display: flex;
	flex-direction: column;
	height: 100%;
	width: 280px;
	border-right: 1px solid $color-border;
}
.flow-processor.body .object-device-manager .header {
	margin-bottom: 0;
}
.flow-processor.body .object-device-manager > *:last-child {
	flex-grow: 1;
	overflow-y: auto;
}

.flow-processor.body .flow-processor-details {
	display: flex;
	flex-direction: column;
	height: 100%;
	max-width: 380px;
	width: 100%;
	border-right: 1px solid #e5e5ea;
}
.flow-processor.body .flow-processor-details > .content {
	padding: 0 15px 15px;
	overflow-y: auto;
}
