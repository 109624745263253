@import "../../../defaults.scss";

.property-info > div:first-child {
	display: flex;
	flex-direction: row;
}
.property-info > div ~ div {
	margin-top: 10px;
}

.property-info .name {
	flex-grow: 1;
	text-transform: capitalize;
	overflow-wrap: break-word;
	overflow: hidden;
	text-overflow: ellipsis;
	color: $text-color-main;
}
.property-info .description {
	overflow-wrap: break-word;
	overflow: hidden;
}

.property-info .data-type {
	color: $text-color-light;
}
.property-info .data-type div {
	width: 100%;
	font-size: smaller;
	white-space: nowrap;
	text-align: right;
}
.property-info .data-type div:first-child {
	text-transform: capitalize;
}