@import "../../../defaults.scss";
@import "./button.scss";

.rs-picker-daterange-menu .rs-calendar-table-cell-selected .rs-calendar-table-cell-content {
	background-color: $color-primary;
	border-color: $color-primary;
}
.rs-picker-daterange-menu .rs-calendar-table-cell.rs-calendar-table-cell-is-today > * {
	border-color: $color-primary;
}

.rs-picker-daterange-menu .rs-calendar-table-cell-content:hover {
	background-color: $color-primary-pale;
}
.rs-picker-daterange-menu .rs-calendar-table-cell-in-range:before {
	background-color: $color-primary-pale;
}

.rs-picker-daterange-menu .rs-picker-toolbar .rs-picker-toolbar-option {
	color: $color-primary;
	font-weight: bold;
	text-decoration: none;
}
.rs-picker-daterange-menu .rs-picker-toolbar-right > * {
	color: $color-primary;
	background-color: white;
	border: 1px solid $color-primary;
	border-radius: 20px;
	font-weight: bold;
}
.rs-picker-daterange-menu .rs-picker-toolbar-right > *:hover {
	background-color: $color-primary-pale !important;
	color: $color-primary;
}
.rs-picker-daterange-menu .rs-picker-toolbar-right > *:active {
	background-color: $color-primary !important;
	color: white;
}