@import "../../../defaults.scss";

.checkbox .rs-checkbox-checked .rs-checkbox-inner:before,
.checkbox .rs-checkbox-indeterminate .rs-checkbox-inner:before {
	border-color: $color-primary;
	background-color: $color-primary;
}
.checkbox .rs-checkbox-checker {
	padding-top: 0;
	padding-bottom: 0;
	display: flex;
	align-items: center;
}
.checkbox .rs-checkbox-checker label {
	margin-bottom: 0 !important;
}

.checkbox.inline .rs-checkbox-checker {
	min-height: 16px !important;
	height: 16px !important;
	width: 16px !important;
	padding: 0 !important;
}
.checkbox.inline .rs-checkbox-checker .rs-checkbox-wrapper {
	top: 0 !important;
	left: 0 !important;
}
.checkbox span.description {
	margin-left: 10px;
	font-size: 11px;
}
