@import '../../../../defaults.scss';

.device-brief-view {
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
}
.device-brief-view .main {
	display: flex;
	align-items: center;
}

.device-brief-view .main > * ~ * {
	margin-left: 1rem;
	line-height: 1.5;
}

.device-brief-view .main .title {
	font-size: larger;
	font-weight: bold;
}

.device-brief-view .main .rs-avatar svg {
	width: 32px;
	height: 32px;
}
.device-brief-view .device-latest-message-fields-view > .header {
	display: flex;
	justify-content: space-between;
	padding: 5px;
	border-bottom: 1px solid $color-border;
}
.device-brief-view .device-latest-message-fields-view > .header label {
	margin-right: 5px;
}
.device-brief-view .device-latest-message-fields-view .message-fields-view {
	padding: 5px;
	height: 100px;
	min-width: 250px;
	max-width: 400px;
	overflow: auto;
}
.device-brief-view .device-latest-message-fields-view .message-fields-view .group {
	padding: 0;
}
.device-brief-view .device-latest-message-fields-view .message-fields-view .no-data-view {
	padding: 15px;
}
.device-brief-view .device-latest-message-fields-view .message-fields-view .title {
	display: initial !important;
	text-transform: none !important;
}