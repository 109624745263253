$header-height: 70px;
$body-height: calc(100vh - #{$header-height});
$screen-tablet: 768px;

// colors
$color-primary: #79CDD0;
$color-primary-pale: #E2F4F5;
$color-widget-primary: #33558B;

$color-border: #e5e5ea;
$color-disabled: #a0a0a0;

$color-primary-dark: #545A60;
$color-primary-dark-pale: #BEBEBE;
$color-primary-dark-bg: #E6E6E7;

$background-color-app: #f9fdfd;

// reports
$reports-bg-color: #F9FDFD;

// events
$device-event-color: #74D659;
$device-incedent-event-color: #C92828;

// device status colors
$device-status-color-online: hsl(213, 85%, 55%); // #2A83EE;
$device-status-color-active: hsl(107, 50%, 59%); // #74D659;
$device-status-color-idle: hsl(37, 91%, 64%); // #F7B751;
$device-status-color-offline: hsl(0, 67%, 47%);// #C92828;
$device-status-color-stale: hsl(0, 0%, 75%); // #BEBEBE;
// ...filters (base - black)
$device-status-filter-online: invert(53%) sepia(88%) saturate(4047%) hue-rotate(196deg) brightness(96%) contrast(93%);
$device-status-filter-active: invert(73%) sepia(36%) saturate(669%) hue-rotate(60deg) brightness(96%) contrast(90%);
$device-status-filter-idle: invert(87%) sepia(6%) saturate(7466%) hue-rotate(323deg) brightness(109%) contrast(94%);
$device-status-filter-offline: invert(32%) sepia(70%) saturate(1642%) hue-rotate(335deg) brightness(78%) contrast(104%);
$device-status-filter-stale: invert(92%) sepia(0%) saturate(557%) hue-rotate(156deg) brightness(82%) contrast(94%);

// device state color filters (base - black)
$device-state-filter-active: invert(81%) sepia(54%) saturate(5401%) hue-rotate(49deg) brightness(92%) contrast(87%); // green
$device-state-filter-inactive: invert(56%) sepia(89%) saturate(7091%) hue-rotate(347deg) brightness(84%) contrast(98%); // red
$device-state-filter-unknown: invert(49%) sepia(2%) saturate(2440%) hue-rotate(177deg) brightness(96%) contrast(100%); // gray

// device state colors
$device-state-color-active: #74D659;
$device-state-color-inactive: #C92828;
$device-state-color-unknown: #BEBEBE;

$device-label-shadow-color: hsla(0, 0%, 0%, 0.15);
$device-label-shadow-selected-color: hsla(0, 0%, 0%, 0.25);

$device-status-text-color-online: hsl(213, 85%, 40%);
$device-status-text-color-active: hsl(107, 50%, 44%);
$device-status-text-color-idle: hsl(37, 91%, 49%);
$device-status-text-color-offline: hsl(0, 67%, 32%);
$device-status-text-color-stale: hsl(0, 0%, 60%);

// text colors
$text-color-main: #707070;
$text-color-light: #A3A3A3;
$text-color-main-2: #798699;
$text-color-error: #d60000;
$text-color-accent: black;
$text-color-pale: #d6d6d6;
// text colors filter
$text-filter-main: invert(47%) sepia(7%) saturate(21%) hue-rotate(357deg) brightness(90%) contrast(92%);
$test-filter-light: invert(78%) sepia(1%) saturate(0%) hue-rotate(66deg) brightness(85%) contrast(87%);
$text-filter-main-2: invert(59%) sepia(8%) saturate(817%) hue-rotate(176deg) brightness(87%) contrast(83%);

// menu icons colors
$icon-color-main: #99ABB4;
$icon-filter-white: invert(100%) sepia(100%) saturate(0%) hue-rotate(305deg) brightness(103%) contrast(103%);
$icon-filter-main: invert(73%) sepia(28%) saturate(153%) hue-rotate(156deg) brightness(87%) contrast(88%);
$icon-filter-highlight: invert(85%) sepia(13%) saturate(1003%) hue-rotate(131deg) brightness(91%) contrast(87%);
$icon-filter-disabled: invert(47%) sepia(7%) saturate(21%) opacity(0.3) hue-rotate(357deg) brightness(90%) contrast(92%);
$icon-filter-pale: invert(47%) sepia(7%) saturate(21%) opacity(0.3) hue-rotate(357deg) brightness(90%) contrast(92%);
$icon-filter-red: invert(17%) sepia(94%) saturate(6911%) hue-rotate(358deg) brightness(106%) contrast(110%);

// button colors
$button-color-primary: white;
// $button-text-color-primary: $color-primary;

$button-bg-color-primary: white;
$button-bg-color-primary-hover: $color-primary-pale;
$button-bg-color-primary-active: $color-primary;
$button-text-color-primary: $color-primary;
$button-text-color-primary-hover: $button-bg-color-primary-hover;
$button-text-color-primary-active: white;

// list
$list-item-bg-odd: #f7f7f7;
$list-item-bg-even: white;
$list-item-bg-selected: #d0d0d0;

// drop actions

$droppable-box-shadow: 0px 4px 7px -2px rgba(0, 0, 0, 0.4);
$droppable-text-color: white;
$droppable-background-color: #79CDD0;
$can-drop-background-color-odd: #E2F4F5;
$can-drop-background-color-even: #f0fafa;



$list-item-default-padding: 15px;

// sidebar
$sidebar-width: 280px;

// default link
$link-color: #106ba3;

// -------
$eventTypes: gps-first-fix tampering power-lost power-back fall engine-off engine-on peds-dz-warning switch-state cornering battery-low route-start idling stream-stopped power throttle humidity-range-entered ignition-off ignition-on temperature-1 door-opened geofence-entered mil disassembling geofence-left gps-dz-entered displaced rfid peds-fc-warning sos stop-mode right-ld-warning trip-started fc-warning done power-off vibration sim-changed data-reported trip-ended towing media-received overload battery-depleted geofence-10 headway-warning motion failed overrevving fuel-used braked error-reported closest-gateway temperature-range-left geofence-7 braking geofence-5 odometer-alert airplane-mode left-ld-warning temperature-2 acceleration ld-warning-off geofence-8 motion-state geofence geofence-2 stopped temperature-range-entered route-end speeding dtc geofence-3 geofence-1 geofence-4 power-on timeout overheat humidity-range-left power-cut button-pressed position jam speed-exceeded stream-started gps-dz-left object-proximity impact geofence-9 geofence-6 battery-extra-low;

@mixin eventIcon($name) {
	background-image: url("./img/events/icons/#{$name}.png");
}

// --------
$assetTypes: ambulance bed bicycle boy bulldozer bus car cement-mixer crane excavator female forklift galley-cart girl infant lorry male mini-bus motorcycle pmd police-car prime-mover scooter seatbelt ship storage-bin tanker tractor trailer tram van helmet;

@mixin assetTypeIcon($name) {
	mask-image: url("./img/assetTypes/MD/#{$name}.png");
	-webkit-mask-image: url("./img/assetTypes/MD/#{$name}.png");
}

// trip trace
$trace-stroke-color-1: #050607;
$trace-stroke-color-2: #79ccd0;
$trace-point-fill-color: white;

$trace-stroke-width-1: 5;
$trace-stroke-width-2: 3;
$trace-point-radius: 6;
$trace-arrow-radius: 4;
$trace-point-stroke-width: 2;


// screen resolutions
$screen-width-lg: 1100px;
$screen-width-md: 900px;
$screen-width-sm: 720px;
$screen-width-xs: 560px;

:export {
	traceStrokeColor1: $trace-stroke-color-1;
	traceStrokeColor2: $trace-stroke-color-2;
	tracePointFillColor: $trace-point-fill-color;
	traceStrokeWidth1: $trace-stroke-width-1;
	traceStrokeWidth2: $trace-stroke-width-2;
	tracePointRadius: $trace-point-radius;
	traceArrowRadius: $trace-arrow-radius;
	tracePointStrokeWidth: $trace-point-stroke-width;
	deviceStatusColorOnline: $device-status-color-online;
	deviceStatusColorActive: $device-status-color-active;
	deviceStatusColorIdle: $device-status-color-idle;
	deviceStatusColorOffline: $device-status-color-offline;
	deviceStatusColorStale: $device-status-color-stale;
	deviceStateColorActive: $device-state-color-active;
	deviceStateColorInactive: $device-state-color-inactive;
	deviceStateColorUnknown: $device-state-color-unknown;
	textColorMain: $text-color-main;
	colorPrimary: $color-primary;
}
