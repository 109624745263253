@import '../../../../defaults.scss';

.schedule-manager {
	height: 100%;
}

.schedule-manager > * {
	display: flex;
	flex-direction: column;
	height: 100%;
	width: 100%;
	border-right: 1px solid $color-border;
}

.schedule-manager > .form
, .schedule-manager > .card {
	max-width: 380px;
}

.schedule-manager > .form form {
	padding: 0 15px;
}

.schedule-manager > .form .object-device-manager .header {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
}
.schedule-manager > .form .object-device-manager .device-set .x-list.list {
	border: 1px solid $color-border;
	max-height: 180px;
}

.schedule-manager > .card .content label {
	display: block;
}
.schedule-manager > .card > .content {
	padding: 0 15px;
	display: flex;
    flex-direction: column;
    overflow-y: auto;
}

.schedule-manager > .card > .content > .device-set {
	display: flex;
    flex-direction: column;
	overflow-y: auto;
}

.schedule-manager > .card > .content > .device-set > .x-list.list {
    border: 1px solid $color-border;
}

.schedule-manager > .card > .content label::first-letter {
	text-transform: capitalize;
}
.schedule-manager > .card > .content > *:not(:first-child) {
	margin-top: 15px;
}
.schedule-manager > .card > .content > * > label {
	margin-bottom: 8px;
}
.schedule-manager > .card > .footer {
	padding: 15px;
	display: flex;
	justify-content: center;
	gap: 1em;
}

.schedule-manager > .list .list-item {
	display: flex;
	padding: 10px;
	--justify-content: center;
	align-items: center;
}

.schedule-manager > .list .list-item.list-header {
	border-bottom: 1px solid $color-border;
}

.schedule-manager > .list .list-item.list-header > *::first-letter {
	text-transform: capitalize;
}
.schedule-manager > .list .list-item > *:not(:last-child) {
	margin-right: 10px;
}
.schedule-manager > .list .list-item > *:nth-child(1) {
	width: 80px;
}
.schedule-manager > .list .list-item > *:nth-child(2) {
	width: 200px;
}
.schedule-manager > .list .list-item > *:nth-child(3) {
	width: 200px;
}
.schedule-manager > .list .list-item > *:nth-child(3)::first-letter {
	text-transform: capitalize;
}
.schedule-manager > .list .list-item > *:nth-last-child(1) {
	width: 200px;
}
.schedule-manager > .list .x-list {
	height: auto;
}
.schedule-manager > .list .x-list .list-item:last-child {
	border-bottom: 1px solid $color-border;
}
.schedule-manager > .list .x-list .list-item.accepts-drop:nth-child(even) {
	background-color: $can-drop-background-color-even;
}
.schedule-manager > .list .x-list .list-item.accepts-drop:nth-child(odd) {
	background-color: $can-drop-background-color-odd;
}
.schedule-manager > .list .x-list .list-item.accepts-drop.droppable {
	background-color: $droppable-background-color;
	color: $droppable-text-color;
	box-shadow: $droppable-box-shadow;
}
