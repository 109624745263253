@import "../../../../defaults.scss";

.property-selector.device > .header {
	font-weight: bold;
	padding: 10px 0;
}
.property-selector.device > .header::first-letter {
	text-transform: capitalize;
}
.property-selector.device > .filter {
	margin-bottom: 15px;
}
.property-selector.device > .filter label {
	display: block;
	margin-bottom: 8px;
}
.property-selector.device > .filter label::first-letter {
	text-transform: capitalize;
}
.property-selector.device > .content {
	display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    grid-template-rows: max-content;
    grid-gap: 1rem;
}

.property-selector.device > .footer {
	padding: 25px 0 15px 0;
	display: grid;
	grid-template-columns: calc(50% - 5px) calc(50% - 5px);
	grid-gap: 10px;
}

.property-selector.device .device-property.selected {
	background-color: $color-primary-pale !important;
}