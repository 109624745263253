@import '../../defaults.scss';

.app-page.zone-guards .form, .app-page.zone-guards .form fieldset {
	height: 100%;
}
.app-page.zone-guards .body {
	height: 100%;
}
.app-page.zone-guards .sidebar .header {
    display: flex;
    align-items: center
}
.app-page.zone-guards .sidebar .header .icon > * {
	margin-right: 5px;
	display: block;
}
.app-page.zone-guards .sidebar-content {
	display: flex;
	flex-direction: column;
	flex-grow: 1;
	overflow: hidden;
}
.app-page.zone-guards .sidebar-content .error {
	margin-top: 10px;
	text-align: center;
}

.app-page.zone-guards .sidebar-content .submit-error {
	position: absolute;
	top: 0;
	width: calc(100% - 30px);
	text-align: center;
	color: $text-color-error;
}

.app-page.zone-guards .sidebar-content .filter {
	border-bottom: 1px solid $color-border;
	padding: 15px;
}
.app-page.zone-guards .sidebar-content .filter .input {
	display: flex;
	flex-direction: column;
}
.app-page.zone-guards .sidebar-content .filter .input label {
	margin-bottom: 10px;
}
.app-page.zone-guards .sidebar-content .action-bar {
	position: absolute;
    top: 0; right: 0;
    padding: 15px;
}
.app-page.zone-guards .sidebar-content > .content {
	padding: 15px;
	overflow-y: auto;
}
.app-page.zone-guards .sidebar-content > .content > .button {
	margin-top: 20px;
}


.app-page.zone-guards .zone-guard.card {
	border-top: 1px solid $color-border;
	padding: 15px;
}
.app-page.zone-guards .zone-guard.card .group *::first-letter {
	text-transform: capitalize;
}
.app-page.zone-guards .zone-guard.card .name div {
	overflow-wrap: break-word;
}
.app-page.zone-guards .zone-guard.card > div ~ div {
	margin-top: 10px;
}
.app-page.zone-guards .layout.sidebar-layout > .content {
	position: relative;
	width: 100%;
	border-left: 1px solid $color-border;
}
.app-page.zone-guards .list .list-item {
	overflow-wrap: break-word;
	padding: $list-item-default-padding;
}
.app-page.zone-guards .zone-guard-remover .message {
	font-weight: bold;
	padding: 15px 0;
	color: $text-color-main;
}

.app-page.zone-guards .submit-button {
	position: relative;
	padding: 20px 15px 15px;
}
