@import "../../../defaults.scss";

.device-side-menu .side-header {
	margin: 0 15px;
}
.device-side-menu .side-header .side-lines {
    border-bottom: 1px solid #43484E;
	border-top: 1px solid #43484E;
	padding: 20px 10px;
}
.device-side-menu .side-header .side-line {
	display: flex;
	align-items: center;
}
.device-side-menu .side-header .side-line:not(:first-child) {
	margin-top: 8px;
}
.device-side-menu .side-header .side-line > img {
	height: 19px;
	margin: 3px;
}
.device-side-menu .side-header .side-line > div {
	margin-left: 12px;
}
.device-side-menu .side-header .side-line > *:not(img) {
	width: 193px;
}

.device-side-menu .side-header .top-line {
	display: flex;
	padding: 20px 10px;
}
.device-side-menu .side-header .top-line .sidebar-toggle {
	flex-grow: 1;
	margin-left: 5px;
}
.device-side-menu .side-header .top-line .sidebar-toggle img:hover {
	filter: $icon-filter-highlight;
}

/* grouping picker */

.device-side-menu .grouping-picker .rs-picker-toggle.rs-btn {
	background-color: #43484e !important;
	border-color: transparent;
	border-radius: 50px;
	height: 30px !important;
	padding-top: 4px;
	padding-bottom: 4px;
	padding: 4px 12px;
}
.device-side-menu .grouping-picker .rs-picker-toggle.rs-btn:hover,
.device-side-menu .grouping-picker .rs-picker-toggle.active.rs-btn {
	border-color: transparent !important;
}
.device-side-menu .grouping-picker .rs-picker-toggle-placeholder,
.device-side-menu .grouping-picker .rs-picker-toggle-value {
	color: $text-color-main-2 !important;
}
.device-side-menu .grouping-picker .rs-picker-toggle-clean,
.device-side-menu .grouping-picker .rs-picker-toggle-caret {
	color: $text-color-main-2 !important;
	top: 5px !important;
}
.device-side-menu .grouping-picker.droppable .rs-picker-toggle.rs-btn {
	background-color: $color-primary !important;
	color: white !important;
}
.device-side-menu .grouping-picker.droppable .rs-picker-toggle-caret,
.device-side-menu .grouping-picker.droppable .rs-picker-toggle-value {
	color: white !important;
}

/* device filter */

.device-side-menu .device-search-control .input {
	background-color: #43484e;
	border-radius: 20px;
	padding: 4px 12px;
	height: 30px;
}
.device-side-menu .device-search-control .input input {
	width: 100%; height: 100%;
	border: none;
	background-color: transparent;
	caret-color: #adbfdd;
	color: $text-color-main-2;
	padding: 0 !important;
}
.device-side-menu .device-search-control .input.cleanable input {
	padding-right: 15px !important;
}
.device-side-menu .device-search-control .input input::placeholder,
.device-side-menu .device-search-control .input .action-clear {
	color: $text-color-main-2;
}
.device-side-menu .device-search-control .input .action-clear {
	padding: 0 2px;
}

/* */

.device-side-menu .side-body {
	position: relative;
	background-color: transparent;
	flex-grow: 1;
	overflow-y: auto;
}
.device-side-menu .side-body > * {
	height: 100%;
}

.device-side-menu .category-list {
	height: 100%;
}
.device-side-menu .toggle.multiple-selection {
	margin: 0 15px;
	padding: 15px 10px;
	border-bottom: 1px solid #43484E;
}
