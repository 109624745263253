@import '../../../defaults.scss';

.ol-zoom {
	top: 8px;
	left: 8px;
}

.ol-control button {
	width: 19px;
	height: 19px;
	font-size: 1rem !important;
}

.ol-scale-line {
	right: 8px;
	left: auto;
	bottom: 13px;
}
.ol-overlaycontainer {
	z-index: auto !important;
}
.ol-overlaycontainer-stopevent .ol-control,
.ol-overlaycontainer-stopevent .ol-control > * {
	font-size: 0.9rem;
}
.map {
	position: relative;
}
.map .map-loader {
	position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10;
	top: 0;
	background-color: #0f131a;
	opacity: .6;
}
.map .map-loader .bp3-spinner .bp3-spinner-head {
	stroke: $color-primary;
}
.map .map-controls {
	z-index: 1;
}