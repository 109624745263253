@import '../../../defaults.scss';

.employee-details-card {
	min-width: 300px;
	height: max-content;
}
.employee-details-card > div {
	margin: 0 15px;
}
.employee-details-card .employee-card .block {
	display: flex;
	flex-direction: column;
	padding: 10px 0;
}
.employee-details-card .employee-card div:nth-child(2) {
	padding-top: 0;
}
.employee-details-card .employee-card div:last-child {
	padding-bottom: 0;
}
.employee-details-card .employee-card .block label {
	margin-bottom: 5px;
}
.employee-details-card .employee-card .block .categories {
	display: flex;
}
.employee-details-card .employee-card .block .categories .category {
	margin-right: 10px;
	border-radius: 5px;
	padding: 10px;
	background-color: #f7f7f7;
}
