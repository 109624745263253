@import '../../../../defaults.scss';

.vector-element .header {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
}
.vector-element .header .label {
	font-weight: bold;
	width: 100%;
}

.vector-element .property-element {
	padding: 10px;
    border: 1px solid $color-border;
    border-radius: 2px;
	margin-top: 10px;
}