@import "./xList.scss";

.list.x-list.pending {
	position: relative;
	min-height: 80px;
	overflow: hidden;
}
.list.x-list > .list-loader {
	position: absolute;
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: 10;
	top: 0;
	background-color: white;
	opacity: .6;
}