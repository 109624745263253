@import '../../../defaults.scss';

.fuel-level-processor-field .content .table {
	border-top: 1px solid $color-border;
	border-left: 1px solid $color-border;
}
.fuel-level-processor-field .content .table-item {
	display: grid;
	grid-template-columns: 2fr 2fr;
}
.fuel-level-processor-field .content .table-item > div {
	min-width: 50%;
}
.fuel-level-processor-field label {
	display: inline;
}
.fuel-level-processor-field .header label {
	margin-bottom: 0;
}
.fuel-level-processor-field .item-index {
	display: flex;
	justify-content: space-between;
}
.fuel-level-processor-field .content .table .table-item.droppable {
	background-color: $color-primary !important;
	color: white !important;
	box-shadow: 0px 2px 7px -2px rgba(0, 0, 0, 0.4);
}
.fuel-level-processor-field .content .table-item > div {
	padding: 5px;
	border-right: 1px solid $color-border;
	border-bottom: 1px solid $color-border;
}
.fuel-level-processor-field .content .table .table-item.selected {
	background-color: $list-item-bg-selected;
}
.fuel-level-processor-field .content .table-item:nth-child(2n+1) {
	background-color: $reports-bg-color;
}
.fuel-level-processor-field .content .table-item:nth-child(1) {
	background-color: $color-primary-dark-bg;
}
.fuel-level-processor-field .content > .error {
	text-align: center;
}
.fuel-level-processor-field .rs-input-group .addon {
	border-bottom-right-radius: 2px;
	border-top-right-radius: 2px;
}
.fuel-level-processor-field .rs-input-group .addon:empty {
	display: none;
}