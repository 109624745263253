@import "../../../defaults.scss";

.event-icon {
	height: 32px;
	width: 32px; min-width: 32px;
	background-repeat: no-repeat;
	background-size: auto;
	background-position: center center;
	filter: $device-status-filter-active;
	margin-right: 5px;
	display: inline-block;
	@include eventIcon('default');
}
.event-icon.incident {
	filter: $device-status-filter-offline;
}
@each $eventType in $eventTypes {
	.event-icon.#{$eventType} {
		@include eventIcon($eventType);
	}
}