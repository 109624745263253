@import '../../../defaults.scss';

.zone-category-selector .header {
	font-weight: bold;
	padding: 15px;
	display: flex;
	border-bottom: 1px solid $color-border;
}
.zone-category-selector .header span::first-letter {
	text-transform: capitalize;
}
.zone-category-selector .header > *:first-child {
	flex-grow: 1;
}
.zone-category-selector .header .clickable:hover {
	color: $color-primary;
}
.zone-category-selector .list-item {
	display: flex;
	align-items: center;
}
.zone-category-selector .list-item > *:first-child {
	flex-grow: 1;
	overflow: hidden;
	overflow-wrap: break-word;
}
.zone-category-selector .list-item.droppable {
	background-color: $color-primary !important;
	color: white;
}
.zone-category-selector .list-item.selected {
	background-color: white;
}
.zone-category-selector .list-item.selected:nth-child(odd) {
	background-color: #f7f7f7;
}