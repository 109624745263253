@import "../../../../defaults.scss";
@import "./index.scss";

$column_width:
	minmax(100px, 5%) // No
	minmax(120px, 10%) // time
	minmax(200px, 1fr) // location
	minmax(120px, 10%) // boarded
	minmax(120px, 6%) // alighted
	minmax(120px, 6%) // aboard
;

.report.ridership .scroll-list {
	display: grid;
	grid-template-columns: $column_width;
}
.ridership.report .scroll-list div.start {
	border-top: 3px solid $color-border;
}
.report.ridership .scroll-list .loader,
.report.ridership .scroll-list .indicator {
	grid-column: 1 / 7;
}
.report.ridership .scroll-list > *:nth-child(12n+7),
.report.ridership .scroll-list > *:nth-child(12n+8),
.report.ridership .scroll-list > *:nth-child(12n+9),
.report.ridership .scroll-list > *:nth-child(12n+10),
.report.ridership .scroll-list > *:nth-child(12n+11),
.report.ridership .scroll-list > *:nth-child(12n+12) {
	background-color: $reports-bg-color;
}
