@import "../../../../defaults.scss";
@import "./index.scss";

$group-width:
	minmax(70px, 1fr) // min
	minmax(70px, 1fr) // max
	minmax(70px, 1fr) // avg
	minmax(70px, 1fr) // q-ty
;

.report.temperature-change .scroll-list {
	display: grid;
	grid-template-columns:
		minmax(90px, 5%) // No
		minmax(120px, 10%) // start time
		minmax(120px, 10%) // end time
		minmax(120px, 10%) // duration
		minmax(350px, 1fr) // temp 1
		minmax(350px, 1fr) // temp 2
	;
}

.report.temperature-change .scroll-list .header-item.rows,
.report.temperature-change .scroll-list .temperature-group {
	display: grid;
}
.report.temperature-change .scroll-list .header-item.rows > div:last-child {
	display: grid;
	grid-template-columns: $group-width;
}
.report.temperature-change .scroll-list .temperature-group {
	grid-template-columns: $group-width;
}

.report.temperature-change .scroll-list .loader,
.report.temperature-change .scroll-list .indicator {
	grid-column: 1 / 7;
}

.report.temperature-change .scroll-list > *:nth-child(12n+7),
.report.temperature-change .scroll-list > *:nth-child(12n+8),
.report.temperature-change .scroll-list > *:nth-child(12n+9),
.report.temperature-change .scroll-list > *:nth-child(12n+10),
.report.temperature-change .scroll-list > *:nth-child(12n+11),
.report.temperature-change .scroll-list > *:nth-child(12n+12) {
	background-color: $reports-bg-color;
}