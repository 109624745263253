@import '../../../../defaults.scss';
@import './index.scss';

$column_width:
	minmax(90px, 5%) // No
	minmax(120px, 10%) // start time
	minmax(120px, 10%) // end time
	minmax(70px, 6%) // duration
	minmax(200px, 1fr) // location
	minmax(90px, 6%) // range
	minmax(70px, 6%) // quantity
;
.report.location-history .scroll-list {
	display: grid;
	grid-template-columns: $column_width;
}
.report.location-history.events .scroll-list {
	display: grid;
	grid-template-columns: $column_width  minmax(150px, 10%);
}
.report.location-history.base .scroll-list .loader,
.report.location-history.base .scroll-list .indicator {
	grid-column: 1 / 8;
}
.report.location-history.events .scroll-list .loader,
.report.location-history.events .scroll-list .indicator {
	grid-column: 1 / 9;
}

.report.location-history.base .scroll-list > *:nth-child(14n+8),
.report.location-history.base .scroll-list > *:nth-child(14n+9),
.report.location-history.base .scroll-list > *:nth-child(14n+10),
.report.location-history.base .scroll-list > *:nth-child(14n+11),
.report.location-history.base .scroll-list > *:nth-child(14n+12),
.report.location-history.base .scroll-list > *:nth-child(14n+13),
.report.location-history.base .scroll-list > *:nth-child(14n+14) {
	background-color: $reports-bg-color;
}
.report.location-history.events .scroll-list > *:nth-child(16n+9),
.report.location-history.events .scroll-list > *:nth-child(16n+10),
.report.location-history.events .scroll-list > *:nth-child(16n+11),
.report.location-history.events .scroll-list > *:nth-child(16n+12),
.report.location-history.events .scroll-list > *:nth-child(16n+13),
.report.location-history.events .scroll-list > *:nth-child(16n+14),
.report.location-history.events .scroll-list > *:nth-child(16n+15),
.report.location-history.events .scroll-list > *:nth-child(16n+16) {
	background-color: $reports-bg-color;
}