@import '../../../defaults.scss';

.employee-view.body {
	display: flex;
	flex-direction: row;
	width: 100%;
	min-width: 280px;
}

.employee-view.body .list-item.droppable {
	background-color: $color-primary !important;
	color: white !important;
	box-shadow: 0px 2px 7px -2px rgba(0, 0, 0, 0.4);
	z-index: 1;
}
.employee-view.body .employee-list-wrap {
	border-right: 1px solid $color-border;
}
.employee-view.body .employee-details-card .selection-card {
	padding: 15px;
	border: 1px solid $color-border;
	border-radius: 3px;
	margin: 15px;
}
.employee-view.body .employee-details-card .selection-card.droppable {
	background-color: $droppable-background-color !important;
	color: $droppable-text-color;
	border: none;
	box-shadow: $droppable-box-shadow;
	z-index: 1;
}
.employee-view.body .employee-details-card .selection-card.canDrop {
	background-color: $can-drop-background-color-even;
}
