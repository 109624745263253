@import '../../../defaults.scss';

.object-device-manager > .header {
  margin-bottom: 8px;
}
.object-device-manager > .header > span.title {
  font-weight: bold;
}
.object-device-manager > .header > span.title::first-letter {
  text-transform: capitalize;
}
.object-device-manager.modal {
  height: 80%;
}

.object-device-manager {
  .list-item.droppable {
    background-color: $droppable-background-color;
    color: $droppable-text-color;
    box-shadow: $droppable-box-shadow;
  }
}
