.message-publishers-list {
	display: flex;
	flex-direction: column;
	height: 0;
}

.message-publishers-list label {
	padding: 15px;
	margin: 0;
}

.message-publishers-list {
	flex-grow: 1;
	display: flex;
	flex-direction: column;
	overflow-y: auto;;
}

.message-publishers-list .list-item.droppable {
	background-color: #79CDD0 !important;
	color: white !important;
	box-shadow: 0px 2px 7px -2px rgba(0, 0, 0, 0.4);
}
