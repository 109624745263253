.map-options .map-button {
	position: relative;
	left: 0;
	top: 0;
}

.map-options-content > .header .close {
	height: 11px;
}
.map-options-content > .header .close img {
	height: 100%;
}
.map-options-content > .header .title::first-letter {
	text-transform: capitalize;
}
.map-options-content {
	min-width: 200px;
	padding-bottom: 5px;
}

.map-options-content .header {
	display: flex;
    justify-content: space-between;
	padding: 10px;
	font-weight: bold;
}

.map-options-content > * {
	padding: 10px;
	position: relative;
}
