@import "../../../../defaults.scss";

.device-digest-view > .content {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	gap: 20px;
}

.device-digest-view .digest-card {
	padding: 20px;
	background-color: gray;
	border-radius: 10px;
	color: white;
	position: relative;
	transform: scale(1);
	transition: transform 0.3s;
}
.device-digest-view .digest-card:hover {
	transform: scale(1.05);
	transition: transform 0.3s;
}
.device-digest-view .digest-card .loader {
	height: 100%;
}
.device-digest-view .digest-card .wrap {
	color: white;
	text-decoration: none;
}
.device-digest-view .digest-card .wrap > div:first-child {
	font-size: 5rem;
	font-weight: bold;
}
.device-digest-view .digest-card .wrap > div:nth-child(2) {
	font-size: 1rem;
	font-weight: bold;
	text-transform: capitalize;
}
.device-digest-view .digest-card .link-icon {
	position: absolute;
	top: 20px;
	right: 20px;
	color: white;
}
.device-digest-view .digest-card.events {
	background-color: #4db530;
}
.device-digest-view .digest-card.incidents {
	background-color: #bf3939;
}
.device-digest-view .digest-card.trips {
	background-color: darkslategray;
}
.device-digest-view .device-digest-graphs-view {
	grid-column-start: 1;
	grid-column-end: 4;
}
.device-digest-view .device-digest-graphs-view .object-action-dialog-popup {
	min-width: 150px;
}
.device-digest-view .device-digest-graphs-view .object-action-dialog-popup > * {
	padding: 0;
}
.device-digest-view .device-digest-graphs-view .object-action-dialog-popup > * > .header {
	padding: 15px 15px 0;
}
.device-digest-view .device-digest-graphs-view .object-action-dialog-popup > * .list-item {
	padding: 10px 15px;
}
.device-digest-view .device-digest-graphs-view .recent-data-event .list {
	max-height: 300px;
	overflow-x: hidden;
}
.device-digest-view .device-digest-graphs-view .apexcharts-bar-area {
	cursor: pointer;
}