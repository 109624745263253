@import "../../../defaults.scss";

.button.rs-btn {
	font-weight: bold;
	border-radius: 20px;
	height: 32px;
	padding: 0;
	display: flex;
	align-items: center;
	justify-content: center;
}
.button.rs-btn-block + .rs-btn-block {
	margin: 0;
}
.button.rs-btn.rs-btn-primary {
	border: 1px solid $button-text-color-primary;
	background-color: $button-bg-color-primary;
	color: $button-text-color-primary;
}
.button.rs-btn.rs-btn-primary:hover {
	background-color: $button-bg-color-primary-hover;
}
.button.active.rs-btn.rs-btn-primary,
.button.rs-btn.rs-btn-primary:active,
.button.rs-btn.rs-btn-primary.rs-btn-active {
	background-color: $button-bg-color-primary-active;
	color: $button-text-color-primary-active;
}