@import './../../defaults.scss';

.popup.language-changer {
	padding: 10px;
	text-align: center;
}
.popup.language-changer .wrap::first-letter {
	text-transform: capitalize;
}
.popup.language-changer .wrap:hover {
	color: $color-primary;
}