@import '../../../../defaults.scss';
@import './index.scss';

.report.device-situations .scroll-list .header-section {
	position: -webkit-sticky;
	position: sticky;
	top: 0;
	background-color: white;
	z-index: 1;
	padding: 0;
}
.report.device-situations .scroll-list .header-section > * {
	padding: 15px;
}
.report.device-situations .scroll-list .section-header {
	background-color: #f0f0f0;
	font-weight: bold;
	position: -webkit-sticky;
	position: sticky;
	top: calc(1rem + 30px);
	z-index: 1;
}
.report.device-situations .scroll-list .section {
	display: grid;
	grid-template-columns:
		minmax(120px, 10%) // time
		minmax(280px, 10%) // event
		minmax(70px, 1fr) // location
	;
	padding: 0;
}
.report.device-situations .scroll-list .section > * {
	display: flex;
    align-items: center;
	height: 100%;
	padding: 15px;
}

.report.device-situations .scroll-list .loader,
.report.device-situations .scroll-list .indicator {
	grid-column: 1 / 4;
}

.report.device-situations .scroll-list .section > *:nth-child(6n+4),
.report.device-situations .scroll-list .section > *:nth-child(6n+5),
.report.device-situations .scroll-list .section > *:nth-child(6n+6) {
	background-color: $reports-bg-color;
}