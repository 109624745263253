@import '../../../defaults.scss';

.categories-control .header {
	display: flex;
	margin-bottom: 8px;
}

.categories-control .header span {
	font-weight: bold;
	flex-grow: 1;
}
.categories-control .list {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	grid-gap: 5px;
	grid-auto-rows: auto;
}
.categories-control .list > .list-item {
	display: inline-block;
	background-color: #f7f7f7;
	overflow-wrap: break-word;
	text-overflow: ellipsis;
	position: relative;
	border-radius: 5px;
	padding: 10px;
}
.categories-control .list > .list-item > .action.close {
	position: absolute;
	right: 3px; top: 3px;
}
.categories-control .list > .list-item > .action.close,
.categories-control .list > .list-item > .action.close img {
	width: 8px !important;
	height: 8px !important;
	vertical-align: top;
}
.categories-control .list > .list-item > .action.close:hover img {
	filter: $icon-filter-red;
}
