@import '../../../defaults.scss';

.time-machine-settings.widget {
	height: min-content;
	display: flex;
	flex-direction: column;
}
.time-machine-settings.widget .rs-checkbox {
	margin: 0;
}
.time-machine-settings.widget .map-option,
.time-machine-settings.widget .group-checkbox {
	padding: 2px 0;
}
.time-machine-settings.widget .group-checkbox {
	display: flex;
	justify-content: space-between;
	align-items: center;
}
.time-machine-settings.widget .item.header {
	grid-template-columns: auto 77px 64px ;
	position: sticky;
	top: 0;
	background-color: white;
	z-index: 1;
}
.time-machine-settings.widget .item {
	display: grid;
	grid-template-columns: 1fr 4fr 38px 25px 38px;
	padding: 5px 0;
}
.time-machine-settings.widget .item:not(:last-child) {
	border-bottom: 1px solid $color-border;
}
.time-machine-settings.widget .item img {
	filter: $icon-filter-main;
}
.time-machine-settings.widget .item > div {
	display: flex;
	align-items: center;
	justify-content: flex-end;
}
.time-machine-settings.widget .item:not(.header) > div.device-name {
	padding-left: 10px;
	padding-right: 10px;
}
.time-machine-settings.widget .item > div.device-name {
	justify-content: flex-start;
}
.time-machine-settings.widget .item .color-picker-control .popover {
	top: 0;
}
.time-machine-settings.widget .inline-block {
	width: 100%;
	display: inline-flex;
}
.time-machine-settings.widget .inline-block .label,
.time-machine-settings.widget .inline-block > div {
	padding-right: 5px;
}
.time-machine-settings.widget .inline-block .label > * {
	white-space: nowrap;
	overflow: hidden;
}