@import "../../../defaults.scss";

.menu {
	display: flex;
	flex-direction: column;
}
.menu.horizontal {
	flex-direction: row;
}

.menu > .item {
	cursor: pointer;
}
.menu > .item.disabled {
	color: $text-color-pale;
}
.menu > .item.disabled img {
	filter: $icon-filter-disabled;
}

.menu.horizontal > .item {
	padding: 0 10px;
}

.menu.right {
	justify-content: flex-end;
}
.menu.center {
	justify-content: center;
}