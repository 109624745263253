@import '../../../../defaults.scss';

.devices-visibility-button-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.devices-visibility-button-wrapper .visibility-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &:hover {
    color: $color-primary;
  }
}
