@import '../../../defaults.scss';

.employee-list-wrap {
	display: flex;
	flex-direction: column;
	width: 630px;
}
.employee-list-wrap .section-header {
	display: flex;
}
.employee-list-wrap .section-header .procedure-buttons {
	display: flex;
	margin-left: auto;
}
.employee-list-wrap .section-header .procedure-buttons > * {
	margin-left: 10px;
	filter: $text-filter-main;
}
.employee-list-wrap .section-header .import-button label {
	cursor: pointer;
}
.employee-list-wrap .section-header .import-button:hover,
.employee-list-wrap .section-header .export-button:hover {
	filter: $icon-filter-highlight;
}
.employee-list-wrap .section-header .export-button.disabled {
	color: $color-primary-dark-pale;
}
.employee-list-wrap > span.caption {
	font-weight: bold;
	margin-left: 15px;
	margin-bottom: 8px;
}
.employee-list-wrap .empty {
	margin-top: 20px;
	text-align: center;
}
.employee-list-wrap .filter {
	display: flex;
	padding: 0 15px;
}
.employee-list-wrap .filter .input {
	width: 100%;
}
.employee-list-wrap .filter .checkbox {
	margin-left: 10px;
	white-space: nowrap;
}
.employee-list-wrap .filter .checkbox .rs-checkbox-wrapper {
	left: 5px;
}
.employee-list-wrap .employee-header-list-item {
	position: sticky;
	top: 0;
	font-weight: bold;
	border-bottom: 1px solid $color-border;
	background-color: white;
	z-index: 1;
	margin: 0;
}
.employee-list-wrap .employee-list.x-list {
	flex-grow: 1;
	display: flex;
	flex-direction: column;
	overflow-y: auto;
}
.employee-list-wrap .employee-header-list-item:not(.checkable) > *,
.employee-list-wrap .employee-list.x-list .list-item:not(.checkable) > *,
.employee-list-wrap .employee-header-list-item.checkable > *:not(:first-child),
.employee-list-wrap .employee-list.x-list .list-item.checkable > *:not(:first-child) {
	width: 200px;
	text-overflow: ellipsis;
    overflow: hidden;
}
.employee-list-wrap .employee-header-list-item.withStatus > *:last-child,
.employee-list-wrap .employee-list.x-list .list-item.withStatus > *:last-child {
	width: 60px;
}
.employee-list-wrap .employee-list.x-list .list-item.withStatus .active {
	color: $device-state-color-active;
}
.employee-list-wrap .employee-list.x-list .list-item.withStatus .inactive {
	color: $device-state-color-inactive;
}
.employee-list-wrap .employee-header-list-item,
.employee-list-wrap .employee-list.x-list > * {
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 10px;
}
.employee-list-wrap .employee-list .list-item.droppable {
	background-color: $droppable-background-color;
	color: $droppable-text-color;
	box-shadow: $droppable-box-shadow;
	z-index: 1;
}
.employee-list-wrap .employee-list .list-item.canDrop:nth-child(even) {
	background-color: $can-drop-background-color-even;
}
.employee-list-wrap .employee-list .list-item.canDrop:nth-child(odd) {
	background-color: $can-drop-background-color-odd;
}
.employee-list-wrap .employee-list .list-item.wrong:nth-child(even) {
	background-color: #e2808075;
}
.employee-list-wrap .employee-list .list-item.wrong:nth-child(odd) {
	background-color: #ff393965;
}
