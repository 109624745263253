@import '../../../defaults.scss';

.employee-category-list {
	height: 100%;
	display: flex;
	flex-direction: column;
}
.employee-category-list > .label {
	display: flex;
	justify-content: space-between;
}
.employee-category-list .label,
.employee-category-list .filter {
	padding: 15px;
}
.employee-category-list .filter .input {
	display: flex;
	flex-direction: column;
}
.employee-category-list .filter .input label {
	margin-bottom: 10px;
}
.employee-category-list .list .list-item {
	display: flex;
	justify-content: space-between;
	align-items: center;
	overflow-wrap: break-word;
	padding: $list-item-default-padding;
}
.employee-category-list .list .list-item.droppable {
	background-color: $droppable-background-color !important;
	color: $droppable-text-color;
	box-shadow: $droppable-box-shadow;
	z-index: 1;
}
.employee-category-list .list .list-item.canDrop:nth-child(even) {
	background-color: $can-drop-background-color-even;
}
.employee-category-list .list .list-item.canDrop:nth-child(odd) {
	background-color: $can-drop-background-color-odd;
}
.employee-category-list .no-items {
	padding: 15px;
	text-align: center;
}
