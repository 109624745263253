@import "../../../defaults.scss";

.input .content {
	position: relative;
	width: 100%;
	height: 100%;
}

.input input {
	padding: 7px 11px !important;
	border-radius: 2px !important;
	border-color: $color-border !important;
}
.input.cleanable input {
	padding-right: 30px !important;
}

.input .rs-input:hover,
.input .rs-input:focus,
.input .rs-input-group:hover,
.input .rs-input-group:focus {
	border-color: $color-primary !important;
}

.input .rs-input-group:hover,
.rs-input-group:not(.rs-input-group-inside):not(.rs-input-group-disabled):hover,
.input .rs-input-group.rs-input-group-focus {
	border-color: $color-primary !important;
}

.input .rs-input-group, .rs-input-group:not(.rs-input-group-inside) {
	border-radius: 2px;
}

.input .rs-input[disabled] {
	opacity: .3;
}

.input .rs-input-number-touchspin-down,
.input .rs-input-number-touchspin-up {
	height: 13px;
}

.input .action-clear {
	position: absolute;
	display: flex;
	align-items: center;
	right: 0; top: 0;
	height: 100%;
	padding: 0 12px;
	cursor: pointer;
	font-weight: bold;
	color: $text-color-main;
	text-align: center;
}
.input .action-clear:hover {
	color: #f44336 !important;
}
.input label {
	margin-bottom: 8px;
    display: block;
    font-weight: 700;
}
