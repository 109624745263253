@import '../../../defaults.scss';

.mqtt-client-form {
  padding: 15px;
  overflow-y: auto;

  &--field {
    margin-bottom: 15px;
  }

  &--error {
    margin: 5px 0;
    color: $text-color-error;
  }
}
