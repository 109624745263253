@import '../../../defaults.scss';

.action-locked-devices > span {
	display: flex;
}
.action-locked-devices .wrap > .icon {
	display: flex;
	align-items: center;
	margin-right: 5px;
}
.action-locked-devices .wrap {
	display: flex;
	align-items: center;
	border: 1px solid #d0d0d0;
	border-radius: 2px;
	padding: 2px 5px;
}
.action-locked-devices .wrap .quantity {
	font-size: smaller;
}
.action-locked-devices .locked-devices.content {
	padding: 15px 10px 15px 15px;
	width: auto;
	background-color: white;
}
.action-locked-devices .locked-devices.content .header {
	padding-bottom: 5px;
}
.action-locked-devices .locked-devices.content .x-list.list {
	max-height: 150px;
}
.action-locked-devices .locked-devices.content .x-list.list .list-item {
	display: flex;
    flex-direction: row;
	align-items: center;
	padding: 10px;
	margin-right: 7px;
	background-color: white;
}
.action-locked-devices .locked-devices.content .x-list.list .list-item.disabled {
	cursor: default !important;
	color: $color-disabled;
}
.action-locked-devices .locked-devices.content .x-list.list .list-item.disabled .icon img {
	filter: $icon-filter-disabled;
}
.action-locked-devices .locked-devices.content .x-list.list .list-item:not(:last-child) {
	border-bottom: 1px solid $color-border;
}
.action-locked-devices .locked-devices.content .x-list.list .list-item .icon {
	margin-right: 10px;
}
