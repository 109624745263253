.map-button {
	width: 25px;
	height: 25px;
	color: white;
	border-radius: 2px;
	cursor: pointer;
	padding: 3px;
	text-align: center;
	font-weight: bold;
	background-color: rgba(255,255,255,0.4);
	border-radius: 4px;
}

.map-button > .content {
	background-color: rgba(0,60,136,0.5);
	width: 100%;
	height: 100%;
	padding: 1px;
	border-radius: 2px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.map-button:hover > .contents {
	background-color: rgba(0,60,136,0.7);
}

.map-button.disabled {
	opacity: 0.5;
	cursor: not-allowed;
}

.map-button.disabled:hover > .contents {
	background-color: rgba(0,60,136,0.5);
}

.map-button > .content > svg,
.map-button > .content > img {
	width: 75%;
	height: 75%;
}