@import "../../../../defaults.scss";

.property-element {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	padding: 10px 0;
	text-transform: capitalize;
}

.property-element .name {
	padding-right: 10px;
}

.property-element .name,
.property-element .data-type {
	text-transform: capitalize;
	line-height: 1em;
}

.property-element .data-type {
	font-size: smaller;
}

.property-element.droppable {
	background-color: $color-primary;
	color: white;
	box-shadow: 0px 2px 7px -2px rgba(0, 0, 0, 0.4);
}