.modal .rs-modal-body {
	padding: 0;
}

.modal.full-width {
	width: calc(100vw - 50px);
}
.modal.full-height {
	height: calc(100vh - 50px);
}
.modal.full-height > .rs-modal-dialog,
.modal.full-height > .rs-modal-dialog > .rs-modal-content {
	height: 100%;
}
.modal.full-height > .rs-modal-dialog > .rs-modal-content {
	display: flex;
	flex-direction: column;
}
.modal.full-height > .rs-modal-dialog > .rs-modal-content > .rs-modal-body {
	flex-grow: 1;
	max-height: 100% !important;
}

.modal .rs-modal-title::first-letter {
	text-transform: capitalize;
}
.modal .rs-modal-header {
	position: relative;
}