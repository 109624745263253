@import "../../../defaults.scss";

.device-state-icon {
	height: 16px;
}
.device-state-icon > img {
	height: 100%;
	vertical-align: baseline;
	filter: $device-state-filter-unknown;
}
.device-state-icon.active > img {
	filter: $device-state-filter-active;
}
.device-state-icon.inactive > img {
	filter: $device-state-filter-inactive;
}