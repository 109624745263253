@import '../../../../../defaults.scss';

.device-top-list {
	height: max-content !important;
	max-height: 100%;
}
.device-top-list .header {
	font-weight: bold;
	padding: 5px;
	color: #69C4C7;
	background-color: white;
	position: sticky;
	top: 0;
	text-transform: uppercase;
}

.device-top-list > .device {
	display: grid;
	grid-template-columns: 35px 1fr;
	grid-gap: 5px;
	padding: 10px 5px;
	cursor: inherit;
	margin-right: 10px;
}
.device-top-list > .device:not(:last-child) {
	border-bottom: 1px solid $color-border;
}
.device-top-list .device .info .name {
	font-weight: bold;
}
.device-top-list .device .info .quantity {
	color: $device-status-color-offline;
}
.device-top-list .device .at {
	display: flex;
    align-items: flex-start;
}
.device-top-list .device .at > div {
	display: flex;
    align-items: center;
    justify-content: center;
    background-color: $color-primary;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    color: white;
    font-weight: bold;
}
.device-top-list.empty {
	overflow: hidden;
	height: min-content;
}
