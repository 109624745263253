.device-last-events-statistics.gallery-cards .gallery-cards-wrap {
	border: none;
}
.device-last-events-statistics.gallery-cards .event-statistic-item {
	padding: 5px;
}
.device-last-events-statistics.gallery-cards .event-statistic-item .icon {
	text-align: center;
}
.device-last-events-statistics.gallery-cards .event-statistic-item .icon > .event-icon {
	margin-right: 0;
}
.device-last-events-statistics.gallery-cards .event-statistic-item .name {
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	text-align: center;
}
.device-last-events-statistics.gallery-cards .event-statistic-item .quantity {
	font-weight: bold;
}
.device-last-events-statistics.gallery-cards .gallery-content .card,
.device-last-events-statistics.gallery-cards .gallery-content .card:hover {
	box-shadow: none !important;
	transform: none;
	transition: none;
}