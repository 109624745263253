@import "../../../defaults.scss";

.category-selector {
	display: flex;
    flex-direction: column;
    justify-content: space-between;
	min-height: 300px;
}
.category-selector .block > * ~ * {
	margin-top: 15px;
}
.category-selector label {
	display: block;
	margin-bottom: 8px;
}
.category-selector label::first-letter {
	text-transform: capitalize;
}
.category-selector .block > .content {
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-gap: 10px;
}
.category-selector .block > .empty {
	width: 100%;
	margin: 0;
	text-align: center;
}
.category-selector .item {
	padding: 10px;
	border-radius: 5px;
	border: 1px solid $color-border;
}
.category-selector .item.selected {
	background-color: #f0f0f0;
}
.category-selector .item.droppable {
	background-color: $color-primary;
	color: white;
}
.category-selector .footer {
	margin-top: 25px;
}
