@import "../../../../defaults.scss";

.report {
	width: 100%; height: 100%;
	display: flex;
	flex-direction: column;
}
.report .content {
	height: 100%;
	overflow: hidden;
}
.report .content .center {
	display: flex;
	width: 100%;
	height: 100%;
	align-items: center;
	justify-content: center;
}
.report .content .center .error {
	color: $text-color-error;
}
.report .scroll-list {
	grid-auto-rows: max-content;
	min-height: 250px;
}
.report .scroll-list > * {
	padding: 15px;
	display: flex;
	align-items: center;
	flex-wrap: wrap;
}
// <OBSOLETE> will be removed
.report .list-item > * {
	display: flex;
	align-items: center;
	padding: 15px 10px;
}
.report .list-item > *:first-child {
	padding-left: 15px;
}
.report .list-item > *:last-child {
	padding-right: 15px;
}
.report .list-item:nth-child(odd) > * {
	background-color: $reports-bg-color;
}
// </OBSOLETE>
.report .summary {
	padding: 0 15px 15px;
}
.report .summary span {
	margin-left: 15px;
}
.report .summary .parameters {
	margin-top: 15px;
	display: flex;
}
.report .summary .parameters strong {
	color: black;
}
.report .summary .parameters > div {
	display: flex;
	flex-direction: row;
	margin: 0 40px 0 0;
}
.report .header {
	padding: 15px;
	font-weight: bold;
	font-size: larger;
}
.report .header span {
	display: block;
}
.report .header .menu {
	position: absolute;
	right: 10px; top: 10px;
}
.report .header .menu .item {
	filter: $text-filter-main;
}
// <OBSOLETE> will be removed
.report .heading > * {
	padding: 15px 10px;
	font-weight: bold;
}
.report .heading > *:first-child {
	padding-left: 15px;
}
.report .heading > *:last-child {
	padding-right: 15px;
}
// </OBSOLETE>
.report .header-item {
	position: sticky;
	top: 0;
	font-weight: bold;
	padding-right: 15px;
	color: black;
	border-bottom: 1px solid $color-border;
	background-color: white;
	z-index: 1;
}
.report .header-item::first-letter {
	text-transform: capitalize;
}
.report .scroll-list .loader,
.report .scroll-list .indicator {
	display: flex;
	justify-content: center;
}
.report .scroll-list .loader > * {
	padding: 0;
}
.report .header .menu .item.active {
	filter: $icon-filter-highlight;
}
.report > .content.with-map {
	display: grid;
	grid-template-columns: 2fr 1fr;
	height: 100%;
}
.report > .content.with-chart {
	display: grid;
	grid-template-rows: 320px 1fr;
	height: 100%;
	overflow-y: auto;
}
.report > .content.with-chart .apexcharts-toolbar > div {
	margin-left: 0;
	margin-right: 10px;
}
.report > .content.with-chart > div:first-child {
	position: relative;
	padding-top: 5px;
	border-top: 1px solid $color-border;
	border-bottom: 1px solid $color-border;
}
.report .scroll-list div.selected {
	background-color: $list-item-bg-selected !important;
}