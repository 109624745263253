@import '../../../defaults.scss';

.report-parametrizator {
	width: 100%;
	height: 100%;
	position: relative;
	display: grid;
	grid-template-columns: 420px 1fr;
}
.report-parametrizator > * {
	overflow-y: auto;
	height: 100%;
}
.report-parametrizator .parametrizator > *,
.report-parametrizator .report-previewer > * {
	padding: 15px;
}
.report-parametrizator > * > .title {
	font-size: larger;
	font-weight: bold;
	position: sticky;
	display: flex;
	top: 0;
	z-index: 6;
	background-color: white;
}
.report-parametrizator > * > .title > *:not(:last-child) {
	margin-right: 10px;
}
.report-parametrizator .parametrizator .progress-indicator {
	display: flex;
	text-transform: capitalize;
	padding: 1rem;
    background-color: #f0f0f0;
    border-radius: 5px;
}
.report-parametrizator .parametrizator .progress-indicator > *:first-child {
	margin-right: 5px;
}
.report-parametrizator .parametrizator .form-control-group.footer {
	text-align: center;
}
.report-parametrizator .parametrizator .ow-button-group {
	grid-auto-columns: 0.5fr;
}
.report-parametrizator .parametrizator .button-group .button .exporting {
	display: flex;
}
.report-parametrizator .parametrizator .button-group .button .exporting span.progress {
	margin-left: 5px;
}

.report-parametrizator .report-previewer {
	border-left: 1px solid $color-border;
	width: 100%;
	display: grid;
	grid-template-rows: auto 1fr;
	overflow: hidden;
}
.report-parametrizator .report-previewer .preview-container {
	height: 100%;
}

@media(max-width: 1100px) {
	.report-parametrizator {
		display: grid;
		grid-template-columns: 1fr;
		grid-template-rows: auto;
		overflow: auto;
	}
	.report-parametrizator > * {
		width: 100%;
		max-width: none !important;
		height: min-content !important;
	}
}
