@import '../../../defaults.scss';

.mobile-global-menu-button {
	width: 2rem;
	height: 2rem;
	position: absolute;
	top: 25px;
	right: 20px;
	z-index: 20;
	display: none;
}
@media (max-width: $screen-tablet) {
	.mobile-global-menu-button {
		display: flex;
		justify-content: space-around;
		flex-flow: column nowrap;
	}
	.global-menu {
		position: absolute;
		background-color: white;
		transform: translateX(100%) scale(0, 1);
		top: $header-height;
		right: 0;
		height: $body-height;
		width: $header-height;
		transition: transform 0.3s ease-in-out;
	}
	.global-menu ul {
		flex-flow: column nowrap;
	}
	.global-menu.open {
		transform: translateX(0) scale(1, 1);
	}
}
.mobile-global-menu-button > div {
	width: 2rem;
	height: 0.25rem;
	background-color: $text-color-main;
	border-radius: 10px;
	transform-origin: 1px;
	transition: all 0.3s linear;
	&:nth-child(1) {
		transform: rotate(0);
	}
	&:nth-child(2) {
		transform: translateX(0);
		opacity: 1;
	}
	&:nth-child(3) {
		transform: rotate(0);
	}
}

.mobile-global-menu-button.open > div {
	background-color: $color-primary;
	&:nth-child(1) {
		transform: rotate(45deg);
	}
	&:nth-child(2) {
		transform: translateX(100%);
		opacity: 0;
	}
	&:nth-child(3) {
	transform: rotate(-45deg);
	}
}
