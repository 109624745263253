.object-zone-manager {
    display: grid;
    grid-template-columns: 250px 1fr;
    grid-auto-rows: 100%;
    flex-grow: 1;
}

.object-zone-manager .zones {
    display: flex;
    flex-direction: column;
}
.object-zone-manager .zones .no-items::first-letter {
    text-transform: capitalize;
}
.object-zone-manager .zones > .header {
    align-items: center;
}
.object-zone-manager .zones > .header > span {
    font-weight: bold;
}
.object-zone-manager .zones > *:last-child {
    flex-grow: 1;
    text-emphasis: none;
    overflow-y: auto;
}

@media(max-width: 1280px) {
    .object-zone-manager {
        display: grid;
        grid-template-columns: none;
        grid-template-rows: 250px 1fr;
    }
}