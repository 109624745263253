@import '../../../../defaults.scss';

.device-situations-parameters > *:not(:first-child) {
  margin-top: 20px;
}

.device-situations-parameters .form-control-group .checkbox .rs-checkbox-checker {
  padding-left: 26px;
}

.device-situations-parameters .form-control-group .checkbox .rs-checkbox-wrapper {
  left: 0;
}

.device-situations-parameters .object-device-manager .header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.device-situations-parameters .object-device-manager .device-set .x-list.list {
  border: 1px solid $color-border;
  max-height: 180px;
}
