@import '../../../../defaults.scss';

.device-list {
	box-shadow: none;
}
.device-list .rs-list-item-md {
	padding: 0;
}
.device-list-item-content {
	box-shadow: none;
	color: #798699;
	cursor: pointer;
	user-select: none;
	display: flex;
}
.device-list .device.selected .device-list-item-content > .wrap {
	padding: 15px 5px 15px 25px;
	flex-grow: 1;
	position: relative;
}
.device-list .device.selected .device-list-item-content > .action {
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 17px;
	padding: 7px;
	color: white;
	background-color: #e46060;
}
.device-list > *:nth-child(even) .device {
	background-color: #3F4246;
}
.device-list > *:nth-child(odd) .device {
	background-color: #313438;
}
.device-list .device .device-list-item-content {
	background-color: transparent;
}
.device-list-item-content {
	background-color: #313438;
}
.device-list .device.selected {
	background-color: #f0f0f0;
}
.device-list .device.droppable {
	background-color: $color-primary !important;
	color: white !important;
}
.device-list .device.droppable .wrap > .icon > img,
.device-list .device.droppable .location-icon img,
.device-list .device.droppable .pin-icon {
	filter: $icon-filter-white;
}
.device-list .device.draggable {
	opacity: 0.5;
}
.device-list-item-content .wrap {
	padding: 15px 25px;
	display: flex;
	width: 100%;
}
.device-list-item-content .wrap > .icon {
	flex-basis: 40px;
	flex-shrink: 0;
	margin-right: 5px;
}
.device-list-item-content .wrap > .icon > img {
	width: 32px;
	filter: $text-filter-main-2;
}

.device-list .device .wrap .content {
	flex-grow: 1;
	display: flex;
	flex-direction: column;
	justify-content: center;
}
.drag-layer .device-list-item-content {
	width: $sidebar-width;
}
.device-list-item-content .location {
	padding-top: 5px;
	padding-bottom: 5px;
	font-size: 80%;
	min-height: 36px;
}
.device-list-item-content .location .loader {
	align-items: flex-start;
}
.device-list-item-content .location-icon img,
.device-list-item-content .pin-icon {
	height: 10px;
	vertical-align: baseline;
	filter: $text-filter-main-2;
}

.device-list-item-content .icons {
	margin-top: 4px;
	position: relative;
	display: grid;
	grid-template-columns: auto auto auto 1fr;
	column-gap: 6px;
}
.device-list .device .icons > img {
	height: 15px;
}
.device-list-item-content .pin-block {
	position: absolute;
	right: 7px;
	padding: 0 5px;
	top: 8px;
	cursor: pointer;
	z-index: 10;
}
.device-list-item-content .wrap > .action {
	font-size: 25px;
    width: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
}
