@import '../../../defaults.scss';


$tab-height: 45px;

.layout.tab-layout {
	display: flex;
	flex-direction: row;
	height: 100%;
	width: 100%;
}

.layout.tab-layout .tabs {
	width: $tab-height;
	min-width: $tab-height;
	margin-left: -$tab-height;
	position: relative;
}

.layout.tab-layout .tabs > .wrap {
	transform: rotate(-90deg) translate(-100%);
	position: relative;
	top: 10%;
	height: $tab-height;
	width: max-content;
	transform-origin: 0 0;
	display: flex;
	flex-direction: row;
	align-items: baseline;
}

.layout.tab-layout .tabs > .wrap > .tab {
	font-size: larger;
    font-weight: bold;
	display: flex;
    align-items: center;
	border-top-left-radius: 5px;
	border-top-right-radius: 5px;
	padding: 7px 15px;
	cursor: pointer;
	width: max-content;
	height: $tab-height;
	background-color: white;
	border: 1px solid $color-border;
}

.layout.tab-layout .tabs > .wrap > .tab.active {
	display: none;
}

.layout.tab-layout .tabs > .wrap > .tab img {
	width: 22px;
    filter: invert(47%) sepia(7%) saturate(21%) hue-rotate(357deg) brightness(90%) contrast(92%);
}

.layout.tab-layout .tabs > .wrap > .tab img ~ span {
	margin-left: 10px
}
.layout.tab-layout > .content {
	width: 100%;
}