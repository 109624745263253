@import "../../defaults.scss";

$padding: 15px;

.app-page.device-properties {
	padding-left: 30px;
}
.app-page.device-properties .sidebar .property-element {
	width: calc(#{$sidebar-width} - 2 * #{$padding});
}
.app-page.device-properties .sidebar .property-element .name {
	overflow: hidden;
	overflow-wrap: break-word;
}
.app-page.device-properties .sidebar-layout .sidebar-content > .action-bar  {
	position: absolute;
	top: 0;
	right: 0;
	padding: 17px;
}
.app-page.device-properties .layout.sidebar-layout.body {
	height: 100%;
}
.app-page.device-properties .layout.sidebar-layout.body > .content {
	flex-grow: 1;
	border-left: 1px solid $color-border;
	padding: 15px;
	overflow-y: auto;
}
.app-page.device-properties .sidebar-layout .sidebar-content {
	padding: $padding;
}
.app-page.device-properties .sidebar-content {
	overflow-y: auto;
}
.app-page.device-properties .sidebar-content .filter .input label {
	display: block;
    font-weight: bold;
    margin-bottom: 10px;
}
.app-page.device-properties .sidebar-content .filter .input label::first-letter {
	text-transform: capitalize;
}