@import '../../../defaults.scss';

.floating-label-input .placeholder.label {
	transform: scale(.75) translateY(-34px);
	font-size: 16px;
	top: 8px;
	text-transform: capitalize;
	font-weight: bold;
	margin: 0;
}
.floating-label-input .placeholder {
    position: absolute;
	transform-origin: bottom left;
	background: #fff;
    left: 7px;
	top: 5px;
	border-radius: 4px;
	line-height: 26px;
    max-width: -webkit-calc(100% - (2*8px));
    max-width: calc(100% - (2*8px));
    overflow: hidden;
    padding: 0 5px;
    text-overflow: ellipsis;
    -webkit-transition: transform 150ms cubic-bezier(0.4,0,0.2,1),opacity 150ms cubic-bezier(0.4,0,0.2,1);
    transition: transform 150ms cubic-bezier(0.4,0,0.2,1),opacity 150ms cubic-bezier(0.4,0,0.2,1);
    white-space: nowrap;
    width: auto;
    z-index: 1;
}

.floating-label-input .input {
	flex-grow: 1;
	flex-shrink: 1;
    background-color: transparent !important;
    border: none;
    display: block;
	color: $text-color-main;
	min-width: 0%;
    outline: none;
	border-radius: 4px;
    height: 36px;
    padding: 13px 10px;
    z-index: 1;
}
.floating-label-input .input[disabled] {
	color: $text-color-pale;
}

.floating-label-input .wrap {
	position: relative;
	display: flex;
	border: 2px solid $color-primary;
	border-radius: 5px;
	margin-top: 20px;
}
.floating-label-input.disabled .wrap {
	border-color: $color-primary-pale;
}
.floating-label-input .wrap .show-password {
	width: 30px;
    display: flex;
	cursor: pointer;
    align-items: center;
	color: $text-color-main;
    justify-content: center;
}
.floating-label-input .wrap .show-password:hover {
	color: $color-primary;
}
.floating-label-input.error .wrap {
	border-color: red;
}
.floating-label-input.error .placeholder {
	color: red;
}
.floating-label-input .error,
.floating-label-input .description {
	margin: 3px 12px;
}
.floating-label-input .description {
	color: $text-color-main !important;
}
.floating-label-input .error {
	font-size: smaller;
	color: red;
}
