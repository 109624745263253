.commute-detector-list {
    display: flex;
    flex-direction: column;
    height: 0;
}
.commute-detector-list label {
    padding: 15px;
    margin-bottom: 0;
}
.commute-detector-list {
	flex-grow: 1;
    display: flex;
    flex-direction: column;
    overflow-y: auto;;
}
.commute-detector-list .list-item.droppable {
    background-color: #79CDD0 !important;
    color: white !important;
    box-shadow: 0px 2px 7px -2px rgba(0, 0, 0, 0.4);
}
