@import '../../defaults';

.app-page.message-publisher .form,
.app-page.message-publisher .form fieldset {
	height: 100%;
}

.app-page.message-publisher .body {
	height: 100%;
}

.app-page.message-publisher .sidebar .header {
	display: flex;
	align-items: center;
	margin-right: 80px;
}

.app-page.message-publisher .sidebar .header .icon > * {
	margin-right: 5px;
	display: block;
}

.app-page.message-publisher .sidebar-content {
	display: flex;
	flex-direction: column;
	flex-grow: 1;
	overflow: hidden;
}

.app-page.message-publisher .sidebar-content .error {
	margin-top: 10px;
	text-align: center;
}

.app-page.message-publisher .sidebar-content .filter {
	border-bottom: 1px solid $color-border;
	padding: 15px;
}

.app-page.message-publisher .sidebar-content .filter .input {
	display: flex;
	flex-direction: column;
}

.app-page.message-publisher .sidebar-content .filter .input label {
	margin-bottom: 10px;
}

.app-page.message-publisher .sidebar-content .action-bar {
	position: absolute;
	top: 0; right: 0;
	padding: 25px 15px;
}

.app-page.message-publisher .sidebar-content .action-bar .actions {
	display: grid;
	grid-auto-flow: column;
	grid-auto-columns: 16px;
	grid-gap: 16px;
	cursor: pointer;
}

.app-page.message-publisher .sidebar-content .action-bar .actions > * img {
	filter: $text-filter-main;
}

.app-page.message-publisher .sidebar-content > .content {
	padding: 15px;
	overflow-y: auto;
}

.app-page.message-publisher .sidebar-content > .content > .button {
	margin-top: 20px;
}

.app-page.message-publisher .message-publisher-card {
	border-top: 1px solid $color-border;
	padding: 15px;
}

.app-page.message-publisher .message-publisher-card .publications .option::first-letter {
	text-transform: capitalize;
}

.app-page.message-publisher .message-publisher-card .name div {
	overflow-wrap: break-word;
}

.app-page.message-publisher .message-publisher-card > div ~ div {
	margin-top: 10px;
}

.app-page.message-publisher .layout.sidebar-layout > .content {
	position: relative;
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	border-left: 1px solid $color-border;
}

.app-page.message-publisher .list .list-item {
	overflow-wrap: break-word;
	padding: $list-item-default-padding;
}

.app-page.message-publisher .sidebar .header {
	width: 120px;
}

