@import "../../../../defaults.scss";

$group-width:
	minmax(60px, 1fr) // consumed
	minmax(60px, 1fr) // drained
	minmax(60px, 1fr) // refueled
;

$message-group-width:
	minmax(80px, 1fr) // datetime
	minmax(180px, 4fr) // location
;

.trip-history-list.scroll-list .header-item {
	position: sticky;
	top: 0;
	font-weight: bold;
	padding-right: 15px;
	color: black;
	border-bottom: 1px solid $color-border;
	background-color: white;
	z-index: 1;
}

.trip-history-list.scroll-list.fuelLevel {
	border: 1px solid $color-border;
	display: grid;
	min-height: 250px;
	grid-template-columns:
		80px // No
		minmax(260px, 1fr) // start
		minmax(260px, 1fr) // end
		minmax(70px, 7%) // duration
		minmax(60px, 7%) // distance
		minmax(180px, 20%) // fuelLevelBalance
	;
}

.trip-history-list.scroll-list {
	border: 1px solid $color-border;
	display: grid;
	grid-template-columns:
		80px // No
		minmax(250px, 1fr) // start
		minmax(250px, 1fr) // end
		minmax(70px, 7%) // duration
		minmax(60px, 7%) // distance
	;
}

.trip-history-list.scroll-list.fuelLevel .loader,
.trip-history-list.scroll-list.fuelLevel .indicator {
	grid-column: 1 / 7;
}

.trip-history-list.scroll-list .loader,
.trip-history-list.scroll-list .indicator {
	grid-column: 1 / 6;
}

.trip-history-list.scroll-list .header-item {
	display: flex;
	align-items: flex-end;
}

.trip-history-list.scroll-list .header-item.rows,
.trip-history-list.scroll-list .header-item.message,
.trip-history-list.scroll-list .fuel-level-balance-group {
	display: grid;
}
.trip-history-list.scroll-list .header-item.message > div:last-child {
	display: grid;
	grid-template-columns: $message-group-width;
}
.trip-history-list.scroll-list .message-group {
	display: grid;
	grid-template-columns: $message-group-width;
}
.trip-history-list.scroll-list > * {
	padding: 10px !important;
}
.trip-history-list.scroll-list div.selected {
	background-color: #d0d0d0 !important;
}
.trip-history-list.scroll-list .message-group > div,
.trip-history-list.scroll-list .header-item.message > div:first-child,
.trip-history-list.scroll-list .header-item.message > div:last-child > div {
	padding: 5px;
}
.trip-history-list.scroll-list .header-item.rows > div:last-child {
	display: grid;
	grid-template-columns: $group-width;
}
.trip-history-list.scroll-list .fuel-level-balance-group {
	grid-template-columns: $group-width;
}
.trip-history-list.scroll-list .fuel-level-balance-group > div {
	word-break: break-all;
	padding: 5px;
}
.trip-history-list.scroll-list .location span:not(:last-child) {
    display: flex;
    align-items: center;
}

.trip-history-list.scroll-list.fuelLevel > *:nth-child(12n+7),
.trip-history-list.scroll-list.fuelLevel > *:nth-child(12n+8),
.trip-history-list.scroll-list.fuelLevel > *:nth-child(12n+9),
.trip-history-list.scroll-list.fuelLevel > *:nth-child(12n+10),
.trip-history-list.scroll-list.fuelLevel > *:nth-child(12n+11),
.trip-history-list.scroll-list.fuelLevel > *:nth-child(12n+12) {
	background-color: $reports-bg-color;
}

.trip-history-list.scroll-list:not(.fuelLevel) > *:nth-child(10n+6),
.trip-history-list.scroll-list:not(.fuelLevel) > *:nth-child(10n+7),
.trip-history-list.scroll-list:not(.fuelLevel) > *:nth-child(10n+8),
.trip-history-list.scroll-list:not(.fuelLevel) > *:nth-child(10n+9),
.trip-history-list.scroll-list:not(.fuelLevel) > *:nth-child(10n+10) {
	background-color: $reports-bg-color;
}

@media (max-width: $screen-tablet) {
	.trip-history-list.scroll-list {
		max-height: calc(100vh - 70px);
	}
}
