@import "../../../defaults.scss";

.zone-x {
	display: flex;
	height: 100%;
}
.zone-x .action-bar {
	position: absolute;
	top: 0;
	right: 0;
	padding: 17px;
}
.zone-x .action-bar .actions {
	display: grid;
	grid-auto-flow: column;
	grid-auto-columns: 16px;
	grid-gap: 16px;
	cursor: pointer;
}
.zone-x .sidebar .action-bar .actions > * img {
	filter: $text-filter-main;
}
.zone-x > .content {
	flex-grow: 1;
	position: relative;
}

.zone-x .zone-map {
	height: 100%;
}

.zone-x .list-item {
	width: 100%;
	text-transform: capitalize;
	overflow-wrap: break-word;
	padding: $list-item-default-padding !important;
}