@import '../../../defaults.scss';

.device-filtered-events .title {
	margin-bottom: 10px;
}
.device-filtered-events .x-list.list .event-item {
	padding: 10px 0;
}
.device-filtered-events .x-list.list .event-item > div:not(:last-child) {
	padding-bottom: 7px;
}
.device-filtered-events .x-list.list .event-item .event-icon {
	height: 20px;
    width: 20px;
    min-width: 20px;
    background-size: 20px;
}
.device-filtered-events .x-list.list .event-item:not(:last-child) {
    border-bottom: 1px solid $color-border;
}