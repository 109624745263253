@import "../../defaults.scss";

$padding: 15px;

.object-action-dialog-popup {
	width: auto;
	height: auto;
	position: absolute;
	z-index: 1;
	max-width: 30%;
}
.object-action-dialog-popup > div:first-child {
	position: absolute;
	width: 100%;
	height: auto;
	background-color: white;
	opacity: 0.9;
}
.object-action-dialog-popup > div:last-child {
	position: relative;
	padding: $padding;
	border: 1px solid $color-primary;
}

.object-action-dialog-popup > div > .header {
	font-weight: bold;
	position: relative;
	text-align: left;
	color: black;
	margin-bottom: 10px;
	display: flex;
	align-items: center;
}
.object-action-dialog-popup > div > .header > *:first-child {
	flex-grow: 1;
}
.object-action-dialog-popup > div > .header > *::first-letter {
	text-transform: capitalize;
}
.object-action-dialog-popup .actions .close {
	cursor: pointer;
	color: $text-color-main;
	height: 11px;
}
.object-action-dialog-popup .actions .close > img {
	height: 100%;
	display: block;
}
