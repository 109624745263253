.group-message-fields .fields {
	display: flex;
	flex-direction: column;
}
.group-message-fields .message-field {
	display: inline-block;
}
.group-message-fields .message-field span.label {
	font-weight: bold;
	margin-right: 5px;
}
.group-message-fields .message-charts {
	display: flex;
	flex-direction: row;
	justify-content: space-around;
}
.group-message-fields .fields > label {
	margin-bottom: 10px;
	display: block;
	text-align: center;
}
.group-message-fields .no-data-view {
	min-height: 60px;
}