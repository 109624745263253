.widget.time-machine-player {
	padding-bottom: 10px;
}
.widget.time-machine-player .button-group ~ .button-group {
	margin-top: 1rem;
}
.widget.time-machine-player .button-group:first-child {
	grid-template-columns: 15% 1fr 15%;
}
.widget.time-machine-player .button-group:nth-child(2) {
	grid-template-columns: 1fr 14px repeat(3, 15%);
}
.widget.time-machine-player .button.chevron {
	border: none;
	width: 14px;
	color: #8e8e93;
}
.widget.time-machine-player .button.chevron svg {
	stroke-width: 3px;
}
.widget.time-machine-player .button > *:first-child {
	display: flex;
}
