@import '../../defaults.scss';

.app-page.device-presence-detectors .form, .app-page.device-presence-detectors .form fieldset {
	height: 100%;
}
.app-page.device-presence-detectors .body {
	height: 100%;
}
.app-page.device-presence-detectors .sidebar .header {
    display: flex;
	align-items: center;
	margin-right: 80px;
}
.app-page.device-presence-detectors .sidebar .header .icon > * {
	margin-right: 5px;
	display: block;
}
.app-page.device-presence-detectors .sidebar-content {
	display: flex;
	flex-direction: column;
	flex-grow: 1;
	overflow: hidden;
}
.app-page.device-presence-detectors .sidebar-content .error {
	margin-top: 10px;
	text-align: center;
}
.app-page.device-presence-detectors .sidebar-content .filter {
	border-bottom: 1px solid $color-border;
	padding: 15px;
}
.app-page.device-presence-detectors .sidebar-content .filter .input {
	display: flex;
	flex-direction: column;
}
.app-page.device-presence-detectors .sidebar-content .filter .input label {
	margin-bottom: 10px;
}
.app-page.device-presence-detectors .sidebar-content .action-bar {
	position: absolute;
    top: 0; right: 0;
    padding: 15px;
}
.app-page.device-presence-detectors .sidebar-content .action-bar .actions {
	display: grid;
    grid-auto-flow: column;
    grid-auto-columns: 16px;
    grid-gap: 16px;
    cursor: pointer;
}
.app-page.device-presence-detectors .sidebar-content .action-bar .actions > * img {
	filter: $text-filter-main;
}
.app-page.device-presence-detectors .sidebar-content > .content {
	padding: 15px;
	overflow-y: auto;
}
.app-page.device-presence-detectors .sidebar-content > .content > .button {
	margin-top: 20px;
}


.app-page.device-presence-detectors .presence-detector.card {
	border-top: 1px solid $color-border;
	padding: 15px;
}
.app-page.device-presence-detectors .presence-detector.card .name div {
	word-break: break-word;
}
.app-page.device-presence-detectors .presence-detector.card > div ~ div {
	margin-top: 10px;
}
.app-page.device-presence-detectors .presence-detector.card .options .option::first-letter {
	text-transform: capitalize;
}
.app-page.device-presence-detectors .layout.sidebar-layout > .content {
	position: relative;
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	border-left: 1px solid $color-border;
}
.app-page.device-presence-detectors .list .list-item {
	word-break: break-word;
	padding: 15px !important;
}
.app-page.device-presence-detectors .presence-detector-remover .message {
	font-weight: bold;
	padding: 15px 0;
	color: $text-color-main;
}
