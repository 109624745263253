@import '../../../../defaults.scss';

.action-bar.device-action-bar img {
	filter: $text-filter-main-2;
}
.action-bar.device-action-bar .action.add .bp3-popover-open img {
	filter: $icon-filter-highlight;
}
.action-bar.device-action-bar .wrap.draggable img {
	filter: $icon-filter-highlight;
}
.device-sidebar .toggle {
	padding: 0 5px;
}