@import '../../../../defaults.scss';

.action.device-add .device-action-add-popover.bp3-popover {
	margin-top: 5px !important;
	border-color: #79cdd0 !important;
	color: black !important;
	border-radius: 5px !important;
}
.action.device-add .popup.active img {
	filter: $icon-filter-highlight;
}
.action.device-add .device-action-add-popover .bp3-popover-content {
	border-radius: 5px !important;
}
.action.device-add .device-action-add-popover a div {
	color: black;
}
.action.device-add .popover a div::first-letter {
	text-transform: capitalize;
}
.action.device-add .popover a {
	height: 40px;
}
.action.device-add .device-action-add-popover a:hover {
	background-color: #e2f4f5 !important;
}
.action.device-add .device-action-add-popover .bp3-menu {
	padding: 0;
}