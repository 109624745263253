@import "../../../defaults.scss";

// menu
.x-picker.menu {
	padding: 0;
	border-radius: 2px;
}
.x-picker.menu a {
	text-decoration: none;
}

// menu light
.x-picker.menu {
	background-color: white;
	color: $text-color-main;
}
.x-picker.menu .rs-picker-select-menu-items {
	margin: 0;
}
.x-picker.menu .rs-picker-select-menu-item {
	padding: 15px;
	color: inherit;
}
.x-picker.menu .rs-picker-select-menu-item:hover:not(.rs-picker-select-menu-item-active) {
	background-color: $color-primary-pale;
}
.x-picker.menu .rs-picker-select-menu-item-active {
	background-color: $color-primary !important;
	color: white !important;
}
.x-picker.menu .rs-picker-none {
	background-color: white;
}

// menu dark
.x-picker.menu.dark {
	background-color: $color-primary-dark-bg;
}
.x-picker.menu.dark .rs-picker-select-menu-items {
	margin: 0;
}
.x-picker.menu.dark .rs-picker-select-menu-item {
	padding: 15px;
	color: inherit;
}
.x-picker.menu.dark .rs-picker-select-menu-item:hover:not(.rs-picker-select-menu-item-active) {
	background-color: $color-primary-dark-pale;
}
.x-picker.menu.dark .rs-picker-select-menu-item-active {
	background-color: $color-primary-dark;
	color: white;
}
.x-picker.menu.dark .rs-picker-none {
	background-color: $color-primary-dark-bg;
}