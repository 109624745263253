@import "../../../defaults.scss";

.event-field {
	display: flex;
	align-items: center;
	flex-wrap: nowrap !important;
}

.event-field .aux {
	font-size: 90%;
	color: $text-color-light;
}
.event-field .additional-content {
	padding: 5px 0
}
.event-field .arrow-button {
	display: flex;
	justify-content: flex-start;;
    padding: 3px;
}
.event-field .arrow-button img {
	margin-left: 50px;
	height: 5px;
	user-select: none;
	filter: $icon-filter-main;
}
.event-field .arrow-button:hover img {
	filter: $icon-filter-highlight;
}
.event-field .arrow-button img.rotate {
	transform: rotate(180deg);
}