@import '../../../../defaults.scss';

.device-behaviour-parameters > *:not(:first-child) {
	margin-top: 20px;
}

.device-behaviour-parameters .object-device-manager .header {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
}
.device-behaviour-parameters .object-device-manager .device-set .x-list.list {
	border: 1px solid $color-border;
	max-height: 180px;
}
