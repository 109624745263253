@import "./defaults.scss";

// WARNING!
// FILE ONLY FOR THE MOST GENERAL 3-RD PARTY UI FRAMEWORK STYLE FIXING

*[class^="rs-"]:not(body) {
	font-size: inherit;
}

.rs-panel .rs-panel-heading {
	font-size: inherit;
	color: inherit;
	width: 100%;
}
.rs-panel .rs-panel-heading > span.lg {
	font-size: larger;
}

.rs-control-label {
	font-weight: bold;
}

.rs-picker-toggle {
	color: inherit !important;
}

// Avatar
.rs-avatar {
	border-radius: 5px;
}
.rs-avatar.rs-avatar-circle {
	border-radius: 50%;
}

// Popover
.bp3-popover {
	box-shadow: none;
	border: 1px solid $color-border;
}
.bp3-popover-content {
	border-radius: 0 !important;
}

.rs-popover.custom-hover {
	padding: 7px;
}

// Menu
.bp3-menu-item {
	align-items: center;
}
.bp3-menu-item.bp3-disabled {
	text-decoration: none;
}
.bp3-menu {
	color: $text-color-main;
}

// List
.rs-list {
	box-shadow: none;
}
.rs-list-item {
	box-shadow: none;
}

// CheckBox
.rs-checkbox-checked .rs-checkbox-wrapper .rs-checkbox-inner:before,
.rs-radio-checked .rs-radio-wrapper .rs-radio-inner:before,
.rs-checkbox-indeterminate .rs-checkbox-wrapper .rs-checkbox-inner:before {
	border-color: $color-primary;
	background-color: $color-primary;
}
.rs-checkbox-checker:hover .rs-checkbox-wrapper .rs-checkbox-inner:before {
	border-color: $color-primary !important;
}
.rs-checkbox-checker {
	padding-top: 0;
	padding-bottom: 0;
	display: flex;
	align-items: center;
}
.rs-checkbox-checker label {
	margin-bottom: 0 !important;
}

// Radio

.rs-radio-checker:hover .rs-radio-wrapper .rs-radio-inner:before {
    border-color: $color-primary !important;
}

//
.rs-picker-menu {
	padding: 0 !important;
	border-radius: 0;
	// background-color: transparent;
}
.rs-picker-menu.placement-bottom-start {
	margin-top: 6px;
}
.rs-picker-none {
	height: 40px;
	display: flex;
	align-items: center;
	padding-top: 0;
	padding-bottom: 0;
}
.rs-picker-tag-wrapper {
	max-height: 100px;
	overflow: auto;
}

// rsuite button
.rs-btn-loading > .rs-btn-spin:before {
	border-color: $color-primary;
}
.rs-btn-primary.rs-btn-loading > .rs-btn-spin:after {
	border-top-color: $color-primary;
}

// Notifications
.rs-notification .rs-notification-item-content {
	width: 300px;
}
.rs-notification .rs-notification-item-content .rs-notification-title {
	font-weight: bold;
}
.rs-notification .rs-notification-item-close .rs-notification-item-close-x:hover {
	color: $color-primary;
}
.rs-notification-title-with-icon {
	display: flex;
    align-items: center;
}
.notification-title.custom {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.rs-input-number {
	display: grid;
	grid-template-columns: 1fr auto;
}