@import "../../../defaults.scss";

.toggle {
	display: flex;
	flex-direction: row;
}
.toggle.block {
	display: block;
}

.toggle > * {
	display: flex;
	align-items: center;
}
.toggle > *:first-child {
	flex-grow: 1;
}
.toggle > .label {
	margin-right: 10px;
}
.toggle .rs-btn-toggle-checked, .toggle .rs-btn-toggle-checked:hover {
	background-color: $color-primary;
}
.toggle .rs-btn-toggle-inner, .toggle .label span {
	font-weight: bold;
}
