@import '../../../../../defaults.scss';

.fuel-level-balance-chart > .wrap {
	display: grid;
	grid-template-rows: auto 1fr;
	padding-left: 15px;
	background-color: white;
	height: 100%;
	width: 100%;
}
.fuel-level-balance-chart.fullscreen > .wrap {
	position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100% !important;
    z-index: 100;
    margin: 0 !important;
    padding: 20px !important;
}
.fuel-level-balance-chart .title {
	padding-bottom: 5px;
}
.fuel-level-balance-chart .chart-container {
	display: flex;
}
.fuel-level-balance-chart .unselectable {
	-moz-user-select: -moz-none;
	-khtml-user-select: none;
	-webkit-user-select: none;
	-ms-user-select: none;
	user-select: none;
}
.fuel-level-balance-chart .chart-tools {
	position: relative;
	overflow-y: auto;
}
.fuel-level-balance-chart .rv-discrete-color-legend .rv-discrete-color-legend-item {
	display: flex;
    align-items: center;
    flex-direction: row-reverse;
}
.rv-discrete-color-legend-item__title {
	margin: 0 10px 0 0;
}
.fuel-level-balance-chart .rv-discrete-color-legend .rv-discrete-color-legend-item.clickable.disabled {
	cursor: pointer !important;
}
.fuel-level-balance-chart .chart-interactions {
	display: flex;
	padding: 10px;
	justify-content: flex-end;
	position: sticky;
	top: 0;
	background-color: white;
}
.fuel-level-balance-chart .chart-interactions > * {
	margin-left: 5px;
}
.fuel-level-balance-chart .chart-interactions > *.selected > *,
.fuel-level-balance-chart .chart-interactions > *:hover > * {
	color: $color-primary;
}