@import '../../../defaults.scss';

.zone-selector .zone-filter .input {
	display: flex;
	flex-direction: column;
}
.zone-selector .zone-filter .input label {
	margin-bottom: 10px;
}

.zone-selector {
	display: grid;
	grid-gap: 20px;
	grid-template-columns: minmax(170px, 1fr) minmax(300px, 3fr);
	grid-auto-rows: 100%;
	width: 100%;
	height: 100%;
	position: relative;
}

.zone-selector .section {
	display: flex;
	flex-direction: column;
	height: 100%;
}
.zone-selector .section label::first-letter {
	text-transform: capitalize;
}
.zone-selector .section > * ~ * {
	margin-top: 15px;
}

.zone-selector .section > .content {
	flex-grow: 1;
	border: 1px solid $color-border;
	width: 100%;
	overflow-y: auto;
}

.zone-selector .x-list .list-item {
	padding: $list-item-default-padding;
	overflow-wrap: break-word;
}

/* left block */

.button-group.zone-selector-buttons {
	display: grid;
	grid-gap: 10px;
	grid-template-columns: calc(50% - 5px) calc(50% - 5px);
}

.modal .zone-selector-buttons {
	max-width: 400px;
	padding: 15px 0;
}

/* right block */

.zone-selector .zone-category-selector .header {
	border-top: none;
}
.zone-selector .zone-category-selector.collapsed .header {
	border-bottom: none;
}
.zone-selector .zone-list {
	flex-grow: 1;
}