@import "../../../../defaults.scss";

.app-page.device-card .page-panel > .content .device-form {
	max-width: 500px;
}
.app-page.device-card .page-panel > .wrap > .content .device-form .main-fields {
	display: grid;
	grid-template-columns: 9fr 1fr;
	grid-gap: 10px;
}
.app-page.device-card .page-panel > .wrap {
	display: flex;
	flex-direction: column;
}
.app-page.device-card .page-panel > .wrap > .header {
	display: flex;
	align-items: center;
	padding: 15px 15px 0 15px;
	font-size: larger;
}
.app-page.device-card .page-panel > .wrap > .header .icon {
	margin-right: 10px;
}
.app-page.device-card .page-panel > .wrap > .header .title {
	font-weight: bold;
}
.app-page.device-card .page-panel > .wrap > .header .title::first-letter {
	text-transform: capitalize;
}
.app-page.device-card .page-panel > .wrap > .content {
	flex-grow: 1;
	padding: 15px;
	overflow-y: auto;
	width: 100%;
}

.app-page.device-card .page-panel > .wrap > .content .device-form {
	max-width: 500px;
}