.flow-processor-details .content > div:not(:first-child) {
	margin-top: 15px;
}
.flow-processor-details .message-field > div {
	display: inline-block;
	width: 100%;
	background-color: #f0f0f0;
	border-radius: 5px;
	padding: 15px;
}
.flow-processor-details .message-field > div::first-letter {
	text-transform: capitalize;
}

.flow-processor-details .form-control {
	margin-bottom: 15px;
}

.flow-processor-details .rs-checkbox .rs-checkbox-checker {
	padding-left: 26px;
}
.flow-processor-details .rs-checkbox .rs-checkbox-wrapper {
	left: 0;
}
