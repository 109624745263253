@import "../../defaults.scss";

.app-page.login {
	width: 100%; height: 100%;
	min-height: 600px;
	background-image: url('../../img/login-page-background.jpg');
	background-image: image-set('../../img/login-page-background.jpg' 1x,
								'../../img/login-page-background-2x.jpg' 2x);
	background-position: top;
	background-repeat: no-repeat;
	background-size: cover;
}

.app-page.login .logo-white {
	position: absolute;
	left: 5%;
	top: 6%;
}
.app-page.login .logo {
	display: none;
}

.app-page.login .rs-panel {
	width: 21.9%;
	min-width: 400px;
	height: 100%;
	background-color: white;
	margin-right: 16.4%;
	margin-left: auto;
	padding: 40px;
	display: flex;
	align-items: center;
	flex-direction: column;
	justify-content: center;
	border-radius: 0;
	position: relative;
}
.app-page.login .rs-panel .rs-panel-heading {
	padding-bottom: 0;
}
.app-page.login .rs-panel .rs-panel-heading > *:first-child {
	color: $color-primary;
	width: 70%;
	margin-bottom: 10px;
	font-size: 150%;
	font-weight: bold;
}
.app-page.login .rs-panel .rs-panel-heading > *::first-letter {
	text-transform: capitalize;
}
.app-page.login .rs-panel .rs-panel-body {
	padding-top: 0;
	width: 100%;
}


.app-page.login .form .form-control:not(:first-child) {
	margin-top: 25px;
}
.app-page.login .form input {
	border-top: none;
	border-right: none;
	border-bottom-width: 1px;
	border-left: none;
	border-radius: 0;
}
.app-page.login .form button {
	width: 100%;
	height: 40px;
	border-radius: 20px;
	font-weight: bold;
}

@media (max-width: 1000px) {
	.app-page.login .logo-white {
		display: none;
	}
	.app-page.login .logo {
		display: block;
		position: absolute;
		top: 10%;
		left: 60px;
	}
}
