@import '../../defaults.scss';

.languages-menu.bp3-menu {
	padding: 5px;
	min-width: auto;
}
.languages-menu .language-item img {
	width: 16px;
	height: 16px;
	// filter: $icon-filter-main;
}
.languages-menu .language-item {
	padding: 5px 7px;
	line-height: 20px;
}
.languages-menu .language-item:hover {
	background-color: rgba(167, 182, 194, 0.3);
}
.languages-menu .language-item.active img {
	filter: $icon-filter-highlight;
}
.languages-menu .language-item.active {
	color: $color-primary;
}