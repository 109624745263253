@import "../../../defaults.scss";

$margin: 15px;

.form {
	display: block;
}

.form input[type="submit"] {
	display: none;
}

.form > fieldset:not(:first-child),
.form > fieldset > * ~ *,
.form .form-control-group ~ .form-control-group,
.form .form-control-group .content > * ~ *,
.form > .error,
.form > .footer {
	margin-top: $margin;
}

.form label, .form .label {
	margin-bottom: 8px;
	display: block;
	font-weight: bold;
}
.form label::first-letter, .form .label::first-letter {
	text-transform: capitalize;
}

// checkbox

.form .form-control .rs-checkbox-checker {
	height: 26px;
	min-height: 26px;
}
.form .form-control .rs-checkbox-wrapper {
	top: 5px;
}

.form .form-control .error {
	margin-top: 5px;
}

.form .form-control-group.labelled > .content {
	padding: 10px;
	border: 1px solid $color-border;
	border-radius: 2px;
}