.app-page.time-machine {
	display: flex;
	flex-direction: column;
}
.app-page.time-machine > .content {
	flex-grow: 1;
	position: relative;
}

.app-page.time-machine > .footer  {
	display: flex;
	flex-direction: column;
	flex: 0 0 130px;
	height: 130px;
}
