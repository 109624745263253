@import '../../../defaults.scss';

.group-checkbox .rs-checkbox-checker {
	padding-left: 26px;
}
.group-checkbox .rs-checkbox-checker .line {
	flex: 1;
	height: 1px;
	background-color: $color-border;
}
.group-checkbox .rs-checkbox-checker label {
	margin-right: 8px;
}
.group-checkbox .rs-checkbox-checker .rs-checkbox-wrapper {
	left: 0;
}