@import "../../../../defaults.scss";

.device-x-editor > div ~ div {
	margin-top: 10px;
}
.device-x-editor .header {
	font-weight: bold;
}
.device-x-editor .header::first-letter {
	text-transform: capitalize;
}
.device-x-editor .content > div ~ * {
	margin-top: 10px;
}

.device-x-editor .footer {
	padding-top: 10px;
	display: grid;
	grid-template-columns: calc(50% - 5px) calc(50% - 5px);
	grid-gap: 10px;
}
