@import '../../../../defaults.scss';
@import './index.scss';

.report.fuel-level .scroll-list {
	display: grid;
	grid-template-columns:
		minmax(60px, 1fr) // No
		minmax(100px, 3fr) // start time
		minmax(100px, 3fr) // end time
		minmax(80px, 2fr) // duration
		minmax(40px, 1fr) // fuel level
	;
}

.report.fuel-level .scroll-list .loader,
.report.fuel-level .scroll-list .indicator {
	grid-column: 1 / 6;
}

.report.fuel-level .scroll-list > *:nth-child(10n+6),
.report.fuel-level .scroll-list > *:nth-child(10n+7),
.report.fuel-level .scroll-list > *:nth-child(10n+8),
.report.fuel-level .scroll-list > *:nth-child(10n+9),
.report.fuel-level .scroll-list > *:nth-child(10n+10) {
	background-color: $reports-bg-color;
}