@import '../../defaults.scss';

.info-dialog > * ~ * {
	margin-top: 15px;
}

.info-dialog .message {
	font-weight: bold;
	color: $text-color-main;
}
.info-dialog .message::first-letter {
	text-transform: capitalize;
}
