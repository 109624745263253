@import "../../../defaults.scss";

.layout.sidebar-layout {
	display: flex;
}

.layout.sidebar-layout .sidebar {
	flex: 0 0 $sidebar-width;
	width: $sidebar-width;
	max-width: $sidebar-width;
	display: flex;
	flex-direction: column;
	position: relative;
}

.layout.sidebar-layout .sidebar > .header {
	padding: 15px;
	display: flex;
	align-items: center;
}
.layout.sidebar-layout .sidebar > .header .icon > img {
	height: 22px;
	margin-right: 10px;
	filter: $text-filter-main;
}
.layout.sidebar-layout .sidebar > .header .title {
	font-size: larger;
	font-weight: bold;
	filter: $text-filter-main;
}
.layout.sidebar-layout .sidebar > .header .title::first-letter {
	text-transform: capitalize;
}