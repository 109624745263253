.form.account-details .block {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
}
.form.account-details .form-control {
	width: 100%;
	margin: 0 15px;
}
.form.account-details > .footer {
    margin-top: 30px;
}
.form.account-details > .error {
    text-align: center;
}
.form.account-details .account-details-buttons {
	margin: 0 auto;
    width: 400px;
    grid-gap: 30px;
}
.modal .account-details-buttons {
    margin: 35px auto 20px auto;
    width: 400px;
    grid-gap: 30px;
}