@import '../../../../defaults.scss';
@import './index.scss';

.report.speeding .scroll-list {
	display: grid;
	grid-template-columns:
		minmax(90px, 5%) // No
		minmax(120px, 10%) // start time
		minmax(150px, 1fr) // start address
		minmax(120px, 10%) // end time
		minmax(150px, 1fr) // end address
		minmax(120px, 6%) // duration
		minmax(100px, 6%) // max speed
	;
}

.report.speeding .scroll-list .loader,
.report.speeding .scroll-list .indicator {
	grid-column: 1 / 8;
}

.report.speeding .scroll-list > *:nth-child(14n+8),
.report.speeding .scroll-list > *:nth-child(14n+9),
.report.speeding .scroll-list > *:nth-child(14n+10),
.report.speeding .scroll-list > *:nth-child(14n+11),
.report.speeding .scroll-list > *:nth-child(14n+12),
.report.speeding .scroll-list > *:nth-child(14n+13),
.report.speeding .scroll-list > *:nth-child(14n+14) {
	background-color: $reports-bg-color;
}