@import '../../../defaults.scss';

.zone-set label {
	margin-bottom: 5px;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
}
.zone-set label span.toggle-zones-view {
	padding: 0 5px;
}
.zone-set label span.toggle-zones-view:hover {
	color: $color-primary;
}