@import '../../../defaults.scss';

.fuel-processor-fields > div:not(:first-child) {
	margin-top: 1rem;
}
.fuel-processor-fields .form-control-group {
	border: 1px solid $color-border;
	padding: 1rem;
}
.fuel-processor-fields .input-fields {
	display: flex;
	margin-top: 1rem;
}
.fuel-processor-fields .input-fields > div {
	margin: 0;
}
.fuel-processor-fields .input-fields > div:first-child {
	margin-right: 10px;
}
.fuel-processor-fields .input-fields > .parameter-input {
	display: flex;
}
.fuel-processor-fields .input-fields .parameter-input .unit {
	display: flex;
    align-items: flex-end;
	padding: 9px 0 9px 5px;
}
.fuel-processor-fields .description {
	opacity: 0.9;
}