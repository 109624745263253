@import '../../../defaults.scss';

.recent-event-list-wrap {
	display: flex;
	min-height: 60px;
	justify-content: center;
	align-items: center;
}
.recent-event-list {
	width: 100%;
}
.recent-event-list .event {
	padding: 15px 10px;
	color: #707070;
}
.recent-event-list .event:not(:last-child) {
	border-bottom: 1px solid $color-border;
}

.recent-event-list .event-icon {
	margin-right: 10px;
}
.recent-event-list .event-field {
	align-items: flex-start;
}