@import '../../defaults.scss';

.app-page.employee-manager .form,
.app-page.employee-manager .form fieldset {
	height: 100%;
}
.app-page.employee-manager .body {
	height: 100%;
}
.app-page.employee-manager .sidebar > .header {
	display: flex;
	align-items: center;
	margin-right: 90px;
}
.app-page.employee-manager .sidebar > .header .icon > * {
	margin-right: 5px;
	display: block;
}
.app-page.employee-manager .sidebar-content {
	display: flex;
	flex-direction: column;
	flex-grow: 1;
	overflow: hidden;
}
.app-page.employee-manager .sidebar-content .error {
	margin-top: 10px;
	text-align: center;
}
.app-page.employee-manager .sidebar-content .label {
	width: 100%;
	border-bottom: 1px solid $color-border;
}
.app-page.employee-manager .employee-list-wrap {
	border-right: 1px solid $color-border;
}
.app-page.employee-manager .sidebar-content .action-bar {
	position: absolute;
	top: 0; right: 0;
}
.app-page.employee-manager .sidebar-content > .action-bar {
	padding: 25px 15px;
}
.app-page.employee-manager .sidebar-content .action-bar .actions {
	display: grid;
	grid-auto-flow: column;
	grid-auto-columns: 16px;
	grid-gap: 16px;
	cursor: pointer;
}
.app-page.employee-manager .sidebar-content .action-bar .actions > * img {
	filter: $text-filter-main;
}
.app-page.employee-manager .sidebar-content > .content {
	padding: 15px;
	overflow-y: auto;
}
.app-page.employee-manager .sidebar-content > .content > .button {
	margin-top: 20px;
}


.app-page.employee-manager .section-header {
	margin: 15px;
	font-size: larger
}
.app-page.employee-manager .section-header span {
	font-weight: bold;
}
.app-page.employee-manager .layout.sidebar-layout > .content {
	position: relative;
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	border-left: 1px solid $color-border;
}
.app-page.employee-manager .sidebar-content > .employee-category-list {
	margin-top: 6px;
}
