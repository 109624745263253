@import '../../../defaults.scss';

.color-picker {
	display: flex;
	flex-direction: column;
	background-color: white;
}
.color-picker .body {
	display: flex;
	flex-direction: row;
	z-index: 12;
}
.color-picker .body > div:first-child {
	width: 100%;
}
.color-picker .hue-group {
	position: relative;
	display: flex;
	flex-direction: row;
	margin-left: 10px;
	margin-right: 3px;
}
.color-picker .hue-wrap {
	position: relative;
	width: 12px;
}
.color-picker .saturation-wrap {
	width: 100%;
	height: 200px;
	position: relative;
	user-select: none;
}
.color-picker .hue-pointer {
	margin-top: 1px;
	width: 20px;
	border-radius: 50%;
	height: 18px;
	position: absolute;
	top: -11px;
	box-shadow: rgba(0, 0, 0, 0.6) 0px 0px 2px;
	background: rgb(255, 255, 255);
	transform: translateX(-4px);
}
.color-picker .v-swatches {
	width: 14px;
	margin-left: 10px;
	height: 250px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}
.color-picker .v-swatches > div {
	height: 18%;
}

.color-picker .values {
	margin-top: 10px;
	display: grid;
	grid-template-columns: 2fr 3fr 3fr 3fr 6fr;
	grid-gap: 5px;
}
.color-picker .values > div {
	text-align: center;
}
.color-picker .values input {
	display: block;
	width: 100%;
	max-width: fit-content;
	color: #575757;
	background-color: #fff;
	border: 1px solid $color-border;
	transition: border-color .3s ease-in-out;
	border-radius: 3px;
	padding: 2px;
}


.color-picker .h-swatches {
	width: 100%;
	display: flex;
	justify-content: space-between;
	height: 35px;
	padding-top: 15px;
	z-index: 12;
}
.color-picker .h-swatches > div {
	margin: 0 3px;
	border-radius: 2px;
	width: 100%;
	height: 100%;
}
.color-picker .h-swatches > div:first-child {
	margin-left: 0;
}
.color-picker .h-swatches > div:last-child {
	margin-right: 0;
}