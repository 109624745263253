@import '../../../../defaults.scss';

.device-command-tile {
	cursor: pointer;
	border: 1px solid $color-border;
	border-radius: 3px;
	padding: 10px;
	display: flex;
	flex-direction: column;
	min-height: 75px;
    transition: transform 0.3s;
}
.device-command-tile:hover {
	background-color: $color-primary-pale;
	box-shadow: 0px 2px 7px -2px rgba(0, 0, 0, 0.4);
	transform: translate(0px, -3px) scale(1.02);
}
.device-command-tile.droppable {
	background-color: $color-primary;
	color: white;
	box-shadow: 0px 2px 7px -2px rgba(0, 0, 0, 0.4);
}
.device-command-tile .property-info {
	flex-grow: 1;
}
.device-command-tile > .title {
	display: flex;
    flex-direction: row !important;
	justify-content: space-between !important;
	align-items: flex-start !important;
}
.device-command-tile > .title .status {
	font-size: 20px;
}
.device-command-tile .title label {
	padding-bottom: 15px;
	font-weight: bold;
	overflow-wrap: break-word;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}
.device-command-tile .title .event-icon {
	margin-left: 10px;
	height: 20px;
    width: 20px;
    min-width: 20px;
    background-size: 20px;
}
.device-command-tile .title label::first-letter {
    text-transform: capitalize;
}
.device-command-tile .commentary {
	height: 35px;
	overflow-wrap: break-word;
	overflow: hidden;
	text-overflow: ellipsis;
	overflow-wrap: break-word;
}